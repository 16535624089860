import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ActivatedRoute} from '@angular/router';


//placekey
import {geoToPlacekey} from '@placekey/placekey';
//H3
import {gridDisk} from "h3-js";

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const createLocations = gql`
  mutation createLocations(  $homeAddressID:ID,$homeID:ID, $latitude: Float, $longitude: Float, ){
  createLocations(input:
    {
      homeID:$homeID
      homeAddressID:$homeAddressID
      latitude: $latitude
      longitude: $longitude
      home_address:{connect:{
        where: {
          node:{homeAddressID:$homeAddressID}
        }
        
      }}

      home:{connect:{
        where: {
          node:{homeID:$homeID}
        }
        
      }}
       
    }) 
  
  {
   locations{
    locationID
    homeAddressID
    home_address{
      street_number
      street
      city
      state
      zip
    }
    

   
    
  }
 
    }
    
  }
  
`;



const createHomeAddresses = gql`
 mutation createHomeAddressses($address_name:String,
  														$street_number:String,
  														$street: String,
  														$city: String,
  														$state: String,
  														$zip: String,
  														$uid: ID,
                              $placekey: String,
                              
                             
  										
){
  createHomeAddresses(input:
    {
      address_name: $address_name
      street_number:$street_number
      street: $street
      city: $city
      state: $state
      zip: $zip
      uid:$uid
      placekey:$placekey
      
      
     
    	home:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      zips:{connect:{
        where: {
          node:{zip:$zip}
        }
        
      }}
     
    }
    
  ) 
  
  {
   homeAddresses{
    homeAddressID
    	uid
      address_name
    	street_number
    	street
    	city
    	state
    	zip
      latitude
      longitude
      placekey
      home{
        uid
      }
      	
        }
    
  }
 
    }
  
`;

@Component({
  selector: 'app-home-address',
  templateUrl: './home-address.page.html',
  styleUrls: ['./home-address.page.scss'],
})
export class HomeAddressPage implements OnInit {

  aForm: FormGroup;
  isSubmitted = false;
  uid = this.authService.getUid();
  zip = this.navParams.get('zip');
  latitude = this.navParams.get('latitude');
  homes: string[];
  loading = true;
  error: any; 
  homeAddressID: string;
  
  address_name: string;
  street_number: string;
  street: string;
  city: string;
  state: string;
  
  //latitude :number;
  longitude :number;




  constructor(private fb: FormBuilder,private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    this.aForm = this.fb.group({
      uid:               [''],
      address_name:      [''],
      street_number:     [''],
      street:            [''],
      city:              ['', [Validators.required]],
      state:             ['', [Validators.required]],
      zip:               ['', [Validators.required]],
      placekey:          [''],
      
     
     });
    
  }
  closeModalHomeAddress():void{
    //this.modalController.dismiss();
    this.modalController.dismiss(null, null, "homeAddresspage");
  


  this.apollo
  .watchQuery({
    query: gql`
       query homes($uid:ID)
{
homes(where:{uid:$uid}){
uid
homeID
username
password
email
homeInfo{
  name_first
  name_last
}
homeAddress{
  homeAddressID
  street_number
  street
  city
  state
  zip
  latitude
  longitude
  placekey
}
location{
  latitude
  longitude
}
}
}


    `,
           //variables: {uid:this.uid} , 
       variables:    {uid: this.afAuth.auth.currentUser.uid},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.homes = result?.data?.homes;
    this.loading = result.loading;
    this.error = result.error;
  });
}
async createHomeAddresses() {
  this.isSubmitted = true;
  if (!this.aForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.aForm.value);
    
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: createHomeAddresses,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    address_name:this.aForm.value.address_name,
    street_number:this.aForm.value.street_number,
    street:this.aForm.value.street,
    city:this.aForm.value.city,
    state:this.aForm.value.state,
    zip:this.aForm.value.zip,
    //placekey:this.placekey,
    //latitude:this.latitude,
    //actionID:this.actionID
    
    
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
    
     
     
     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
}
async createLocation(longitude,latitude,homeAddressID,homeID) {
  
  this.apollo.mutate({
    mutation: createLocations,
    
    variables: {
    longitude:longitude,
    latitude:latitude,
    homeAddressID:homeAddressID,
    homeID:homeID
    //latitude:this.latitude,
    //actionID:this.actionID
    
    
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
  
}
}
