import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
//Modal Pages
import { JobsPage } from '../jobs/jobs.page';
import { HashtagPage } from '../hashtag/hashtag.page';
import { InvitesPage } from '../invites/invites.page';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  segmentModel = "home";
  suppliers: any[];
  Suppliers: any[];
  email: string[];
  todays: any[];

  loading = true;
  dataReturned: string[];
  error: any;

  uid = this.authService.getUid(); 

  constructor(private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController, private loadingCtrl: LoadingController,
    private toastCtrl: ToastController, 
    private alertCtrl: AlertController, ) {}


    ngOnInit() {
      this.apollo
      .watchQuery({
        query: gql`
           query todays
  {
  todays{
  todayID
  
  month{
    monthID
    month
  }
  year{
    yearID
    year
  }
        day{
            day
            dayID
            date
            dayOfWeek
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
  }
  
  
        `,
               //variables: {uid:this.uid} , 
          // variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.todays = result?.data?.todays;
        this.loading = result.loading;
        this.error = result.error;
      });
      console.log('todays',this.todays)
  
  
  
  
  
  
  
  
  
      
      this.apollo
        .watchQuery({
          query: gql`
              query Supplier($uid:ID) {
            suppliers(where:{uid:$uid}) 
          {
            supplierID
            uid
            name_first
            name_last
            supplierBusinessType
            countTask
            countTaskHash
            countDecline
            countInvite
            countAccept
            countConnects
  
      email
      password
      username
      supplierItem{
        item
      }
  
      supplierSubcategory{
        subcategory
      }
      supplierCategory{
              category
            }
             supplierSubcategory{
              subcategory
            }
      supplier_info{
       
        company
        founded
        license
        tagline
        bio
        employee
      }
      territory{
        territory
      }
      supplierBase{
        baseID
          business_type{
            businessTypeID
            business_type
            image_path
            image_path_header
          }
      }
    }
  }
          `,
                 //variables: {uid:this.uid} , 
             variables:    {uid: this.afAuth.auth.currentUser.uid},
             
        })
        .valueChanges.subscribe((result: any) => {
          this.suppliers = result?.data?.suppliers;
          this.loading = result.loading;
          this.error = result.error;
        });
        console.log('suppliers',this.suppliers)
    }
  
    GET_SUPPLIERS(uid){
      this.apollo
      .watchQuery({
        query: gql`
           query suppliers($uid:ID) {
            suppliers(where:{uid:$uid}) {
            email
            supplierID
            countTask
            countDecline
            countInvite
            countAccept
            countConnects
            
            supplierCategory{
          categoryID
          category
        }
        supplierHashtags{
          itemID
          item_tag
        }
       
            supplierTasks{
              task_description
              taskID
              itemID
              categoryID
              subcategoryID
              businessTypeID
              posted
              date_create
              taskDaysAgo
              item_tag
              city_tag
              connectSupplierID
              count_connect
              count_decline
              declineSupplierID
              declineSupplierUID
              
              category {
                category
            }
    subcategory{
      subcategory
    }
              
            }
  }
   
  }
  
  
  
  
  `,
      variables:    {uid: this.afAuth.auth.currentUser.uid},
      //variables:    {uid: uid}
  
  })
      .valueChanges.subscribe((result: any) => {
        this.suppliers = result?.data?.suppliers;
        this.loading = result.loading;
        this.error = result.error;
      });
    };
    
  
    segmentChanged(event){
      console.log(this.segmentModel);
      
      console.log(event);
    }  
    
    async openModalHashtag(uid) {
      let loading = await this.loadingCtrl.create({
        message: 'Loading Hashtag Data ...',
        duration: 1000,
      });
      await loading.present();
      const modal = await this.modalController.create({
        component: HashtagPage,
        cssClass: 'my-modal-class',
        id:"hashtag",
        componentProps: {
          
          uid:this.uid,
       
        }
      });
    
      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned !== null) {
          this.dataReturned = dataReturned.data;
          //alert('Modal Sent Data :'+ dataReturned);
        }
      });
      
    
      return await modal.present();
  }
      async openModalJobs(uid) {
        let loading = await this.loadingCtrl.create({
          message: 'Loading Task Data ...',
          duration: 1000,
        });
        await loading.present();
        const modal = await this.modalController.create({
          component: JobsPage,
          cssClass: 'my-modal-class',
          id:"jobs",
          componentProps: {
            
            uid:this.uid,
            
            //zipID:zipID
          }
        });
      
        modal.onDidDismiss().then((dataReturned) => {
          if (dataReturned !== null) {
            this.dataReturned = dataReturned.data;
            //alert('Modal Sent Data :'+ dataReturned);
          }
        });
      
        return await modal.present();
      }
      async openModalInvites(uid) {
        const modal = await this.modalController.create({
          component: InvitesPage,
          cssClass: 'my-modal-class',
          id:"invites",
          componentProps: {
            
            uid:this.uid,
         
          }
        });
      
        modal.onDidDismiss().then((dataReturned) => {
          if (dataReturned !== null) {
            this.dataReturned = dataReturned.data;
            //alert('Modal Sent Data :'+ dataReturned);
          }
        });
      
        return await modal.present();
      }
      closeModal():void{
        this.modalController.dismiss();
    }
}