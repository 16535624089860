import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
//import { NavParams } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { BannerModalPage } from '../banner-modal/banner-modal.page';



@Component({
  selector: 'app-tow',
  templateUrl: './tow.page.html',
  styleUrls: ['./tow.page.scss'],
})
export class TowPage implements OnInit {

  //uid = this.authService.getUid(); 
  homeID:any;
  bannerPlacements: any[];
  loading = true;
  error: any;
  dataReturned: any;
  todays: any[];
  homes: any[];
  week:any[];
  weekID:any;
 bannerUID: any[];
 bannerPlacementUID: any[];
 

 constructor(private authService: AuthService, 
  private afAuth: AngularFireAuth,
  private navService: NavService,
  private apollo: Apollo,
  //private navParams: NavParams,
  private modalController: ModalController,
  public toastController: ToastController,
  public alertController: AlertController,
  //public navParams: NavParams,
  private router: Router) {}

  ngOnInit() {
    //let weekID = this.navParams.get('weekID');
    //let todayID = this.navParams.get('todayID');
    //let dayID = this.navParams.get('dayID');
    //let yearID = this.navParams.get('yearID');

    //let territory_number = this.navParams.get('territory_number');
    //console.log('weekID',weekID);
    //console.log('todayID',todayID);
    //console.log('dayID',dayID);
    //console.log('yearID',yearID);

    let weekID =this.weekID
   

    this.apollo
    .watchQuery({
      query: gql`
         query todays
{
todays{
  todayID
  month{
    monthID
    month
  }
  year{
    yearID
    year
  }
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement_count
            promo_placement_count
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
}

      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays)

  
  this.apollo
  .watchQuery({
    query: gql`
      query bannerPlacements($weekID:ID)
{
bannerPlacements(where:{weekID:$weekID}){
  bannerID
 bannerPlacementUID
 weekID
 dayID
  week
  year
  territory_number
  banners{
    bannerUID
    image_path
    headline
    message
  }
}
}

    `,
           //variables: {uid:this.uid} , 
       variables:    {weekID:weekID},
      
       
  })
  .valueChanges.subscribe((result: any) => {
    this.bannerPlacements = result?.data?.bannerPlacements;
    this.loading = result.loading;
    this.error = result.error;
  });

  console.log('weekID',weekID);

  }
async openModalBanner(bannerPlacementUID, territoryID,zipID,todayID,dayID) {
  const modal = await this.modalController.create({
    component: BannerModalPage,
    cssClass: 'my-modal-class',
    id:"banner",
    componentProps: {
      
     // uid:this.uid,
      bannerPlacementUID:  bannerPlacementUID,
      territoryID:territoryID,
      zipID: zipID,
      todayID:todayID,
      dayID:dayID
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
closeModal():void{
  this.modalController.dismiss();
}

      








}

