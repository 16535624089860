import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
const now = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
const GET_TODAY = gql`
  query GetToday {
    todays{
    todayID
          day{
              day
              dayID
              date
              weekNumber
              month
              year
          }
    }}
`;

const createTasks = gql`
  mutation createTasks( $createdAt:DateTime,$itemID:ID,$territory:String,$task_description:String,$task_detail:String, $task_why_now: String, $categoryID:ID, $homeUID:ID,$town:String,$category:String, $subcategory:String, $subcategoryID:ID,$territoryID:ID, $is_emergency:Boolean, $make_model:String, $years_old: String,$zipID:ID){
  createTasks(input:
    {
      homeUID:$homeUID
     
     	task_description:$task_description
      task_detail:$task_detail
      task_why_now:$task_why_now
      categoryID:$categoryID
      subcategoryID:$subcategoryID
      territoryID:$territoryID
      is_active:true
      town:$town
      is_emergency:$is_emergency
      make_model:$make_model
      years_old:$years_old
     createdAt:$createdAt
      itemID:$itemID

    
      

      item:{connect:{
        where: {
          node:{itemID:$itemID}
        }
      }}
      
      
      
      homes:{connect:{
        where: {
          node:{uid:$homeUID}
        }
        
        edge: {
         
          category:$category
          subcategory:$subcategory
         
          
          
         
        }
        
      }}
      
      territory:{connect: {
        where: {
          node:{territoryID:$territoryID}
        }
        edge:{
          territory:$territory
          category:$category
          subcategory:$subcategory
          
          
           task_description:$task_description
        
          town:$town
        }
      }}
      
      category:{connect:{
        where:	{
          node:{categoryID:$categoryID}
        }
        edge:{
          territory:$territory
          category:$category
          
          
          task_description:$task_description
          
        }
      }}
      
      
      
      subcategory:{connect:{
        where:	{
          node:{subcategoryID:$subcategoryID}
        }
        edge:{
          
					subcategory:$subcategory
          category:$category
         
          territory:$territory
          
          task_description:$task_description
          
          
          
        }
      }}
      
    	
      
      
      zip:{connect:{
        where:	{
          node:{zipID:$zipID}
        }
      }}
       
    }
    
    
    
  ) 
  
  {
   tasks{
     taskID
     task_description
   	 task_detail
      task_why_now
    categoryID
    subcategoryID
    is_active
    is_emergency
    make_model
    years_old
    actionTask{
      action
    }
    actionID
    item{
      itemID
      item
    }
   territory{
      territory
    }
    subcategory{
      subcategory
      subcategoryID
    }
      
      	homes{
          homeID
        
          username
          password
        }
        }
    
  }
 
    }
    

  
`;


const createTodos = gql`
  mutation createTodos( $itemID:ID,$territory:String,$task_description:String,$task_detail:String, $task_why_now: String, $categoryID:ID, $homeUID:ID,$town:String,$category:String, $subcategory:String, $subcategoryID:ID,$territoryID:ID, $is_emergency:Boolean, $make_model:String, $years_old: String,$zipID:ID){
  createTodos(input:
    {
      homeUID:$homeUID
     
     	task_description:$task_description
      task_detail:$task_detail
      task_why_now:$task_why_now
      categoryID:$categoryID
      subcategoryID:$subcategoryID
      territoryID:$territoryID
      is_active:true
      town:$town
      is_emergency:$is_emergency
      make_model:$make_model
      years_old:$years_old
      zipID:$zipID
      itemID:$itemID

    
      

      item:{connect:{
        where: {
          node:{itemID:$itemID}
        }
      }}
      
      
      
      homes:{connect:{
        where: {
          node:{uid:$homeUID}
        }
        
        edge: {
         
          category:$category
          subcategory:$subcategory
         
          
          
         
        }
        
      }}
      
      territory:{connect: {
        where: {
          node:{territoryID:$territoryID}
        }
        edge:{
          territory:$territory
          category:$category
          subcategory:$subcategory
          
          
           task_description:$task_description
        
          town:$town
        }
      }}
      
      category:{connect:{
        where:	{
          node:{categoryID:$categoryID}
        }
        edge:{
          territory:$territory
          category:$category
          
          
          task_description:$task_description
          
        }
      }}
      
      
      
      subcategory:{connect:{
        where:	{
          node:{subcategoryID:$subcategoryID}
        }
        edge:{
          
					subcategory:$subcategory
          category:$category
         
          territory:$territory
          
          task_description:$task_description
          
          
          
        }
      }}
      
    	
      
      
      zip:{connect:{
        where:	{
          node:{zipID:$zipID}
        }
      }}
       
    }
    
    
    
  ) 
  
  {
   todos{
    todoUID
     task_description
   	 task_detail
      task_why_now
    categoryID
    subcategoryID
    is_active
    is_emergency
    make_model
    years_old
        zip{
          zip
        }
        category{
          categoryID
          category
        }
    actionTask{
      action
    }
    actionID
    item{
      itemID
      item
    }
   territory{
      territory
    }
    subcategory{
      subcategory
      subcategoryID
    }
      
      	homes{
          homeID
        
          username
          password
        }
        }
   
  }
 
    }
    

  
`;

@Component({
  selector: 'app-banner-modal-rfq',
  templateUrl: './banner-modal-rfq.page.html',
  styleUrls: ['./banner-modal-rfq.page.scss'],
})
export class BannerModalRfqPage implements OnInit {

  private querySubscription: Subscription;
  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  isSubmitted = false;
  homes:any[];
  subcategories:any[];
  categories:any[];
  items:any[];
  todays:any[];
  actions:any[];
  actionTasks:any[];
  dataReturned: any[];
  registerForm: FormGroup;
  categoryID: any;
  subcategoryID: any;
  zip:any;
  zipID:any;
  territory:any;
  territoryID:any;
  homeID: any;
  itemID: any;
  homeUID: any;
  actionID: any;
  businessTypeID: any;
  value_selected:any;
  action:any;
  createdAt:any;

  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    private fb: FormBuilder, 
    ) {}

  ngOnInit() {

    this.querySubscription = this.apollo.watchQuery<any>({

      query: GET_TODAY,
      variables: {
        //homeUID:this.uid
      },
    })
      .valueChanges
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        this.todays = data.todays;
      });

    this.registerForm = this.fb.group({
      
      task_description:   ['', [Validators.required]],
      
      task_detail:        [''],
      task_why_now:       [''],
      itemID:             [''],
      action:             [''],
      make_model:         [''],
      years_old:          [''],
      territoryID:        [''],
      //itemID: ['', [Validators.required, Validators.minLength(2)]],
      

      
   
      
    });
    let subcategoryID = this.navParams.get('subcategoryID');
    let categoryID = this.navParams.get('categoryID');
    let zipID = this.navParams.get('zipID');
    let uid=this.uid;
    let territoryID=this.territoryID;
    let businessTypeID = this.navParams.get('businessTypeID');
    let todayID = this.navParams.get('todayID');
    let itemID=this.itemID;
    let actionID=this.actionID;
    let now = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
   
    console.log('sub',subcategoryID);
    console.log('cat',categoryID);
    console.log('uid',uid);
    console.log('territoryID',territoryID);
    console.log('businesstypeID',businessTypeID),
    console.log('zipID',zipID),
    console.log('itemID',itemID),
   

    this.apollo
    .watchQuery({
      query: gql`
        query items($itemID:ID)
{
  items(where:{itemID:$itemID}){
    itemID
    item
    image_path
   
  }
}

      `,
             //variables: {uid:this.uid} , 
         variables:    {itemID:itemID},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.items = result?.data?.items;
      this.loading = result.loading;
      this.error = result.error;
    });



    this.apollo
    .watchQuery({
      query: gql`
         query actionTasks
                        {
    actionTasks{
    actionID
    action
      
    }
    }
    
      `,
            
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.actionTasks = result?.data?.actionTasks;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('action',this.actions)

    this.apollo
    .watchQuery({
      query: gql`
         query homes($uid:ID)
    {
    homes(where:{uid:$uid}){
    homeID
    uid
      homeZip{
        zipID
        zip
        town
        zipTerritory{
          territory
        }
      }
      
    
    }
    }
    
      `,
             variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid)

    this.apollo
    .watchQuery({
      query: gql`
       query subcategories($subcategoryID:ID) {
          subcategories(where:{subcategoryID:$subcategoryID}){
            subcategory
            subcategoryID
            
              item{
                itemID
                item
                image_path
              }
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {subcategoryID:subcategoryID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.subcategories = result?.data?.subcategories;
      this.loading = result.loading;
      this.error = result.error;
    });


    this.apollo
    .watchQuery({
      query: gql`
       query categories($categoryID:ID) {
          categories(where:{categoryID:$categoryID}){
            category
            categoryID
            
              
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {categoryID:categoryID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.categories = result?.data?.categories;
      this.loading = result.loading;
      this.error = result.error;
    });
  }
  closeModal():void{
    //this.modalController.dismiss();
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "banners-category");
    
    this.modalController.dismiss(null, null, "banners-rfq");
   
    
  }


  async createTasks(zipID) {
    this.isSubmitted = true;
    if (!this.registerForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {

      
      console.log(this.registerForm.value)
    }

    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation: createTasks,
      
      variables: {
      uid: this.afAuth.auth.currentUser.uid,
      task_description:this.registerForm.value.task_description,
      task_detail:this.registerForm.value.task_detail,
      task_why_now:this.registerForm.value.task_why_now,
      action:this.registerForm.value.action,
      make_model:this.registerForm.value.make_model,
      years_old:this.registerForm.value.years_old,
      categoryID:this.categoryID,
      subcategoryID:this.subcategoryID,
      zipID:zipID,
      territoryID:this.territoryID,
      homeUID:this.afAuth.auth.currentUser.uid,
     // businessTypeID:this.businessTypeID,
      itemID:this.itemID, 
      createdAt:now,
      //actionID:this.actionID
      
      
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);
       
  console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    
    });
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "banners-category");
    this.modalController.dismiss(null, null, "banners-rfq");
    
     this.router.navigateByUrl('seller/entry');
  }

     async createTodos(zipID) {
      this.isSubmitted = true;
      if (!this.registerForm.valid) {
        console.log('Please provide all the required values!')
        return false;
      } else {
  
        
        console.log(this.registerForm.value)
      }
  
      //let params = Object.assign(credentials)
      this.apollo.mutate({
        mutation: createTodos,
        
        variables: {
        uid: this.afAuth.auth.currentUser.uid,
        task_description:this.registerForm.value.task_description,
        task_detail:this.registerForm.value.task_detail,
        task_why_now:this.registerForm.value.task_why_now,
        action:this.registerForm.value.action,
        make_model:this.registerForm.value.make_model,
        years_old:this.registerForm.value.years_old,
        categoryID:this.categoryID,
        subcategoryID:this.subcategoryID,
        zipID:zipID,
        territoryID:this.territoryID,
        homeUID:this.afAuth.auth.currentUser.uid,
       // businessTypeID:this.businessTypeID,
        itemID:this.itemID, 
        //actionID:this.actionID
        
        
      },
        
      }).subscribe(({ data }) => {
        
     
         console.log(data);
         
    console.log("Form Submitted!");
    
    
    //this.navCtrl.navigateForward('/setup'); 
     //this.router.navigate(['/app/setup/',this.id ]);
       
      }, (error) => {
        console.log('there was an big ass error sending the query', error);
        
        //this.navCtrl.navigateRoot('/setup');
      
      });
      this.modalController.dismiss(null, null, "banner");
      this.modalController.dismiss(null, null, "banners-subcategory");
      this.modalController.dismiss(null, null, "banners-category");
      this.modalController.dismiss(null, null, "banners-rfq");
      this.modalController.dismiss(null, null, "banners");
      
       this.router.navigateByUrl('seller/entry');
  





}
checkValue(event) { 
  //console.log(event.detail.value);
  console.log('itemID',this.itemID)
}
checkValueAction(event) { 
  //console.log(event.detail.value);
  console.log('action',this.actionID)
}
showToast() {
  this.toastController.create({
    header: 'Congratulation',
    message: 'You Have Now Created Your Request',
    position: 'middle',
    cssClass: 'my-custom-class',
    color: 'home',
    duration: 3000
    
  }).then((obj) => {
    obj.present();
    
   
  });
}
}
