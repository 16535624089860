import { Component, OnInit, ViewChild,EventEmitter, Input, Output } from '@angular/core';
import {Apollo, gql, QueryRef} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ActivatedRoute} from '@angular/router';

//used for poll & refresh
import { Subscription } from 'rxjs'

//placekey
import {geoToPlacekey} from '@placekey/placekey';
//H3
import geojson2h3, { h3SetToFeatureCollection } from 'geojson2h3';


import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';
//import {getHexBoundary} from "h3-js";

import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";
import {cellToChildren} from "h3-js";
import {cellToParent} from "h3-js";



import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//keys to the kingdom
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//import {geoToH3} from "h3-js";
//import {h3ToGeo} from "h3-js";
//import {h3ToGeoBoundary} from "h3-js";

//services
import { LocationService } from '../../services/location.service';


//mapbox
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

//range help

//observable radius
//https://observablehq.com/@jmuyskens/inputs
//import {slider, radio} from "@jashkenas/inputs"
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';

@Component({
  selector: 'app-gis',
  templateUrl: './gis.page.html',
  styleUrls: ['./gis.page.scss'],
})
export class GisPage implements OnInit {
  private mapbox: mapboxgl.Map;
  private map = {};
  private directions = {};
  private container = {};
  private value: any;
  private values: any;

  locations:any[];
  location:any;
  coordinates:any;
  h37:any;
  h:any;
  h39:any;
  kring:any[];
  job:any[];
  //h39hex:any;

  range:any;
  hexagons:any[];  //geojson:any;
  geojson:any[];  //geojson:any;
  lngLat:any;
  resolution:number;

  data:[];

  loading = true;
  error: any;
  
  lastEmittedValue: any;
  apple:any;
  result1:any;
  detail:any;
  public rangeValue = { lower: 0, upper:10}; 
  public k = { lower: 0, upper:10}; 





  constructor(private apollo:Apollo,
    private locationService: LocationService,
    private modalController: ModalController
) {
mapboxgl.accessToken = environment.mapboxgl.accessToken;




}

  ngOnInit() {
    //let range = this.range;
   
    

    
  
  }

  
      ionViewDidEnter() {

        const ages = [32, 33, 16, 40];
        let result1 = ages.filter(checkAdult);
        function checkAdult(age) {
        return age >= 18;
        }
        console.log('age',result1);
        this.result1=result1;
        this.rangeValue.lower= this.rangeValue.lower;
        
        const words = ['spray', 'limit', 'elite', 'exuberant', 'destruction', 'present'];
        const result = words.filter(word => word.length > 9);

        console.log(result);
        // Expected output: Array ["exuberant", "destruction", "present"]
        
        let z =this.locationService
      .getLocations()
      .subscribe((data) => {
        let geoJson = data;

       

        //new
        let a =data.data.locations;
        let h37       = a.map((h37)=> h37.h37);
        let h39       = a.map((h39)=> h39.h39);
        let h39hex    = h39.map((h39)=>cellToBoundary(h39)); 
        let hexagons  = h39hex.map((h39hex)=>polygonToCells(h39hex,9));
        let geojson   = hexagons.map((hexagons)=>h3SetToFeatureCollection(hexagons));
      
        let geojson1 =h3SetToFeatureCollection(hexagons);
        let h39geo    = h39.map((h39)=>cellToBoundary(h39,true));
        //setting up K-ring as h3 Index
        //Will need to set on click map coordinates for lat lon
        //Maybe overlay of h3 index over map with click event
        //const h = "892a1054557ffff";
        let h3 = latLngToCell(40.984634276758555,-74.11759371461565, 6);
        const h = "862a10547ffffff";
        const h1 = cellToChildren(h3,9);
        const k = this.k.lower;
        //let k = range;
         let kring = gridDisk(h,k);
         //testing for circle search
        //let kringhex = cellToBoundary(kring); 
        let kringgeo    = kring.map((kring)=>cellToBoundary(kring,true));
        //lookupIndexes.reduce((output, h3Index) => [...output, ...(lookupMap[h3Index] || [])], []);
        let jobs = h39.reduce((output, h3Index) => [...output, ...(h39 || [])], []);
        
        //let job = h39.filter(value => kring.includes(value));
        let job = h39.filter(h39 => h1.includes(h39) );
        let jobsgeo    = job.map((job)=>cellToBoundary(job,true));

       

        //observable radius
        //https://observablehq.com/@nrabinowitz/h3-radius-lookup?collection=@nrabinowitz/h3-tutorial
        //h3.gridDisk(origin, k)=kRingIndexes


        //let kRingResults(searchLocation) {
          //const lookupIndexes = gridDisk(searchLocation,2);
          // Find all points of interest in the k-ring
          //return lookupIndexes.reduce((output, h3Index) => [...output, ...(lookupMap[h3Index] || [])], []);
        //}

        const x = h39.filter(h39 => h1.includes(h39) );
         console.log('x',x);
        

        console.log('a',a);
        console.log('h37',h37);
        console.log('h3',h3)
        console.log('h39',h39);
        console.log('h39hex',h39hex);
        console.log('hexagons',hexagons);
        console.log('geojson',geojson);
        console.log('geojson1',geojson1);
        console.log('h39geo',h39geo);
       //console.log('disk',disk);
        console.log('kring',kring);
        //console.log('kringhex',kringhex);
        console.log('kringgeo',kringgeo);
        console.log('h',h);
        console.log('h1',h1);

        //console.log('k',k);
       // console.log('range',range);
        console.log('jobs',jobs);
        console.log('jobsgeo',jobsgeo);

        console.log('job',job);
        console.log('lastEmittedValue',this.lastEmittedValue);
        console.log('rangelower',this.rangeValue.lower)
        //console.log('lat',lat);



        




       


      
        //use this function to populate html page
        this.h37 = h37;
        this.h39 = h39;
        this.h    =h;
        //this.h39hex   = h39hex;
        this.hexagons   = hexagons;
        this.geojson    = geoJson;
        this.job        = job;
        this.lastEmittedValue = this.lastEmittedValue;
        //this.range      = range;
        

   //map setup
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v9',
        zoom: 13,
        center: [-74.08097014877629,40.98385513546514],
        attributionControl: false
       //below code from database fields
      // center: [longitude,latitude]
        });
       map.addControl(new mapboxgl.AttributionControl({
          customAttribution: 'Map design by Aarbo'
          }));
        
        map.addControl(
          new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
          })
          );
        var marker = new mapboxgl.Marker();

        function add_marker (event) {
          let coordinates = event.lngLat;
          console.log('Lng:', coordinates.lng, 'Lat:', coordinates.lat);
          marker.setLngLat(coordinates).addTo(map);
        

        }
        
        map.on('click', add_marker);
        map.on('style.load', function() {
          map.on('click', function(e) {
            let coordinates = e.lngLat;
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML('Set Location: <br/>' + coordinates)
              .addTo(map);
              console.log('coordinates',coordinates)
              let apple = coordinates;
              console.log('apple',apple.lat);

          });
        });
        
        this.apple = this.apple
        
        // add navigation controls \(zoom buttons, pitch & rotate\)
        map.addControl(new mapboxgl.NavigationControl());
        

        map.on('load', () => {
          // Add a data source containing GeoJSON data.
          map.addSource('h39Jobs', {
            'type': 'geojson',
            'data': {
              'type': 'Feature',
            'geometry': {
              
              'type': 'Polygon',
              
              'coordinates':[h39geo],
             
               'properties': {
                  'message': 'Chennai'
               }}}
               });

          //**** */
          map.addSource('kringgeo', {
            'type': 'geojson',
            'data': {
              'type': 'Feature',
            'geometry': {
              
              'type': 'Polygon',
              
              'coordinates':kringgeo,
             
             
               'properties': {
                  'message': 'Chennai'
               }}}
               });

               map.addSource('maine2', {
                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                'geometry': {
                  
                  'type': 'Polygon',
                  
                  //'coordinates':kringgeo,
                  'coordinates':jobsgeo,
                 
                   'properties': {
                      'message': 'Chennai'
                   }}}
                   
                   });
                   map.addLayer({
                    'id': 'outline2',
                    'type': 'line',
                    'source': 'maine2',
                    'layout': {},
                    'paint': {
                    'line-color': 'black',
                    'line-width': .50
                    }
                    });
                    map.addLayer({
                      'id': 'fill2',
                      'type': 'fill',
                      'source': 'h39Jobs', // reference the data source
                      'layout': {},
                      'paint': {
                      'fill-color': 'red', // blue color fill
                      'fill-opacity': 0.40
                      }
                      });
         
             console.log('geojson',geojson)
              
              // Add a new layer to visualize the polygon.
              map.addLayer({
              'id': 'h39Jobs',
              'type': 'fill',
              'source': 'h39Jobs', // reference the data source
              'layout': {},
              'paint': {
              'fill-color': '#0080ff', // blue color fill
              'fill-opacity': 0.50
              }
              });
             
              // Add a black outline around the polygon.
              map.addLayer({
              'id': 'outline1',
              'type': 'line',
              'source': 'kringgeo',
              'layout': {},
              'paint': {
              'line-color': '#000',
              'line-width': .15
              }
              });
              map.addLayer({
                'id': 'outline',
                'type': 'line',
                'source': 'h39Jobs',
                'layout': {},
                'paint': {
                'line-color': '#000',
                'line-width': 1
                }
                });
                
              
              
              
              });

             
             
      })
   
    


    
    }
    closeModal():void{
      this.modalController.dismiss();
    }
    } 
    
  