import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-profit',
  templateUrl: './profit.page.html',
  styleUrls: ['./profit.page.scss'],
})
export class ProfitPage implements OnInit {
  segmentModel = "profit";

  profit = { "desired_profit": 100000, "fixed_cost": 0, "target_gross_margin":35, "average_sales_value":500, "conversion_rate":60,"target_gross_margin5":5,"target_gross_margin10":10,"target_gross_margin15":15,"target_gross_margin20":20, "target_gross_margin25":25, "target_gross_margin30":30, "target_gross_margin35":35, "target_gross_margin40":40,"target_gross_margin45":45,"target_gross_margin50":50,"target_gross_margin55":55,"target_gross_margin60":60,"target_gross_margin65":65,"target_gross_margin70":70};

  required_gross_profit() : any {
    return (this.profit.desired_profit + this.profit.fixed_cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ;
  }
  annual_sales_breakeven() : any {
    return (( this.profit.fixed_cost) / (this.profit.target_gross_margin /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}

  annual_sales_required() : any {
    return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}

  annual_sales_required5() : any {
      return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin5 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  annual_sales_required10() : any {
        return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin10 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
    

  annual_sales_required15() : any {
      return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  annual_sales_required20() : any {
      return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  annual_sales_required25() : any {
        return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
    
  annual_sales_required30() : any {
          return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
      
  annual_sales_required35() : any {
            return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
    
  annual_sales_required40() : any {
              return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  annual_sales_required45() : any {
                return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin45 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  annual_sales_required50() : any {
                  return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin50 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
    
  annual_sales_required55() : any {
                    return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin55 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
      
  annual_sales_required60() : any {
                      return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin60 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
                 
  annual_sales_required65() : any {
                        return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin65 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
           
  annual_sales_required70() : any {
                          return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin70 /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  weekly_sales_required_breakeven() : any {
                            return ((( this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
                                         
  weekly_sales_required() : any {
    return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}

  weekly_sales_required5() : any {
      return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin5 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}

  weekly_sales_required10() : any {
        return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin10 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  

  weekly_sales_required15() : any {
      return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}

  weekly_sales_required20() : any {
      return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  weekly_sales_required25() : any {
        return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
    
  weekly_sales_required30() : any {
          return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
      
  weekly_sales_required35() : any {
            return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
        
  weekly_sales_required40() : any {
              return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
          

  weekly_sales_required45() : any {
                return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin45 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
        
  weekly_sales_required50() : any {
                  return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin50 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
              
  weekly_sales_required55() : any {
                    return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin55 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
               
  weekly_sales_required60() : any {
                      return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin60 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
         
  weekly_sales_required65() : any {
                        return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin65 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
      
  weekly_sales_required70() : any {
                          return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin70 /100))/52).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
          
  number_jobs_per_week_breakeven() : any {
                            return ((((this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52) / this.profit.average_sales_value).toFixed(0);}
                                                  

  number_jobs_per_week() : any {
    return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52) / this.profit.average_sales_value).toFixed(0);}
  
  number_jobs_per_week5() : any {
      return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin5 /100))/52) / this.profit.average_sales_value).toFixed(0);}
    
  number_jobs_per_week10() : any {
        return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin10 /100))/52) / this.profit.average_sales_value).toFixed(0);}
       

  number_jobs_per_week15() : any {
        return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100))/52) / this.profit.average_sales_value).toFixed(0);}
      
  number_jobs_per_week20() : any {
      return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100))/52) / this.profit.average_sales_value).toFixed(0);}
    
  number_jobs_per_week25() : any {
        return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100))/52) / this.profit.average_sales_value).toFixed(0);}
     
  number_jobs_per_week30() : any {
          return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100))/52) / this.profit.average_sales_value).toFixed(0);}
       
  number_jobs_per_week35() : any {
            return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100))/52) / this.profit.average_sales_value).toFixed(0);}
         
  number_jobs_per_week40() : any {
              return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100))/52) / this.profit.average_sales_value).toFixed(0);}
   
  number_jobs_per_week45() : any {
                return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin45 /100))/52) / this.profit.average_sales_value).toFixed(0);}
     
  number_jobs_per_week50() : any {
                  return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin50 /100))/52) / this.profit.average_sales_value).toFixed(0);}
       
  number_jobs_per_week55() : any {
                    return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin55 /100))/52) / this.profit.average_sales_value).toFixed(0);}
         
  number_jobs_per_week60() : any {
                      return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin60 /100))/52) / this.profit.average_sales_value).toFixed(0);}
           
  number_jobs_per_week65() : any {
                        return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin65 /100))/52) / this.profit.average_sales_value).toFixed(0);}
             
  number_jobs_per_week70() : any {
                          return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin70 /100))/52) / this.profit.average_sales_value).toFixed(0);}
               
  number_leads_per_week() : any {
      return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
 
  number_leads_per_week_fixed() : any {
        return (((((this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}    

  number_leads_per_week5() : any {
        return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin5 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
   
  number_leads_per_week10() : any {
          return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin10 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
              

  number_leads_per_week15() : any {
        return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
      
  number_leads_per_week20() : any {
        return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
      
  number_leads_per_week25() : any {
          return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
      
  number_leads_per_week30() : any {
            return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
          
  number_leads_per_week35() : any {
              return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
            
  number_leads_per_week40() : any {
                return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
          
  number_leads_per_week45() : any {
                  return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin45 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
            
  number_leads_per_week50() : any {
                    return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin50 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
       
  number_leads_per_week55() : any {
                      return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin55 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
         
  number_leads_per_week60() : any {
                        return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin60 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
           
  number_leads_per_week65() : any {
                          return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin65 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
             
  number_leads_per_week70() : any {
                            return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin70 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
               
  constructor(
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private fb: FormBuilder, 
    private router: Router) {}

  ngOnInit() {

    
    
  }
  closeModal():void{
    this.modalController.dismiss();
    
}
doRefresh(event: any) {
  setTimeout(() => {
    event.target.complete();
  }, 2000);


  
  console.log(event);
}
segmentChanged(){
  console.log(this.segmentModel);
  
  console.log(event);
}
}