import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { OfferingDetailModalPage } from '../offering-detail-modal/offering-detail-modal.page';


@Component({
  selector: 'app-offering-modal',
  templateUrl: './offering-modal.page.html',
  styleUrls: ['./offering-modal.page.scss'],
})
export class OfferingModalPage implements OnInit {

  uid = this.authService.getUid(); 
  
  promos: any[];
  loading = true;
  error: any;
  dataReturned: any;
  todays: any[];
  homes: any[];
 

  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let promoUID = this.navParams.get('promoUID');
    let territory_number = this.navParams.get('territory_number');
    let territoryID = this.navParams.get('territoryID');
    let todayID = this.navParams.get('todayID');
    let dayID = this.navParams.get('dayID');
    let zipID = this.navParams.get('zipID');
    console.log('dayID',dayID);
    console.log('zipID',zipID);
    console.log('promoUID',promoUID);
    console.log('todayID',todayID);
    console.log('territoryID',territoryID);

   

    this.apollo
    .watchQuery({
      query: gql`
         query todays
{
todays{
  todayID
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banners_place{
              territory_number
              bannerID
              banners{
                headline
              }
            }
            promoss_place{
              territory_number
              promoID
              promo{
                promotion
              }
            }
  }
  }
}


      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
  

  
  this.apollo
  .watchQuery({
    query: gql`
      query promos($promoUID:ID)
{
promos(where:{promoUID:$promoUID}){
  promoUID
 promotion
 description
 image_path
  offering{
    offeringUID
    image_path
    offering
  }
  promo_offering{
      offeringUID
    promo_image_path
    offering_image_path
    offering
    }
}
}

    `,
           //variables: {uid:this.uid} , 
       variables:    {promoUID: promoUID},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.promos = result?.data?.promos;
    this.loading = result.loading;
    this.error = result.error;
  });



  }
async openModalOffering(offeringUID,todayID,territoryID, zipID,dayID) {
  const modal = await this.modalController.create({
    component: OfferingDetailModalPage,
    cssClass: 'my-modal-class',
    id:"offerings",
    componentProps: {
      
      uid:this.uid,
      offeringUID:  offeringUID,
      todayID:todayID,
      territoryID:territoryID,
      zipID:zipID,
      dayID:dayID
      
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
closeModal():void{
  this.modalController.dismiss();
}

}


