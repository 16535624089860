import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ActivatedRoute} from '@angular/router'
import { Observable, BehaviorSubject } from 'rxjs';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { RfqHomePage } from '../rfq-home/rfq-home.page';
import { RfqPage } from '../rfq/rfq.page';


@Component({
  selector: 'app-cat',
  templateUrl: './cat.page.html',
  styleUrls: ['./cat.page.scss'],
})
export class CatPage implements OnInit {

  //uid = this.authService.getUid(); 
  uid = "VO74uw2G04dtfBL0ooDQ9PFbGee2"

  loading = true;
  error: any;
  territoryID: any;
  zipID: any;
  businessTypeID: any;
  categories:any[];
  dataReturned: any[];
  constructor(private afAuth: AngularFireAuth,
              private authService: AuthService, 
              private navService: NavService,
              private apollo: Apollo, 
              private router: Router,
              private modalController: ModalController,
              private navParams: NavParams,
              ) {}

  ngOnInit() {
    let categoryID = this.navParams.get('categoryID');
    let businessTypeID = this.navParams.get('businessTypeID');
    let zipID = this.navParams.get('zipID');
    let todayID = this.navParams.get('todayID');
    let territory_number = this.navParams.get('territory_number');
    let territoryID = this.navParams.get('territoryID');
    console.log('catD',categoryID),
    console.log('territory_number',territory_number),
    console.log('territoryID',territoryID),
    console.log('bt',this.businessTypeID),
    console.log('zipID',this.zipID),
    console.log('todayID',todayID)
  
  this.apollo
    .watchQuery({
      query: gql`
       query Category($categoryID:ID) {
          categories(where:{categoryID:$categoryID}){
            category
            categoryID
            image_path
            image_path_header
              subcategory(options:{sort:{subcategory:ASC}})
{
                subcategory
                subcategoryID
                slug
              }
   
    
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {categoryID:categoryID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.categories = result?.data?.categories;
      this.loading = result.loading;
      this.error = result.error;
    });
  }
  close():void{
      this.modalController.dismiss();
    }
    closeModal():void{
      //this.modalController.dismiss();
      this.modalController.dismiss(null, null, "categoryhomepage");
    }
    async openModal(subcategoryID,categoryID,todayID,territoryID) {
      const modal = await this.modalController.create({
        component: RfqHomePage,
        cssClass: 'my-modal-class',
        id:"rfqpage" ,
        componentProps: {
          
          uid:this.uid,
          subcategoryID:  subcategoryID,
          categoryID:  categoryID,
          territoryID: territoryID,
          businessTypeID: this.businessTypeID,
          //zipID:this.zipID,
          todayID:todayID,
        }
      });
   
      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned !== null) {
          this.dataReturned = dataReturned.data;
          //alert('Modal Sent Data :'+ dataReturned);
        }
      });
   
      return await modal.present();
    }
    
  

}
