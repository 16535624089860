import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-backcost',
  templateUrl: './backcost.page.html',
  styleUrls: ['./backcost.page.scss'],
})
export class BackcostPage implements OnInit {

  segmentModel = "cogs";
event:any;
  

  backcost = {"sales_price":0,
              "labor_cost":0,
               "material_cost":0, 
               "subcontractor_cost":0, 
               "fee_cost":0,
               "selling_price":0,
             
              "markup10":10.00,   
              "markup15":15, 
              "markup20":20,
              "markup25":25, 
              "markup30":30,
              "markup35":35, 
              "markup40":40, 
              "markup45":45,
              "markup50":50, 
              "markup55":55, 
              "markup60":60, 
              "markup65":65,
              "markup70":70,
              "markup75":75,
              "markup80":80,
              "markup85":85,
              "markup90":90,
              "markup95":95,
              "markup100":100,
              "markup125":125,
              "markup150":150,
              "markup175":175,
              "markup200":200,
              "markup225":225, 
              "markup250":250,
              "profit_margin10":10,
              "profit_margin15":15,
              "profit_margin20":20,
              "profit_margin25":25,

              "profit_margin30":30,
              "profit_margin35":35,
              "profit_margin40":40,
              "profit_margin45":45,

              "profit_margin50":50,
              "profit_margin55":55,
              "profit_margin60":60,
              "profit_margin65":65,

              "profit_margin70":70,
              "profit_margin75":75,
              "profit_margin80":80,
              "profit_margin85":85,

              "profit_margin_target":35,





              }
  
target_selling_price_variance() : any {
                return ((this.backcost.selling_price- (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin_target) /100))).toFixed(2);

              }

              
  target_selling_price() : any {
                return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin_target) /100)).toFixed(2) ;

              }

  cost_of_goods_sold() : any {
                return this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
              }
  profit() : any {
    return (this.backcost.selling_price - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2);
  }

  profit_target() : any {
    return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin_target) /100)
      -(this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2);
  }

 
  gross_profit_margin() : any {
    return ((this.backcost.selling_price - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / this.backcost.selling_price *100).toFixed(2);
  }

  gross_profit_margin_difference() : any {
    return (((this.backcost.selling_price - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / this.backcost.selling_price *100)
    - (this.backcost.profit_margin_target)).toFixed(2)
    ;
  }
  
  markup() : any {
    return (((this.backcost.selling_price - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)))/((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost ))*100).toFixed(2);
  }

  markup_difference() : any {
    return ((((this.backcost.selling_price - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)))/((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost ))*100)
            - (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin_target) /100) - 
            (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)))/((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost ))*100).toFixed(2);
    ;
  }



  markup_target() : any {
    return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin_target) /100) - 
      (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)))/((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost ))*100).toFixed(2);
  }
  price = { "hourly_rate": 85, "number_of_workers": 1, "hours_required":1 ,"overhead":10000,"annual_chargeable_hours":1640, "travel_time":0, "cost_of_materials":0, "cost_of_subcontractors":0, "rental_fees":0, "desired_profit_margin":35, "profit_margin10":10, "profit_margin15":15, "profit_margin20":20, "profit_margin25":25, "profit_margin30":30, "profit_margin35":35, "profit_margin40":40, "profit_margin45":45};
  
  total_hours() : any {
    return this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time;
}
  cost_of_labor() : any {
  return this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time) ;
}

total_chargeable_hours(): any{
  return this.price.number_of_workers * this.price.annual_chargeable_hours
}

overhead_cost_per_hour() {
  return (this.price.overhead /((this.price.number_of_workers * this.price.annual_chargeable_hours))).toFixed(2)
}

overhead_cost_per_project() {
  return (((this.price.overhead /((this.price.number_of_workers * this.price.annual_chargeable_hours)))) * this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time).toFixed(2)
}

total_cost_of_goods_sold() : any {
  return this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees ;
}

desired_profit_margin_percent_calc() : any {
  return (1 -(this.price.desired_profit_margin) /100).toFixed(4) ;
}



//Sales Price - Cost of Goods Sold
gross_profit() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}

gross_profit10() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}

gross_profit15() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;

}

gross_profit20() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}

gross_profit25() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit30() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit35() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit40() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit45() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit50() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit55() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit60() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit65() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit70() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit75() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit80() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}
gross_profit85() : any {
  return (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)).toFixed(2) ;
}

profit_expense_percent() : any {
return((this.price.desired_profit_margin/100).toFixed(4))
}
labor_expense_percent() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))).toFixed(4) ;
}

labor_expense_percent10() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100))*100).toFixed(2);
}
labor_expense_percent15() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100))*100).toFixed(2);
}
labor_expense_percent20() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100))*100).toFixed(2);
}
labor_expense_percent25() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100))*100).toFixed(2);
}
labor_expense_percent30() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100))*100).toFixed(2);
}
labor_expense_percent35() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100))*100).toFixed(2);
}
labor_expense_percent40() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100))*100).toFixed(2);
}
labor_expense_percent45() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100))*100).toFixed(2);
}
labor_expense_percent50() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100))*100).toFixed(2);
}
labor_expense_percent55() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100))*100).toFixed(2);
}
labor_expense_percent60() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100))*100).toFixed(2);
}
labor_expense_percent65() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100))*100).toFixed(2);
}
labor_expense_percent70() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100))*100).toFixed(2);
}
labor_expense_percent75() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100))*100).toFixed(2);
}
labor_expense_percent80() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100))*100).toFixed(2);
}
labor_expense_percent85() : any {
  return ((this.backcost.labor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100))*100).toFixed(2);
}



material_expense_percent() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))).toFixed(4) ;
}
material_expense_percent10() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100))*100).toFixed(2);
}
material_expense_percent15() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100))*100).toFixed(2);
}
material_expense_percent20() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100))*100).toFixed(2);
}
material_expense_percent25() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100))*100).toFixed(2);
}
material_expense_percent30() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100))*100).toFixed(2);
}
material_expense_percent35() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100))*100).toFixed(2);
}
material_expense_percent40() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100))*100).toFixed(2);
}
material_expense_percent45() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100))*100).toFixed(2);
}
material_expense_percent50() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100))*100).toFixed(2);
}
material_expense_percent55() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100))*100).toFixed(2);
}
material_expense_percent60() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100))*100).toFixed(2);
}
material_expense_percent65() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100))*100).toFixed(2);
}
material_expense_percent70() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100))*100).toFixed(2);
}
material_expense_percent75() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100))*100).toFixed(2);
}
material_expense_percent80() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100))*100).toFixed(2);
}
material_expense_percent85() : any {
  return ((this.backcost.material_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100))*100).toFixed(2);
}
subcontractor_expense_percent() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))).toFixed(4) ;
}
subcontractor_expense_percent10() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100))*100).toFixed(2);
}
subcontractor_expense_percent15() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100))*100).toFixed(2);
}
subcontractor_expense_percent20() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100))*100).toFixed(2);
}
subcontractor_expense_percent25() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100))*100).toFixed(2);
}
subcontractor_expense_percent30() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100))*100).toFixed(2);
}
subcontractor_expense_percent35() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100))*100).toFixed(2);
}
subcontractor_expense_percent40() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100))*100).toFixed(2);
}
subcontractor_expense_percent45() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100))*100).toFixed(2);
}
subcontractor_expense_percent50() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100))*100).toFixed(2);
}
subcontractor_expense_percent55() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100))*100).toFixed(2);
}
subcontractor_expense_percent60() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100))*100).toFixed(2);
}
subcontractor_expense_percent65() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100))*100).toFixed(2);
}
subcontractor_expense_percent70() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100))*100).toFixed(2);
}
subcontractor_expense_percent75() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100))*100).toFixed(2);
}
subcontractor_expense_percent80() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100))*100).toFixed(2);
}
subcontractor_expense_percent85() : any {
  return ((this.backcost.subcontractor_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100))*100).toFixed(2);
}
rental_fees_expense_percent10() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100))*100).toFixed(2);
}
rental_fees_expense_percent15() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100))*100).toFixed(2);
}
rental_fees_expense_percent20() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100))*100).toFixed(2);
}
rental_fees_expense_percent25() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100))*100).toFixed(2);
}
rental_fees_expense_percent30() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100))*100).toFixed(2);
}
rental_fees_expense_percent35() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100))*100).toFixed(2);
}
rental_fees_expense_percent40() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100))*100).toFixed(2);
}
rental_fees_expense_percent45() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100))*100).toFixed(2);
}
rental_fees_expense_percent50() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100))*100).toFixed(2);
}
rental_fees_expense_percent55() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100))*100).toFixed(2);
}
rental_fees_expense_percent60() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100))*100).toFixed(2);
}
rental_fees_expense_percent65() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100))*100).toFixed(2);
}
rental_fees_expense_percent70() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100))*100).toFixed(2);
}
rental_fees_expense_percent75() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100))*100).toFixed(2);
}
rental_fees_expense_percent80() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100))*100).toFixed(2);
}
rental_fees_expense_percent85() : any {
  return ((this.backcost.fee_cost ) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100))*100).toFixed(2);
}
// Total cost of goods sold / (1-profit margin)*100
// Total cost of goods sold / (1-profit margin)*100

sales_price10() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100)).toFixed(2) ;

}
sales_price15() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100)).toFixed(2) ;
}
sales_price20() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100)).toFixed(2) ;
}

sales_price25() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100)).toFixed(2) ;
}
// Total cost of goods sold / (1-profit margin)*100
sales_price30() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100)).toFixed(2) ;
}
// Total cost of goods sold / (1-profit margin)*100

sales_price35() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100)).toFixed(2) ;
}
// Total cost of goods sold / (1-profit margin)*100

sales_price40() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100)).toFixed(2) ;
}
sales_price45() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100)).toFixed(2) ;
}
sales_price50() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100)).toFixed(2) ;
}
sales_price55() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100)).toFixed(2) ;
}
sales_price60() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100)).toFixed(2) ;
}
sales_price65() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100)).toFixed(2) ;
}
sales_price70() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100)).toFixed(2) ;
}
sales_price75() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100)).toFixed(2) ;
}
sales_price80() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100)).toFixed(2) ;
}
sales_price85() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100)).toFixed(2) ;
}
cost_of_goods_sold_percent10() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))).toFixed(4) ;
}

cost_of_goods_sold_percent15() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent20() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent25() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent30() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent35() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent40() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent45() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent50() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent55() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent60() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent65() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent70() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent75() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent80() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100))*100).toFixed(2);
}
cost_of_goods_sold_percent85() : any {
  return ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) /((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100))*100).toFixed(2);
}








net_profit(): any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100)) .toFixed(2) ;

}

profit10() : any {
  return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup10/100);
  }
profit15() : any {
return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup15/100);
}
profit20() : any {
  return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup20/100);
  }
profit25() : any {
    return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup25/100);
    }
profit30() : any {
      return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup30/100);
      }
profit35() : any {
        return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup35/100);
        }
profit40() : any {
          return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup40/100);
          }
profit45() : any {
            return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup45/100);
            }
profit50() : any {
              return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup50/100);
              }
profit55() : any {
                return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup55/100);
                }
profit60() : any {
            return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup60/100);
                  }
profit65() : any {
            return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup65/100);             
}
profit70() : any {
  return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup70/100);
  }
profit75() : any {
    return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup75/100);
    }
profit80() : any {
      return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup80/100);
      }
profit85() : any {
        return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup85/100);
        }
profit90() : any {
          return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup90/100);
          }
profit95() : any {
            return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup95/100);
            }
profit100() : any {
              return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup100/100);
              }
profit125() : any {
                return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup125/100);
                }
profit150() : any {
                  return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup150/100);
                  }
profit175() : any {
                    return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup175/100);
                    }
profit200() : any {
                      return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup200/100);
                      }
profit225() : any {
                        return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup225/100);
                        }
profit250() : any {
                          return (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup250/100);
                          }

price10() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup10/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}

price15() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup15/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price20() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup20/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price25() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup25/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price30() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup30/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price35() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup35/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price40() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup40/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price45() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup45/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price50() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup50/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price55() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup55/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price60() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup60/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price65() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup65/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price70() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup70/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price75() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup75/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price80() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup80/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price85() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup85/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price90() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup90/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price95() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup95/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price100() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup100/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price125() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup125/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price150() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup150/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price175() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup175/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price200() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup200/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price225() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup225/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}
price250() : any {
  return   ((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup250/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost;
}


//Margin = Cost of Goods Sold * Markup / selling Price
// Profit /Price
//Margin used for Markup sheet
margin10() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup10/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup10/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}

margin15() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup15/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup15/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin20() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup20/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup20/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin25() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup25/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup25/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin30() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup30/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup30/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin35() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup35/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup35/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin40() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup40/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup40/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin45() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup45/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup45/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin50() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup50/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup50/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin55() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup55/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup55/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin60() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup60/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup60/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin65() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup65/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup65/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin70() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup70/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup70/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin75() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup75/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup75/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin80() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup80/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup80/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin85() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup85/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup85/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin90() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup90/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup90/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin95() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup95/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup95/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin100() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup100/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup100/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin125() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup125/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup125/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin150() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup150/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup150/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin175() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup175/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup175/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin200() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup200/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup200/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin225() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup225/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup225/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
margin250() : any {
  return ((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost + this.backcost.fee_cost) * (this.backcost.markup250/100))) / (((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) * (this.backcost.markup250/100)) + this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)*100).toFixed(2);
}
//GrossProfit/ Cost of goods sold
markup10margin () {
return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin10) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
}
markup15margin () {
  return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin15) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
  }
markup20margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin20) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
    }
markup25margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin25) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
      }
markup30margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin30) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
        }
markup35margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin35) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
          }
markup40margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin40) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
            }
markup45margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin45) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
              }
markup50margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin50) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
             }
markup55margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin55) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
               }
markup60margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin60) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
            }
            
markup65margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin65) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
                        }
markup70margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin70) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
              }
markup75margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin75) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
          }
markup80margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin80) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
                      }
markup85margin () {
    return (((((this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost) / (1 -(this.backcost.profit_margin85) /100)) - (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost)) / (this.backcost.labor_cost + this.backcost.material_cost +this.backcost.subcontractor_cost +this.backcost.fee_cost))*100).toFixed(2);
                                          }
markup_flag () {
  return "Increase markup by this.to meet targeted profit "
}

  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private router: Router) {}

  ngOnInit() {

    
  }
  closeModal():void{
    this.modalController.dismiss();
    
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
}
