import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NavParams } from '@ionic/angular';

import { LoadingController,ModalController } from '@ionic/angular';
import { Jobs1Page } from '../jobs1/jobs1.page';
import { TaskPage } from '../task/task.page';
import { MapquestPage } from '../mapquest/mapquest.page';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-principle',
  templateUrl: './principle.page.html',
  styleUrls: ['./principle.page.scss'],
})
export class PrinciplePage implements OnInit {

  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  principlePlacements: any[];
  principleUID:any;
  dataReturned: string[];
  libraryUID:any;

  constructor(private authService: AuthService, 
    //private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

  ngOnInit() {
    let principleUID = this.navParams.get('principleUID');
    this.uid=this.uid

    this.apollo
    .watchQuery({
      query: gql`
        query principlePlacements($principleUID:ID)
{
  principlePlacements(where:{principleUID:$principleUID}){
    principlePlacementUID
    principleUID
    principle
    principleID
    principle_image_path
    pillar_image_path
    pillar_description
    pillar_heading

    week
    weekID
    yearNumber
    
    division
    divisionID
  }
}

 
      `,
             //variables: {uid:this.uid} , 
                 //variables: {supplierUID: this.uid} ,
                // variables:    {supplierID: this.afAuth.auth.currentUser.uid},
                 variables:    {principleUID: this.principleUID},


         
    })
    .valueChanges.subscribe((result: any) => {
      this.principlePlacements = result?.data?.principlePlacements;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid)
  }
  closeModal():void{
    this.modalController.dismiss(null, null, "principle");
}

}