import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SubscriptionDetailPage } from '../subscription-detail/subscription-detail.page';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.page.html',
  styleUrls: ['./subscription.page.scss'],
})
export class SubscriptionPage implements OnInit {
  suppliers: any[];
  Suppliers: any[];
  plans: any[];
  Plans: any[];
  email: string[];
  loading = true;
  error: any;
  planID: any
  dataReturned: string[];
 

  uid = this.authService.getUid(); 

  constructor(private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    this.apollo
      .watchQuery({
        query: gql`
           query Supplier($uid:ID) {
          suppliers(where:{uid:$uid}) 
        {
          supplierID
          uid
   
    email
    password
    username
    supplier_info{
     
      company
      founded
      license
      tagline
      bio
      employee
    }
    
  }
}
        `,
               //variables: {uid:this.uid} , 
           variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.suppliers = result?.data?.suppliers;
        this.loading = result.loading;
        this.error = result.error;
      });

      this.apollo
      .watchQuery({
        query: gql`
           query Plans {
          plans 
        {
          planID
          plan_number
          description
          amount
  }
}
        `,
               //variables: {uid:this.uid} , 
           //variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.plans = result?.data?.plans;
        this.loading = result.loading;
        this.error = result.error;
      });
    }
    async openModal(planID) {
      const modal = await this.modalController.create({
        component: SubscriptionDetailPage,
        id:"subscription",
        componentProps: {
          
          uid:this.uid,
          planID:planID
        }
      });
   
      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned !== null) {
          this.dataReturned = dataReturned.data;
          //alert('Modal Sent Data :'+ dataReturned);
        }
      });
   
      return await modal.present();
    }
    
  closeModal():void{
      this.modalController.dismiss();
  }
  }
  


