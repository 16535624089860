import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.page.html',
  styleUrls: ['./strategy.page.scss'],
})
export class StrategyPage implements OnInit {

  segmentModel = "story";

  profit = { "desired_profit": 200000, "fixed_cost": 250000, "target_gross_margin":40,"target_gross_lower_margin":30, "average_sales_value":2500, "conversion_rate":60,"target_gross_margin15":15,"target_gross_margin20":20, "target_gross_margin25":25, "target_gross_margin30":30, "target_gross_margin35":35, "target_gross_margin40":40};
  
  percent_factor () {
    return (((this.profit.target_gross_margin / this.profit.target_gross_lower_margin  ))-1).toFixed(2)
  }

  margin_difference () {
    return this.profit.target_gross_margin - this.profit.target_gross_lower_margin
  }
  desired_profit () {
    return (this.profit.desired_profit.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
  }


  fixed_cost () {
    return (this.profit.fixed_cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
  }

  average_sales_value () {
    return (this.profit.average_sales_value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
  }
  required_gross_profit() : any {
    return (this.profit.desired_profit + this.profit.fixed_cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ;
  }
  annual_sales_required() : any {
    return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}

  annual_sales_required_lower() : any {
    return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_lower_margin /100)).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  annual_sales_required15() : any {
      return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100)).toFixed(2);}
  
  annual_sales_required20() : any {
      return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100)).toFixed(2);}
  
  annual_sales_required25() : any {
        return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100)).toFixed(2);}
    
  annual_sales_required30() : any {
          return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100)).toFixed(2);}
      
  annual_sales_required35() : any {
            return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100)).toFixed(2);}
    
  annual_sales_required40() : any {
              return ((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100)).toFixed(2);}
          
            
  weekly_sales_required() : any {
    return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52).toFixed(0);}

  weekly_sales_required15() : any {
      return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100))/52).toFixed(0);}

  weekly_sales_required20() : any {
      return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100))/52).toFixed(0);}
  
  weekly_sales_required25() : any {
        return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100))/52).toFixed(0);}
    
  weekly_sales_required30() : any {
          return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100))/52).toFixed(0);}
      
  weekly_sales_required35() : any {
        return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100))/52).toFixed(0);}
        
  weekly_sales_required40() : any {
        return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100))/52).toFixed(0);}

  monthly_sales() : any {
          return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/12).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
  
  monthly_sales_lower() : any {
            return (((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_lower_margin /100))/12).toLocaleString('en-US', { style: 'currency', currency: 'USD' });}
    
  number_jobs_per_month() : any {
          return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/12) / this.profit.average_sales_value).toFixed(0);}
       
  
  number_jobs_per_month_lower() : any {
            return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_lower_margin /100))/12) / this.profit.average_sales_value).toFixed(0);}
       
              
          
  number_jobs_per_week() : any {
    return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52) / this.profit.average_sales_value).toFixed(0);}
  
    
  number_jobs_per_week15() : any {
        return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100))/52) / this.profit.average_sales_value).toFixed(0);}
      
  number_jobs_per_week20() : any {
      return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100))/52) / this.profit.average_sales_value).toFixed(0);}
    
  number_jobs_per_week25() : any {
        return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100))/52) / this.profit.average_sales_value).toFixed(0);}
     
  number_jobs_per_week30() : any {
          return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100))/52) / this.profit.average_sales_value).toFixed(0);}
       
  number_jobs_per_week35() : any {
            return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100))/52) / this.profit.average_sales_value).toFixed(0);}
         
  number_jobs_per_week40() : any {
              return ((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100))/52) / this.profit.average_sales_value).toFixed(0);}
           
  number_leads_per_week() : any {
      return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
    
  number_leads_per_week15() : any {
        return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin15 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
      
  number_leads_per_week20() : any {
        return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin20 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
      
  number_leads_per_week25() : any {
          return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin25 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
      
  number_leads_per_week30() : any {
            return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin30 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
          
  number_leads_per_week35() : any {
              return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin35 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
            
  number_leads_per_week40() : any {
                return (((((this.profit.desired_profit + this.profit.fixed_cost) / (this.profit.target_gross_margin40 /100))/52) / this.profit.average_sales_value) /(this.profit.conversion_rate/100)).toFixed(0);}
              


  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private router: Router) {}

  ngOnInit() {

    
    
  }
  closeModal():void{
    this.modalController.dismiss();
    
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
}