import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NavParams } from '@ionic/angular';

import { LoadingController,ModalController } from '@ionic/angular';
import { LibraryPage } from '../library/library.page';
import { TaskPage } from '../task/task.page';
import { MapquestPage } from '../mapquest/mapquest.page';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ToolsPage } from '../tools/tools.page';

@Component({
  selector: 'app-toolcategory',
  templateUrl: './toolcategory.page.html',
  styleUrls: ['./toolcategory.page.scss'],
})
export class ToolcategoryPage implements OnInit {

  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  toolcategoryUID:any
  toolCategories: any[];
  libraryUID:any;
  principleUID:any;
  dataReturned: string[];
  constructor(private authService: AuthService, 
    //private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

    ngOnInit() {


      this.apollo
      .watchQuery({
        query: gql`
           query toolCategories
           {
    toolCategories{
      category
     
      toolcategoryID
      toolcategoryUID
      image_path
    }
  }
  
   
        `,
               //variables: {uid:this.uid} , 
                  
  
  
           
      })
      .valueChanges.subscribe((result: any) => {
        this.toolCategories = result?.data?.toolCategories;
        this.loading = result.loading;
        this.error = result.error;
      });
     
    }
    
    closeModal():void{
      this.modalController.dismiss(null, null, "toolcategory");
  }
  async openModalCategory(toolcategoryUID) {
    const modal = await this.modalController.create({
      component: ToolsPage,
      cssClass: 'my-modal-class',
      id:"tools",
      componentProps: {
        toolcategoryUID:toolcategoryUID
       // uid:this.uid,
     
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
  
    return await modal.present();
  }
  }
