import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
const updateSupplierLicenses = gql`
 
mutation updateSupplierLicenses($supplierlicenseID:ID, 
  													
														$license_number:String,
  													$county_license: String,
  													$state_license: String,
  												
														) {
 
    updateSupplierLicenses(where: {supplierlicenseID: $supplierlicenseID }
    														
   													update:{
														license_number:$license_number,
      											county_license:$county_license,
                            state_license:$state_license		
                            }
       
    ){
        supplierLicenses{
         
         license_number
         county_license
         state_license
         
          
        }
    
  }
}

  
`;

const deleteSupplierLicenses = gql`
 mutation deleteSupplierLicenses($supplierlicenseID:ID){
  deleteSupplierLicenses (where:{supplierlicenseID:$supplierlicenseID}){
    nodesDeleted
   
  }
}

  
`;

@Component({
  selector: 'app-license-modal',
  templateUrl: './license-modal.page.html',
  styleUrls: ['./license-modal.page.scss'],
})
export class LicenseModalPage implements OnInit {

  uid = this.authService.getUid(); 
  suppliers:string[];
  infos:string[];
  loading = true;
  error: any;
  isSubmitted = false;
  data:any;
  supplierlicenseID: any;

  //license Form
   licenseForm: FormGroup;
   license_number: string;
   county_license: string;
   state_license: string;




constructor(private authService: AuthService, 
  private fb: FormBuilder, 
  private afAuth: AngularFireAuth,
  private navService: NavService,
  private apollo: Apollo,
  private modalController: ModalController,
  public toastController: ToastController,
  public alertController: AlertController,
  private navParams: NavParams,
  
  private router: Router) {}

ngOnInit() {
  //let supplierlicenseID = this.navParams.get('supplierlicenseID');
  let company = this.navParams.get('company');
  
 // console.log('supplierlicenseID', supplierlicenseID);
  console.log('company', company)

  this.licenseForm = this.fb.group({
    license_number:      [this.license_number],
    county_license:      [this.county_license],
    state_license:       [this.state_license],
   });


  this.apollo
  .watchQuery({
    query: gql`
       query suppliers($uid:ID)
      {
        suppliers(where:{uid:$uid}){
        uid
        supplierID
        email
          
        
          
          supplierSupplierInfo{
            company
            founded
            employee
            tagline
            base_location
            bio
            url
            insurance
            license
          }
          
          

}
}


    `,
           
       variables:    {uid: this.afAuth.auth.currentUser.uid},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.suppliers = result?.data?.suppliers;
    this.loading = result.loading;
    this.error = result.error;
  });

 


}
closeModal():void{
  this.modalController.dismiss();
}

async updateSupplierLicenses() {
  this.isSubmitted = true;
  if (!this.licenseForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.licenseForm.value)
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: updateSupplierLicenses,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    license_number:this.licenseForm.value.license_number,
    county_license:this.licenseForm.value.county_license,
    state_license:this.licenseForm.value.state_license,
    supplierlicenseID:this.supplierlicenseID
    
   
    
    
     
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);    
    console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
}
async deleteSupplierLicenses() {

this.apollo.mutate({
  mutation: deleteSupplierLicenses,
  
  variables: {
  
  supplierlicenseID:this.supplierlicenseID
 
  
  
   
},
  
}).subscribe(({ data }) => {
  

   console.log(data);    
  console.log("Deleted!");


//this.navCtrl.navigateForward('/setup'); 
//this.router.navigate(['/app/setup/',this.id ]);
 
}, (error) => {
  console.log('there was an big ass error sending the query', error);
  
  //this.navCtrl.navigateRoot('/setup');
});
}
}
