import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MapquestPageRoutingModule } from './mapquest-routing.module';

import { MapquestPage } from './mapquest.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MapquestPageRoutingModule
  ],
  declarations: [MapquestPage]
})
export class MapquestPageModule {}
