import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RoleGuard } from './guards/role.guard';
import { AutomaticLoginGuard } from './guards/automatic-login.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/']);

const routes: Routes = [

  
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule),
    canActivate: [AutomaticLoginGuard]
  },

  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },

  {
    path: 'landing-pro',
    loadChildren: () => import('./pages/landing-pro/landing-pro.module').then( m => m.LandingProPageModule)
  },

  {
    path: 'landing-home',
    loadChildren: () => import('./pages/landing-home/landing-home.module').then( m => m.LandingHomePageModule)
  },
  

  {
    path: 'about-pro',
    loadChildren: () => import('./pages/about-pro/about-pro.module').then( m => m.AboutProPageModule)
  },

  {
    path: 'tow',
    loadChildren: () => import('./pages/tow/tow.module').then( m => m.TowPageModule)
  },

  {
    path: 'profit',
    loadChildren: () => import('./pages/profit/profit.module').then( m => m.ProfitPageModule)
  },
  {
    path: 'banner',
    loadChildren: () => import('./pages/banner/banner.module').then( m => m.BannerPageModule)
  },
  {
    path: 'setup-Home',
    loadChildren: () => import('./pages/setup-home/setup-home.module').then( m => m.SetupHomePageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },

  {
    path: 'base-creation',
    loadChildren: () => import('./pages/base-creation/base-creation.module').then( m => m.BaseCreationPageModule)
  },
  
  
  {
    path: 'buyer',
    canActivate: [AngularFireAuthGuard, RoleGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      role: 'BUYER'
    },
    children: [ 
      {
        path: 'landing-app-pro',
        loadChildren: () => import('./pages/landing-app-pro/landing-app-pro.module').then( m => m.LandingAppProPageModule)
      },
      
      {
        path: 'desktop',
        loadChildren: () => import('./pages/desktop/desktop.module').then( m => m.DesktopPageModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'passport',
        loadChildren: () => import('./pages/passport/passport.module').then( m => m.PassportPageModule)
      },

      {
        path: 'strategy',
        loadChildren: () => import('./pages/strategy/strategy.module').then( m => m.StrategyPageModule)
      },
      {
        path: 'backcost',
        loadChildren: () => import('./pages/backcost/backcost.module').then( m => m.BackcostPageModule)
      },
      {
        path: 'price',
        loadChildren: () => import('./pages/price/price.module').then( m => m.PricePageModule)
      },
      {
        path: 'profit',
        loadChildren: () => import('./pages/profit/profit.module').then( m => m.ProfitPageModule)
      },
      {
        path: 'markup',
        loadChildren: () => import('./pages/markup/markup.module').then( m => m.MarkupPageModule)
      },
      {
        path: 'labor',
        loadChildren: () => import('./pages/labor/labor.module').then( m => m.LaborPageModule)
      },
      {
        path: 'subscription',
        loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
      },
      {
        path: 'subscription-detail',
        loadChildren: () => import('./pages/subscription-detail/subscription-detail.module').then( m => m.SubscriptionDetailPageModule)
      },
      {
        path: 'subscription-detail/:planID',
        loadChildren: () => import('./pages/subscription-detail/subscription-detail.module').then( m => m.SubscriptionDetailPageModule)
      },
      {
        path: 'list',
        loadChildren: () => import('./pages/buyer-list/buyer-list.module').then( m => m.BuyerListPageModule)
      },
      {
        path: 'list/:id',
        loadChildren: () => import('./pages/buyer-list-details/buyer-list-details.module').then( m => m.BuyerListDetailsPageModule)
      },
      {
        path: '',
        redirectTo: 'landing-app-pro',
        pathMatch: 'full'
      },
      {
        path: 'setup',
        loadChildren: () => import('./pages/setup/setup.module').then( m => m.SetupPageModule)
      },
      
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
      },
      {
        path: 'home',
        
        loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'jobs',
        loadChildren: () => import('./pages/jobs/jobs.module').then( m => m.JobsPageModule)
      },
      {
        path: 'task/:uuid',
        loadChildren: () => import('./pages/task/task.module').then( m => m.TaskPageModule)
      },
      {
        path: 'connect/:uuid',
        loadChildren: () => import('./pages/connect/connect.module').then( m => m.ConnectPageModule)
      },
      {
        path: 'cartitem',
        loadChildren: () => import('./pages/cartitem/cartitem.module').then( m => m.CartitemPageModule)
      },
      {
        path: 'discover',
        loadChildren: () => import('./pages/discover/discover.module').then( m => m.DiscoverPageModule)
      },
      {
        path: 'territory',
        loadChildren: () => import('./pages/territory/territory.module').then( m => m.TerritoryPageModule)
      },
      {
        path: 'territory/:territoryID',
        loadChildren: () => import('./pages/territory-detail/territory-detail.module').then( m => m.TerritoryDetailPageModule)
      },
      {
        path: 'territory-detail',
        loadChildren: () => import('./pages/territory-detail/territory-detail.module').then( m => m.TerritoryDetailPageModule)
      },
      {
        path: 'business-type',
        loadChildren: () => import('./pages/business-type/business-type.module').then( m => m.BusinessTypePageModule)
      },
      {
        path: 'business-type/:businessTypeID',
        loadChildren: () => import('./pages/business-type-detail/business-type-detail.module').then( m => m.BusinessTypeDetailPageModule)
      },
      {
        path: 'business-type-detail',
        loadChildren: () => import('./pages/business-type-detail/business-type-detail.module').then( m => m.BusinessTypeDetailPageModule)
      },
      {
        path: 'category',
        loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
      },
      {
        path: 'category/:categoryID',
        loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
      },
      {
        path: 'category-detail',
        loadChildren: () => import('./pages/category-detail/category-detail.module').then( m => m.CategoryDetailPageModule)
      },
      
      {
        path: 'subcategory',
        loadChildren: () => import('./pages/subcategory/subcategory.module').then( m => m.SubcategoryPageModule)
      },
      {
        path: 'subcategory/:subcategoryID',
        loadChildren: () => import('./pages/subcategory/subcategory.module').then( m => m.SubcategoryPageModule)
      },
      {
        path: 'subcategory-detail',
        loadChildren: () => import('./pages/subcategory-detail/subcategory-detail.module').then( m => m.SubcategoryDetailPageModule)
      },
      {
        path: 'invites',
        loadChildren: () => import('./pages/invites/invites.module').then( m => m.InvitesPageModule)
      },
      {
        path: 'invite-detail/:inviteID',
        loadChildren: () => import('./pages/invite-detail/invite-detail.module').then( m => m.InviteDetailPageModule)
      },
      {
        path: 'checkout',
        loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
      },
      {
        path: 'accept',
        loadChildren: () => import('./pages/accept/accept.module').then( m => m.AcceptPageModule)
      },
      {
        path: 'profile-pro',
        loadChildren: () => import('./pages/profile-pro/profile-pro.module').then( m => m.ProfileProPageModule)
      },
      {
        path: 'accept-detail',
        loadChildren: () => import('./pages/accept-detail/accept-detail.module').then( m => m.AcceptDetailPageModule)
      },
      {
        path: 'info-modal',
        loadChildren: () => import('./pages/info-modal/info-modal.module').then( m => m.InfoModalPageModule)
      },
      {
        path: 'address-modal',
        loadChildren: () => import('./pages/address-modal/address-modal.module').then( m => m.AddressModalPageModule)
      },
      {
        path: 'phone-modal',
        loadChildren: () => import('./pages/phone-modal/phone-modal.module').then( m => m.PhoneModalPageModule)
      },
      {
        path: 'license-modal',
        loadChildren: () => import('./pages/license-modal/license-modal.module').then( m => m.LicenseModalPageModule)
      },
      {
        path: 'task-supplier',
        loadChildren: () => import('./pages/task-supplier/task-supplier.module').then( m => m.TaskSupplierPageModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
      },
      {
        path: 'base',
        loadChildren: () => import('./pages/base/base.module').then( m => m.BasePageModule)
      },
      {
        path: 'base1',
        loadChildren: () => import('./pages/base1/base1.module').then( m => m.Base1PageModule)
      },
      {
        path: 'setup-modal',
        loadChildren: () => import('./pages/setup-modal/setup-modal.module').then( m => m.SetupModalPageModule)
      },
      {
        path: 'base-territory',
        loadChildren: () => import('./pages/base-territory/base-territory.module').then( m => m.BaseTerritoryPageModule)
      },
      {
        path: 'map',
        loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
      },
      {
        path: 'mapquest',
        loadChildren: () => import('./pages/mapquest/mapquest.module').then( m => m.MapquestPageModule)
      },
      {
        path: 'mapper',
        loadChildren: () => import('./pages/mapper/mapper.module').then( m => m.MapperPageModule)
      },
      {
        path: 'location',
        loadChildren: () => import('./pages/location/location.module').then( m => m.LocationPageModule)
      },
      {
        path: 'mymap',
        loadChildren: () => import('./pages/mymap/mymap.module').then( m => m.MymapPageModule)
      },
      {
        path: 'gis',
        loadChildren: () => import('./pages/gis/gis.module').then( m => m.GisPageModule)
      },
      {
        path: 'preference',
        loadChildren: () => import('./pages/preference/preference.module').then( m => m.PreferencePageModule)
      },
      {
        path: 'hashtag',
        loadChildren: () => import('./pages/hashtag/hashtag.module').then( m => m.HashtagPageModule)
      },
      {
        path: 'hashtag1',
        loadChildren: () => import('./pages/hashtag1/hashtag1.module').then( m => m.Hashtag1PageModule)
      },
      {
        path: 'jobshash',
        loadChildren: () => import('./pages/jobshash/jobshash.module').then( m => m.JobshashPageModule)
      },
      {
        path: 'mappassport',
        loadChildren: () => import('./pages/mappassport/mappassport.module').then( m => m.MappassportPageModule)
      },
      {
        path: 'setup-modal',
        loadChildren: () => import('./pages/setup-modal/setup-modal.module').then( m => m.SetupModalPageModule)
      },
      {
        path: 'pro-connect',
        loadChildren: () => import('./pages/pro-connect/pro-connect.module').then( m => m.ProConnectPageModule)
      },
      {
        path: 'pro-invite',
        loadChildren: () => import('./pages/pro-invite/pro-invite.module').then( m => m.ProInvitePageModule)
      },
      {
        path: 'pro-accept',
        loadChildren: () => import('./pages/pro-accept/pro-accept.module').then( m => m.ProAcceptPageModule)
      },
      {
        path: 'toolbelt',
        loadChildren: () => import('./pages/toolbelt/toolbelt.module').then( m => m.ToolbeltPageModule)
      },

      {
        path: 'principle',
        loadChildren: () => import('./pages/principle/principle.module').then( m => m.PrinciplePageModule)
      },
      {
        path: 'resource',
        loadChildren: () => import('./pages/resource/resource.module').then( m => m.ResourcePageModule)
      },
      {
        path: 'library',
        loadChildren: () => import('./pages/library/library.module').then( m => m.LibraryPageModule)
      },
      {
        path: 'tools',
        loadChildren: () => import('./pages/tools/tools.module').then( m => m.ToolsPageModule)
      },
      {
        path: 'toolcategory',
        loadChildren: () => import('./pages/toolcategory/toolcategory.module').then( m => m.ToolcategoryPageModule)
      },
      
    ]
  },
  {
    path: 'seller',
    canActivate: [AngularFireAuthGuard, RoleGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      role: 'SELLER'
    },
    children: [

      {
        path: 'landing-app-home',
        loadChildren: () => import('./pages/landing-app-home/landing-app-home.module').then( m => m.LandingAppHomePageModule)
      },
      {
        path: 'canvas',
        loadChildren: () => import('./pages/canvas/canvas.module').then( m => m.CanvasPageModule)
      },
      {
        path: 'ad-modal',
        loadChildren: () => import('./pages/ad-modal/ad-modal.module').then( m => m.AdModalPageModule)
      },
      {
        path: 'home-type',
        loadChildren: () => import('./pages/home-type/home-type.module').then( m => m.HomeTypePageModule)
      },
      {
        path: 'thelist',
        loadChildren: () => import('./pages/thelist/thelist.module').then( m => m.ThelistPageModule)
      },
      {
        path: 'thehashlist',
        loadChildren: () => import('./pages/thehashlist/thehashlist.module').then( m => m.ThehashlistPageModule)
      },
      {
        path: 'list',
        loadChildren: () => import('./pages/seller-list/seller-list.module').then( m => m.SellerListPageModule)
      },
      {
        path: 'list/new',
        loadChildren: () => import('./pages/seller-list-details/seller-list-details.module').then( m => m.SellerListDetailsPageModule)
      },
      {
        path: '',
        //redirectTo: 'list',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
      },
      {
        path: 'setup',
        loadChildren: () => import('./pages/setup/setup.module').then( m => m.SetupPageModule)
      },
      {
        path: 'entry',
        loadChildren: () => import('./pages/entry/entry.module').then( m => m.EntryPageModule)
      },
      {
        path: 'setup-home',
        loadChildren: () => import('./pages/setup-home/setup-home.module').then( m => m.SetupHomePageModule)
      },
      {
        path: 'setup-home-address',
        loadChildren: () => import('./pages/setup-home-address/setup-home-address.module').then( m => m.SetupHomeAddressPageModule)
      },
      {
        path: 'mylocation',
        loadChildren: () => import('./pages/mylocation/mylocation.module').then( m => m.MylocationPageModule)
      },
      {
        path: 'businesstype',
        loadChildren: () => import('./pages/businesstype/businesstype.module').then( m => m.BusinesstypePageModule)
      },
      {
        path: 'businesstype/:businessTypeID',
        loadChildren: () => import('./pages/businesstype/businesstype.module').then( m => m.BusinesstypePageModule)
      },
      {
        path: 'task-home',
        loadChildren: () => import('./pages/task-home/task-home.module').then( m => m.TaskHomePageModule)
      },
      {
        path: 'category-home',
        loadChildren: () => import('./pages/category-home/category-home.module').then( m => m.CategoryHomePageModule)
      },
      {
        path: 'profile-home',
        loadChildren: () => import('./pages/profile-home/profile-home.module').then( m => m.ProfileHomePageModule)
      },
      {
        path: 'task-home-detail',
        loadChildren: () => import('./pages/task-home-detail/task-home-detail.module').then( m => m.TaskHomeDetailPageModule)
      },
      {
        path: 'price',
        loadChildren: () => import('./pages/price/price.module').then( m => m.PricePageModule)
      },
    ]
  },
  {
    path: 'cart-modal',
    loadChildren: () => import('./pages/cart-modal/cart-modal.module').then( m => m.CartModalPageModule)
  },
  {
    path: 'data',
    loadChildren: () => import('./data/data.module').then( m => m.DataPageModule)
  },
  {
    path: 'setup',
    loadChildren: () => import('./pages/setup/setup.module').then( m => m.SetupPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  
  
  {
    path: 'jobs',
    loadChildren: () => import('./pages/jobs/jobs.module').then( m => m.JobsPageModule)
  },
  {
    path: 'jobs1',
    loadChildren: () => import('./pages/jobs1/jobs1.module').then( m => m.Jobs1PageModule)
  },
  {
    path: 'task',
    loadChildren: () => import('./pages/task/task.module').then( m => m.TaskPageModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/connect/connect.module').then( m => m.ConnectPageModule)
  },
  {
    path: 'cartitem',
    loadChildren: () => import('./pages/cartitem/cartitem.module').then( m => m.CartitemPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'worksheet',
    loadChildren: () => import('./pages/worksheet/worksheet.module').then( m => m.WorksheetPageModule)
  },
  {
    path: 'subscription-detail',
    loadChildren: () => import('./pages/subscription-detail/subscription-detail.module').then( m => m.SubscriptionDetailPageModule)
  },
  {
    path: 'discover',
    loadChildren: () => import('./pages/discover/discover.module').then( m => m.DiscoverPageModule)
  },
  {
    path: 'territory',
    loadChildren: () => import('./pages/territory/territory.module').then( m => m.TerritoryPageModule)
  },
  {
    path: 'territory-detail',
    loadChildren: () => import('./pages/territory-detail/territory-detail.module').then( m => m.TerritoryDetailPageModule)
  },
  {
    path: 'business-type',
    loadChildren: () => import('./pages/business-type/business-type.module').then( m => m.BusinessTypePageModule)
  },
  {
    path: 'business-type-detail',
    loadChildren: () => import('./pages/business-type-detail/business-type-detail.module').then( m => m.BusinessTypeDetailPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'category-detail',
    loadChildren: () => import('./pages/category-detail/category-detail.module').then( m => m.CategoryDetailPageModule)
  },
  {
    path: 'subcategory',
    loadChildren: () => import('./pages/subcategory/subcategory.module').then( m => m.SubcategoryPageModule)
  },
  {
    path: 'subcategory-detail',
    loadChildren: () => import('./pages/subcategory-detail/subcategory-detail.module').then( m => m.SubcategoryDetailPageModule)
  },
  {
    path: 'invites',
    loadChildren: () => import('./pages/invites/invites.module').then( m => m.InvitesPageModule)
  },
  {
    path: 'invite-detail',
    loadChildren: () => import('./pages/invite-detail/invite-detail.module').then( m => m.InviteDetailPageModule)
  },
  {
    path: 'accept',
    loadChildren: () => import('./pages/accept/accept.module').then( m => m.AcceptPageModule)
  },
  {
    path: 'accept-detail',
    loadChildren: () => import('./pages/accept-detail/accept-detail.module').then( m => m.AcceptDetailPageModule)
  },
  {
    path: 'setup-home',
    loadChildren: () => import('./pages/setup-home/setup-home.module').then( m => m.SetupHomePageModule)
  },
  {
    path: 'businesstype',
    loadChildren: () => import('./pages/businesstype/businesstype.module').then( m => m.BusinesstypePageModule)
  },
  {
    path: 'trades',
    loadChildren: () => import('./pages/trades/trades.module').then( m => m.TradesPageModule)
  },
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
