import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BannerModalSubcategoryPageRoutingModule } from './banner-modal-subcategory-routing.module';

import { BannerModalSubcategoryPage } from './banner-modal-subcategory.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BannerModalSubcategoryPageRoutingModule
  ],
  declarations: [BannerModalSubcategoryPage]
})
export class BannerModalSubcategoryPageModule {}
