
import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CartModalPage } from '../cart-modal/cart-modal.page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

const createSupplier = gql`
  mutation CreateSuppliers(
 
 $username:					String
 $email:						String
 $password:					String
 
 $uid:							ID
 $is_active:				Boolean



){
createSuppliers(input:
  {
    username:$username
    email:$email
    password:$password
    
    is_active:$is_active
    uid:$uid
   
  }) {
     suppliers{
      username
      email
      password
      supplierID
      is_active
      uid
      
    }
      
      
    }
}
  
`;


@Component({
  selector: 'app-setup',
  templateUrl: './setup.page.html',
  styleUrls: ['./setup.page.scss'],
})
export class SetupPage implements OnInit {
 
  email = this.authService.getEmail(); 
  //uid = this.authService.getUid(); 
  uid = this.authService.getUid(); 

  constructor(private navService: NavService, private authService: AuthService, 
    private modalCtrl: ModalController,private apollo: Apollo) { }

  ngOnInit() {
  }
  createSupplier(_formValue1) {

    let params = Object.assign(_formValue1)
    this.apollo.mutate({
      mutation:  createSupplier,
      
      variables: params,
     refetchQueries: [`Supplier`]
      
    }).subscribe(({ data }) => {
     // console.log('sendMessage: ', uuid);
   
       console.log(data);
       
  console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
     
    });


  }
  //gotoProfile() {
   // this.navService.gotoProfile();
  //}
}
