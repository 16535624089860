import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { environment } from "../../../environments/environment";
import { LoadingController, ToastController,AlertController } from "@ionic/angular";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { ProductService } from "../../services/product.service";
import { CartService } from "../../services/cart.service";
import { Router } from "@angular/router";
//newly added for create task
import {Apollo, gql} from 'apollo-angular';
import { ModalController } from '@ionic/angular';
import { AcceptPage } from '../accept/accept.page';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavParams } from '@ionic/angular';





declare var Stripe;

const paymentMethods = {
  STRIPE: {
    key: 'STRIPE',
    value: 1,
    title: 'Stripe Payment', 
  },
}
const CreateAccept = gql`
mutation CreateInviteAccept(
        $inviteID:ID, 
        $taskID:ID, 
        $connectID:ID,
        $homeID:ID,
        $supplierID:ID,
        $supplierUID:ID,
        $task:String,
        $dayID:ID,
        $weekID:ID,
        ){
        createAccepts(input:
                            {
    inviteID:$inviteID,
    taskID:$taskID,
   
    connectID:$connectID,
    homeID:$homeID,
    supplierID:$supplierID,
    supplierUID:$supplierUID,

    task:$task,
    dayID:$dayID,
    weekID:$weekID


    connects:{connect:{
        where:  {
          node:{connectID:$connectID}
        }
      }}


      homes: {connect:{
        where:  {
          node: {uid:$homeID}
        }
      }}
     tasks: {connect:{
        where:  {
          node: {taskID:$taskID}
        }
      }}
      
      invites:{connect:{
        where:  {
          node:{inviteID:$inviteID}
        }
      }}
      suppliers:{connect:{
        where:  {
          node:{uid:$supplierUID}
        }
      }}
      days:{connect:{
        where:  {
          node:{dayID:$dayID}
        }
      }}
      weeks:{connect:{
        where:  {
          node:{weekID:$weekID}
        }
      }}
      
  }){

  accepts{
    acceptID
    inviteID
    taskID
    connectID
    homeID
    dayID
    weekID
    supplierID
    supplierUID
    tasks{
      task_description
    }
    
    acceptHome{
      email
    }

    connects{
      connectID
    }
    invites{
        inviteID
      }
    homes{
      homeID
    }
    suppliers{
      supplierID
    }
    days{
      dayID
    }
    weeks{
      weekID
    }
    
  }
  
  
   
  }
 
}  
   
    



`;
@Component({
  selector: 'app-checkoutpaid',
  templateUrl: './checkoutpaid.page.html',
  styleUrls: ['./checkoutpaid.page.scss'],
})
export class CheckoutpaidPage implements OnInit {

  uid = this.authService.getUid();
  dataForm: FormGroup;
  cart = [];
  id:any;
  stripe;
  card;
  cardErrors;

  //newly added
  name:any;
  dataReturned: string[];
  acceptID:any;
  homeID:any;
  loading = true;
  error: any;
  supplierAddress:any[];
  suppliers: any[];
  aarbos:any[];
  todays:any;
  today:any;
  dayID:any;
  weekID:any;
  email:any;

  @ViewChild("cardElement", { static: true }) cardElement: ElementRef;

  

 

  constructor(
    //private navParams: NavParams,
    private navParams: NavParams,
    private loadingCtrl: LoadingController,
    private fb: FormBuilder,
    private authService: AuthService,
    private productService: ProductService,
    private toastCtrl: ToastController,
    private cartService: CartService,
    private router: Router,
    private apollo: Apollo,
    private afAuth: AngularFireAuth,
    public alertController: AlertController,
    private modalController: ModalController,
    
  ) {}

  ngOnInit() {

     

    this.dayID=this.navParams.get('dayID');
    this.weekID=this.navParams.get('weekID');

    //this.dayID=this.navParams.get('dayID');

    //form test

    this.dataForm = this.fb.group({
     
      task_description:   ['', [Validators.required]],
      
      name:        ['{{supplier.email}}'],
      task_why_now:       [''],
      itemID:             [''],
      action:             [''],
      checkValueAddress:  [''],
      make_model:         [''],
      years_old:          [''],
      territoryID:        [''],
      h39Index:           ['{{location.h39}}'],
      date:               [''],

      locationID:         [''],
      dayID:              [''],
      weekID:             [''],
      //createdAt:        ['now'],
      //itemID: ['', [Validators.required, Validators.minLength(2)]],
      

      
   
      
    });

    this.apollo
.watchQuery({
  query: gql`
     query SuppliersNew($uid:ID) {
suppliers(where:{uid:$uid}) 
    {
username
supplierID
uid
email
subscriptionConnect{
supplierID
}
supplierSupplierAddress{
  address
  city
  state
  zip

}
supplierSupplierStaff{
  name_first
  name_last

}
subscriptionTerritory{
territoryID
territory
}
subscriptionBusinessType{
businessTypeID
business_type
}
subscriptionTasks{
taskID
count_connect
connectSupplierID
territoryID
task_description
task_detail
date_create
mytaskConnects{
supplierID

}
category{
  category
}
subcategory{
  subcategory
}
item{
  item
  image_path
}
zip{
zip
town
}
}



territory{
        territoryID
        territory
      }

business_type {
businessTypeID
image_path
business_type


category  {
  categoryID
  image_path
  category
  connect{
    connectID
    task
  }
  tasks{
    taskID
    task_description
    date_create
    
   
    
      subcategory{
        subcategory
        image_path
        
      }
  
  
}

}


}
    }}


`,
variables:    {uid: this.uid},
//variables:    {uid: this.afAuth.auth.currentUser.uid},

})
.valueChanges.subscribe((result: any) => {
  this.suppliers = result?.data?.suppliers;
  this.loading = result.loading;
  this.error = result.error;
});

    this.apollo
    .watchQuery({
      query: gql`
         query todays
 {
 todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
 
      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays)

    this.apollo
    .watchQuery({
      query: gql`
         query aarbos
 {
 aarbos{
  mode
  
         
  }
 }
 
      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.aarbos = result?.data?.aarbos;
      this.loading = result.loading;
      this.error = result.error;
    });
    


    
    this.cartService.getCart().subscribe(res => {
      this.cart = res;
      
    });
    console.log('This is my Cart', this.cart);
    console.log('uid', this.uid);
    this.dataForm = this.fb.group({
      name: ["Maxine Aarbo", Validators.required],
      zip: ["48165", Validators.required],
      street: ["Klinkkampweg 30", Validators.required],
      city: ["Muenster", Validators.required],
      country: ["DE", Validators.required],
      state: ["New Jersey", Validators.required]

    });

    this.stripe = Stripe(environment.stripe_key);
    const elements = this.stripe.elements();

    this.card = elements.create("card");
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener("change", ({ error }) => {
      console.log("error: ", error);
      this.cardErrors = error && error.message;
    });
  }

  getTotal() {
    return this.cart.reduce((i, j) => i + j.inviteFee[0].amount * 1, 0);
  }

  async buyNow() {
    const stripeData = {
      payment_method_data: {
        billing_details: {
          name: this.dataForm.get("name").value,
          address: {
            line1: this.dataForm.get("street").value,
            city: this.dataForm.get("city").value,
            postal_code: this.dataForm.get("zip").value,
            country: this.dataForm.get("country").value
          },
          email: this.authService.getEmail()
        }
      },
      receipt_email: this.authService.getEmail()
    };

    const items = this.cart.map(item => {
      return { id: item.id, amount: item.amount };
    });

    const loading = await this.loadingCtrl.create();
    
    await loading.present();

    this.productService
      .startPaymentIntent(this.getTotal() * 100, items)
      .subscribe(async paymentIntent => {
        console.log("my payment intent: ", paymentIntent);
        const secret = paymentIntent.client_secret;

        const { result, err } = await this.stripe.handleCardPayment(
          secret,
          this.card,
          stripeData
        );

       //In production move the Create Invite Accept to the Sucess Area move away from error
        
        if (err) {
          await loading.dismiss();
          const toast = await this.toastCtrl.create({
            message: `Couldn't process payment, please try again later`,
            duration: 3000
          });
          await toast.present();
        } else {
          await loading.dismiss();
          const toast = await this.toastCtrl.create({
            position:"middle",
            color: "home",
            message: 'Thank you for your order, your referral is being processed',
            duration: 6000

          });
         this.cart.forEach(invite => this.CreateInviteAccept(invite.inviteID,invite.taskID,invite.connectID,invite.homeID,invite.supplierID,invite.task,this.dayID, this.weekID,this.uid));
          console.log('mycart',this.cart)
          console.log('success');
          await toast.present();
          this.modalController.dismiss(null, null, "invites");
          this.modalController.dismiss(null, null, "invitedetail");
          this.modalController.dismiss(null,null, "cart");
          this.router.navigateByUrl('/buyer/home');

          this.modalController.dismiss();

          
          //const alert = await this.alertController.create({
           // header: 'Use this lightsaber?',
           // message: 'Do you agree to use this lightsaber to do good across the galaxy?',
            //buttons: ['Disagree', 'Agree'],
           // buttons: [
             // {
               // text: 'Cancel',
                //role: 'cancel',
                //cssClass: 'secondary',
                
               // handler: (blah) => {
                 // console.log('Confirm Cancel: blah');
                //}
             // }, {
                //text: 'Okay',
                
                //handler: () => {
                  //console.log('Confirm Okay');
                  
                //}
             // }
          //  ]
         // });
          
          //console.log('success');
         // await alert.present();
      

  ///////Modal Creatation       
          //const modal = await this.modalController.create({
           // component: AcceptPage,
           // componentProps: {
              
            // "cart":this.cart
            //}
         // });
      
         // modal.onDidDismiss().then((dataReturned) => {
            //if (dataReturned !== null) {
             // this.dataReturned = dataReturned.data;
              //alert('Modal Sent Data :'+ dataReturned);
           // }
         // });
          //console.log('This is my Cart', this.cart);
          //return await modal.present();
      
         // this.router.navigateByUrl('/buyer/accept');
        }
      }, async err => {
        await loading.dismiss();
        const toast = await this.toastCtrl.create({
          message: `Couldn't process payment, please try again later`,
          duration: 3000
        });
        await toast.present();
      });
    


    }
    closeModal():void{
      this.modalController.dismiss();
      

    }
    async CreateInviteAccept(inviteID,taskID,connectID, homeID, supplierID, task,dayID,weekID,uid) {
      this.apollo.mutate({
        mutation:  CreateAccept,
        variables: {
          
            inviteID:inviteID,
            taskID:taskID,
            connectID:connectID,
            homeID:homeID,
            supplierID:supplierID,
            task:task,
            dayID:dayID,
            weekID:weekID,
            supplierUID:uid,
            //uid:uid,
          
          
         } ,
        
        
      }).subscribe(({ data }) => {
        //console.log('id: ', id);
     
        console.log('data',data);
        console.log('data',homeID);
        //this.router.navigateByUrl('/buyer/accept');
        this.router.navigateByUrl('/buyer/home');
  
        
      
      }, (error) => {
        console.log('there was an big ass error sending the query', error);
      });

  }
}
    
    
    
    




