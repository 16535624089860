import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { BehaviorSubject } from 'rxjs';
import { NavParams } from '@ionic/angular';
import { ActivatedRoute, Router, NavigationExtras} from '@angular/router';

import { LoadingController,ModalController } from '@ionic/angular';
import { LibraryPage } from '../library/library.page';
import { TaskPage } from '../task/task.page';
import { MapquestPage } from '../mapquest/mapquest.page';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { PricePage } from '../price/price.page';
import { ProfitPage } from '../profit/profit.page';
import { MarkupPage } from '../markup/markup.page';
import { LaborPage } from '../labor/labor.page';
import { BackcostPage } from '../backcost/backcost.page';
import { StrategyPage } from '../strategy/strategy.page';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.page.html',
  styleUrls: ['./tools.page.scss'],
})
export class ToolsPage implements OnInit {

  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  toolCategories: any[];
  toolCategoryLinks:any[];
  toolcategoryUID:any[];
  libraryUID:any;
  principleUID:any;
  dataReturned: string[];
  
  constructor(private authService: AuthService, 
    //private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private activatedroute:ActivatedRoute, 
    private loadingCtrl: LoadingController,
    private navParams: NavParams,
    
    private router: Router) {}

  ngOnInit() {

    let toolcategoryUID =  this.activatedroute.snapshot.paramMap.get('toolcategoryUID');
console.log('toolcategoryUID',toolcategoryUID)

  
  this.apollo
    .watchQuery({
      query: gql`
         query toolCategoryLinks($toolcategoryUID:ID)
      {
        toolCategoryLinks(where:{toolcategoryUID:$toolcategoryUID}){
      category
      toolcategoryUID
      category_image_path
      toolID
      toolUID
      description
      header
      body
      tool_image_path
     
    }

    }

      `,
             //variables: {uid:this.uid} , 
         variables:    {toolcategoryUID: this.toolcategoryUID},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.toolCategoryLinks = result?.data?.toolCategoryLinks;
      this.loading = result.loading;
      this.error = result.error;
    });


    this.apollo
    .watchQuery({
      query: gql`
         query toolCategories($toolcategoryUID:ID)
      {
        toolCategories(where:{toolcategoryUID:$toolcategoryUID}){
      category
      toolcategoryUID
      toolcategoryID
      image_path
      
     
    }

    }

      `,
             //variables: {uid:this.uid} , 
         variables:    {toolcategoryUID: this.toolcategoryUID},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.toolCategories = result?.data?.toolCategories;
      this.loading = result.loading;
      this.error = result.error;
    });
  }
  closeModal():void{
    this.modalController.dismiss(null, null, "tools");
}
async openModalPrice(uid) {
  let loading = await this.loadingCtrl.create({
    message: 'Loading Price Data ...',
    duration: 1000,
  });
  await loading.present();
  const modal = await this.modalController.create({
    component: PricePage,
    cssClass: 'my-modal-class',
    id:"price",
    componentProps: {
      
      uid:this.uid,
   
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });
  

  return await modal.present();
}

async openModalProfit(uid) {
let loading = await this.loadingCtrl.create({
  message: 'Loading Profit Data ...',
  duration: 1000,
});
await loading.present();
const modal = await this.modalController.create({
  component: ProfitPage,
  cssClass: 'my-modal-class',
  id:"profit",
  componentProps: {
    
    uid:this.uid,
 
  }
});

modal.onDidDismiss().then((dataReturned) => {
  if (dataReturned !== null) {
    this.dataReturned = dataReturned.data;
    //alert('Modal Sent Data :'+ dataReturned);
  }
});


return await modal.present();
}

async openModalMarkup(uid) {
let loading = await this.loadingCtrl.create({
  message: 'Loading Markup Data ...',
  duration: 1000,
});
await loading.present();
const modal = await this.modalController.create({
  component: MarkupPage,
  cssClass: 'my-modal-class',
  id:"margin",
  componentProps: {
    
    uid:this.uid,
 
  }
});

modal.onDidDismiss().then((dataReturned) => {
  if (dataReturned !== null) {
    this.dataReturned = dataReturned.data;
    //alert('Modal Sent Data :'+ dataReturned);
  }
});


return await modal.present();
}

async openModalLabor(uid) {
let loading = await this.loadingCtrl.create({
  message: 'Loading Labor Data ...',
  duration: 1000,
});
await loading.present();
const modal = await this.modalController.create({
  component: LaborPage,
  cssClass: 'my-modal-class',
  id:"labor",
  componentProps: {
    
    uid:this.uid,
 
  }
});

modal.onDidDismiss().then((dataReturned) => {
  if (dataReturned !== null) {
    this.dataReturned = dataReturned.data;
    //alert('Modal Sent Data :'+ dataReturned);
  }
});


return await modal.present();
}

async openModalBackCost(uid) {
let loading = await this.loadingCtrl.create({
  message: 'Loading Back Cost Data ...',
  duration: 1000,
});
await loading.present();
const modal = await this.modalController.create({
  component: BackcostPage,
  cssClass: 'my-modal-class',
  id:"labor",
  componentProps: {
    
    uid:this.uid,
 
  }
});

modal.onDidDismiss().then((dataReturned) => {
  if (dataReturned !== null) {
    this.dataReturned = dataReturned.data;
    //alert('Modal Sent Data :'+ dataReturned);
  }
});


return await modal.present();
}

async openModalStrategy(uid) {
let loading = await this.loadingCtrl.create({
  message: 'Loading Strategy Data ...',
  duration: 1000,
});
await loading.present();
const modal = await this.modalController.create({
  component: StrategyPage,
  cssClass: 'my-modal-class',
  id:"strategy",
  componentProps: {
    
    uid:this.uid,
 
  }
});

modal.onDidDismiss().then((dataReturned) => {
  if (dataReturned !== null) {
    this.dataReturned = dataReturned.data;
    //alert('Modal Sent Data :'+ dataReturned);
  }
});


return await modal.present();
}
}
