import { Component, OnInit } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular'
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//import { LocationPage } from '../location/location.page';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';

import { MylocationPage } from '../mylocation/mylocation.page';

//used for poll & refresh
import { Subscription } from 'rxjs';
import {latLngToCell} from "h3-js";
import {cellToBoundary} from "h3-js";

const GET_TODAYS = gql`
  query GetToday {
    todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
`;

const GET_HOME_ADDRESS = gql`
  query GetHomeAddress($uid:ID) {
    homeAddresses(where:{uid:$uid}) {
      uid
      homeAddressID
      street_number
      street
      city
      state
      zip
      address_name
      lat
      lon
      latitude
      longitude
    }
  }
`


@Component({
  selector: 'app-address-home',
  templateUrl: './address-home.page.html',
  styleUrls: ['./address-home.page.scss'],
})
export class AddressHomePage implements OnInit {
  //added for pollig & Refresh
  homeAdressQuery: QueryRef<any>;

  private querySubscription: Subscription
  ionicForm: FormGroup;
  //uid:string;
  uid = this.navParams.get('uid');
  addresses: string[];
  homeAddresses: string[];
  Homes: any[];
  homes: any[];
  homeID: string;
  email:string;
  loading = true;
  error: any;
  isSubmitted = false;
  data:any;
  dataReturned: any[];
  
  //info Form
  
  homeAddressID: string;
  addressForm: FormGroup;
  address_name: string;
  street_number: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  //latitude = this.navParams.get('latitude');
  latitude: number;
  longitude :number;
  h30:string
  h31:string;
  h32:string;
  h33:string;
  h34:string;
  h35:string;
  h36:string;
  h37:any;
  h38:any;
  h39:any;
  h310:any;
  h311:any;
  h312:any;
  h313:any;
  
  todays:any;
  today:any;
  dayID:any;
  weekID:any;
  day:any;
  week:any;


  h39boundary:any[];

  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController, 
    private alertCtrl: AlertController,
    
    private router: Router) {}

  ngOnInit() {
    let uid = this.uid
    let latitude = this.navParams.get('latitude');
    let longitude = this.navParams.get('longitude');
    let h39 =latLngToCell(latitude,longitude, 9);
    //let h39boundary = this.navParams.get('h39boundary');
    let h39boundary = cellToBoundary(h39,true);
    

    
    let zip = this.navParams.get('zip');
    
    


    console.log('latitude',latitude);
    console.log('longitude',longitude);
    
    console.log('h39boundary',h39boundary)
    


   
    this.h39boundary = h39boundary;

    this.dayID=this.dayID;
    this.weekID=this.weekID
    
    
    

    this.apollo
    .watchQuery({
      query: gql`
        query homes
    {
    homes{
    homeID
    uid
      homeAddress{
        
        homeAddressID
        street_number
        street
        city
        state
        zip
        
        
        
        
        
        
      }
      homeZip{
        zip
        town
        zipTerritory{
          territory
        }
      }
      
    
    }
    }
    
    
      `,
             variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });
      console.log('uid',this.uid);

      
    
  }


  
  async openModal(homeID,homeAddressID,latitude,longitude,h39boundary,dayID,weekID) {
    let loading = await this.loadingCtrl.create({
      message: 'Loading Map Data ...',
      duration: 1000,
    });
    await loading.present();
    const modal = await this.modalController.create({
      component: MylocationPage,
      cssClass: 'my-modal-class',

      id:"LocationPage",
      componentProps: {
        
        homeID:homeID,
        homeAddressID:homeAddressID,
        latitude:latitude,
        longitude:longitude,
        h39boundary:h39boundary,
        dayID:dayID,
        weekID:weekID,
       
       
     
      }
    });
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
     return await modal.present();
    
}

ngOnDestroy() {
  this.querySubscription.unsubscribe()

    this.ionicForm = this.fb.group({
     
      zip: ['', [Validators.required, Validators.minLength(5)]],
   
      role: ['BUYER', Validators.required]
    });
      }
  closeModal():void{
    this.modalController.dismiss();

    console.log('zip',this.zip);
    console.log('uid',this.uid)

    

  }
  
  
  async openModalBanner() {
    const modal = await this.modalController.create({
      component: MylocationPage,
      id:"banner",
      componentProps: {
        
        uid:this.uid,
       
        
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
  
    return await modal.present();
  }

  ionViewDidEnter(){

    this.apollo
    .watchQuery({
      query: gql`
         query todays
 {
 todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
 
      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays);
    

    this.homeAdressQuery = this.apollo.watchQuery<any>({
      query: GET_HOME_ADDRESS,
      variables: {
        //uid : this.navParams.get('uid'),
        uid: this.afAuth.auth.currentUser.uid
      },
      pollInterval: 900,
    });
    this.querySubscription =this.homeAdressQuery
    .valueChanges
    .subscribe(({ data, loading }) => {
      this.loading = loading
      this.homeAddresses = data.homeAddresses;
    });


    this.apollo
    .watchQuery({
      query: gql`
         query homes($uid:ID)
  {
  homes(where:{uid:$uid}){
  uid
  homeID
  username
  password
  email
  
  homeInfo{
    name_first
    name_last
  }
  
  location{
    latitude
    longitude
  }
  }
  }
  
  
      `,
             //variables: {uid:this.uid} 
         variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });
  
   
  }
  refresh(){
    this.homeAdressQuery.refetch()
  }
  }