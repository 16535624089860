import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NavParams } from '@ionic/angular';

import { LoadingController,ModalController } from '@ionic/angular';
import { Jobs1Page } from '../jobs1/jobs1.page';
import { TaskPage } from '../task/task.page';
import { MapquestPage } from '../mapquest/mapquest.page';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-library',
  templateUrl: './library.page.html',
  styleUrls: ['./library.page.scss'],
})
export class LibraryPage implements OnInit {
  
  loading = true;
  error: any;
  libraries: any[];
  principleUID:any;
  dataReturned: string[];
  libraryUID:any;

  constructor(private authService: AuthService, 
    //private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

  ngOnInit() {
    let libraryUID = this.navParams.get('libraryUID');
console.log('libraryUID',libraryUID)

    this.apollo
    .watchQuery({
      query: gql`
        query libraries($libraryUID:ID)
{
  libraries(where:{libraryUID:$libraryUID}){
    libraryUID
    title
    author
    header
    body
    libraryID
    image_path
    
  }
}

 
      `,
             //variables: {uid:this.uid} , 
                 //variables: {supplierUID: this.uid} ,
                // variables:    {supplierID: this.afAuth.auth.currentUser.uid},
                 variables:    {libraryUID: this.libraryUID},


         
    })
    .valueChanges.subscribe((result: any) => {
      this.libraries = result?.data?.libraries;
      this.loading = result.loading;
      this.error = result.error;
    });
    
  }
  closeModal():void{
    this.modalController.dismiss(null, null, "library");
}
  }


