import { Injectable } from '@angular/core';
//newly added
import {Apollo, gql, QueryRef} from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
 

const LOCATIONS = gql`
  query Locations {
      locations{
        coord
        h39
        h37
        
      }
  }
  `;


const books =[
  {
    title:'Books about me',
    //array of books on the shelf
    shelves:  [
      {
      id:1,
      category:'Fiction',
      author:'Bob'
      },
      {
        id:2,
        category:'Drama',
        author:'Art'
        },
    ]
  }
];
const displayCategories =
books.map((book)=> book.shelves.map((shelf)=> shelf.category))
console.log('displayCategories',displayCategories)

const displayAuthors =
books.map((book)=> book.shelves.map((shelf)=> shelf.author))
console.log('displayAuthors',displayAuthors)


const sam =
books.map((apple)=>apple.shelves.map((shelf)=>shelf.id))
console.log('sam',sam)




@Injectable({
  providedIn: 'root'
})
export class LocationService {
  LOCATIONS:any[];
  locations:any[];
  constructor(private apollo: Apollo ) {}


  
  

   getLocations(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query:LOCATIONS,
      
        
   }).valueChanges;
   

   }
  
}





   
    
    
   
  
  

