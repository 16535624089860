import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ActivatedRoute} from '@angular/router'
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { JobsPage } from '../jobs/jobs.page';

const updateBases = gql`
mutation updateBases(
 					 	$supplierUID:ID,
  					$territoryID:ID   
		){
    		updateBases(
        where: { supplierUID:$supplierUID }
        connect: { territory: { where: {node:{territoryID:$territoryID}
        }
         
        } 
          
        } 
    ) {
      bases {
        baseID
        supplierUID
        			territory{
                territoryID
                territory
                
              }
            
        }
    }
}
    


`;

const updateBaseBusinessType = gql`
mutation updateBaseBusinessType(
 					 	$supplierUID:ID,
  					$businessTypeID:ID   
		){
      updateBases(
        where: { supplierUID:$supplierUID }
        connect: { business_type: { where: {node:{businessTypeID:$businessTypeID}
        }
         
        } 
          
        } 
    ) {
      bases {
              baseID
              supplierUID
        			business_type{
                businessTypeID
                business_type
                
              }
            basePlan{
              territory
              business_type
            }
        }
    }
}
    


`;




@Component({
  selector: 'app-base-territory',
  templateUrl: './base-territory.page.html',
  styleUrls: ['./base-territory.page.scss'],
})
export class BaseTerritoryPage implements OnInit {
  suppliers: any[];
  bases: any[];
  plans: any[];
  Suppliers: any[];
  territories: any[];
  Territories: any[];
  businesstypes: any[];
  Businesstypes: any[];
  businessTypes:any[];
  zip: any[];
  territory: any[];
  businessTypeID: any[];
  loading = true;
  dataReturned: string[];
  error: any;
  segmentModel = "territory";
  segment = 'territory';

  uid = this.authService.getUid(); 
 private selectedSegment: String = 'territory'

  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

    ngOnInit() {
      let uid=this.uid;
      console.log('uid',uid);
      //let territoryID = this.activatedroute.snapshot.paramMap.get('territoryID');
      let territoryID = this.navParams.get('territoryID');
      console.log('territoryID',territoryID);
      
      let baseID = this.navParams.get('baseID');
      console.log('baseID',baseID);
      let planID = this.navParams.get('planID');
      console.log('planID',planID);
      let supplierUID = uid;
      console.log('supplierUID',supplierUID);

      this.apollo
      .watchQuery({
        query: gql`
          query businessTypes {
            businessTypes(options:{sort:{business_type:ASC}}){
      business_type
      businessTypeID
      image_path
    
    
    
  }
  }
        `, 
          // variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.businessTypes = result?.data?.businessTypes;
        this.loading = result.loading;
        this.error = result.error;
        console.log('businessTypes',this.businessTypes);

      });
      this.apollo
        .watchQuery({
          query: gql`
             query Supplier($uid:ID) {
            suppliers(where:{uid:$uid}) 
          {
            supplierID
            uid
            email
            password
            username
            count_subscription_plan
            count_territory
            count_business_type
            subscriptionTerritory{
                territoryID
                territory
              }
              subscriptionBusinessType{
                businessTypeID
                business_type
              }
      
      
    }
  }
          `, 
             variables:    {uid: this.afAuth.auth.currentUser.uid},
             
        })
        .valueChanges.subscribe((result: any) => {
          this.suppliers = result?.data?.suppliers;
          this.loading = result.loading;
          this.error = result.error;
        });
  
        this.apollo
        .watchQuery({
          query: gql`
             query Territories($territoryID:ID) {
            territories(where:{territoryID:$territoryID}) 
          {
           territoryID
           territory
           territory_number
           count_business_type
           count_zip
           county
           state
            business_type{
              businessTypeID
              business_type
              image_path
            }
            classification{
              classificationID
                business_type{
                  business_type
                  image_path
                }
            }
           zip(options:{sort:{town:ASC}}){
             town
             zip
           }
           
    }
  }
          `,
            //variables: {territoryID:this.activatedroute.snapshot.paramMap.get('territoryID')} ,
            variables: {territoryID:territoryID} ,
             
        })
        .valueChanges.subscribe((result: any) => {
          this.territories = result?.data?.territories;
          this.loading = result.loading;
          this.error = result.error;
        });


        this.apollo
        .watchQuery({
          query: gql`
             query bases($supplierUID:ID) {
           bases(where:{supplierUID:$supplierUID}) 
          {
                baseID
                supplierUID
                    baseSubscription{
                    subscriptionID
                   }
        plan{
          planID
          description
          territory
          business_type
        
        }
      basePlan{
        territory
        business_type
      }
      
    }
  }
          `, 
            // variables:    {uid: this.afAuth.auth.currentUser.uid},
           // variables:    {baseID:baseID},
           variables:    {supplierUID: supplierUID},
             
        })
        .valueChanges.subscribe((result: any) => {
          this.bases = result?.data?.bases;
          this.loading = result.loading;
          this.error = result.error;
        });


        this.apollo
        .watchQuery({
          query: gql`
             query plans($planID:ID) {
           plans(where:{planID:$planID}) 
          {
                planID
                plan_number
                description
                territory
                business_type
                
       
      
      
    }
  }
          `, 
            // variables:    {uid: this.afAuth.auth.currentUser.uid},
            variables:    {planID:planID},
             
        })
        .valueChanges.subscribe((result: any) => {
          this.plans = result?.data?.plans;
          this.loading = result.loading;
          this.error = result.error;
        });
        
    }
    segmentChanged(event:any){
      console.log(event.target.value);
      this.selectedSegment= event.target.value
    }
    test(){
      this.segmentModel = "0";
      console.log('0')
    }
    closeModalbaseTerritory():void{
      this.modalController.dismiss(null, null, "baseTerritory");
    }
    showToastTerritory() {
      this.toastController.create({
        header: 'Congratulation',
        message: 'You Have Now Created Your New Territory, Please Select Territories',
        position: 'middle',
        cssClass: 'my-custom-class',
        color: 'home',
        duration: 3000
        
      }).then((obj) => {
        obj.present();
        
        
      });
      this.segmentModel = "business";
      console.log('0')
      
      
    }
    updateBases(supplierUID,territoryID ) {
      console.log("territories",territoryID);
      
      console.log("supplierUID",supplierUID);
  
    
      this.apollo.mutate({
        mutation:  updateBases,
        variables: {
          
         
          territoryID:territoryID,
          supplierUID:this.uid,
         
               
              },
        
        
      }).subscribe(({ data }) => {
        //console.log('id: ', id);
       console.log('territory: ', this.territories);
      
        console.log('data',data);
      
      }, (error) => {
        console.log('there was an big ass error sending the query', error);
      });
}


updateBusinesses( businessTypeID,supplierUID ) {
  console.log("businessTypeID",businessTypeID);
  
  console.log("supplierUID",supplierUID);


  this.apollo.mutate({
    mutation:  updateBaseBusinessType,
    variables: {
      
     
      businessTypeID:businessTypeID,
      supplierUID:this.uid,
     
           
          },
    
    
  }).subscribe(({ data }) => {
    //console.log('id: ', id);
   console.log('btID: ', this.businessTypeID);
  
    console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });
    this.modalController.dismiss(null, null, "baseTerritory");
    this.modalController.dismiss(null, null, "base1");
    this.modalController.dismiss(null, null, "setup");
    //this.openModalJobs(this.uid);
    this.router.navigateByUrl('/buyer/landing-app-pro');
     
}
async openModalJobs(uid) {
  const modal = await this.modalController.create({
    component: JobsPage,
    id:"jobs",
    componentProps: {
      
      uid:this.uid,
      
      //zipID:zipID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
test1(businessTypeID){
console.log('bt',businessTypeID,this.uid)
}
}
