import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router} from '@angular/router';
import { FormGroup, FormBuilder,FormControl, Validators,ReactiveFormsModule } from '@angular/forms';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';

import { AddressHomePage } from '../address-home/address-home.page';
//import { NavParams } from '@ionic/angular';


const GET_ADDRESS = gql`
  query {
    homeAddress{
  homeAddressID
  street_number
  street
  city
  state
  zip
  latitude
  longitude
  lat
  lon
  placekey
}
  }
`;




const updateHomes = gql`
  mutation updateHomes(
 					 	$uid:ID,
  					$dayID:ID,
            $weekID:ID,
		){
    		updateHomes(
        where: { uid:$uid }
        update:{dayID:$dayID,weekID:$weekID}
        connect: { days: { where: {node:{dayID:$dayID}}}, weeks:{where:{node:{weekID:$weekID}}}}
        
         
        
          
        
    
    ) {
      homes {
        uid
        			days{
                dayID
               
                
              }
            weeks{
              weekID
            }
        }
    }
}

        

  
`;

const createHomeAddresses = gql`
 mutation createHomeAddressses($address_name:String,
  														$street_number:String,
  														$street: String,
  														$city: String,
  														$state: String,
  														$zip: String,
  														$uid: ID,
                              $placekey: String,
                              
                             
  										
){
  createHomeAddresses(input:
    {
      address_name: $address_name
      street_number:$street_number
      street: $street
      city: $city
      state: $state
      zip: $zip
      uid:$uid
      placekey:$placekey
      
      
     
    	home:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      zips:{connect:{
        where: {
          node:{zip:$zip}
        }
        
      }}
     
    }
    
  ) 
  
  {
   homeAddresses{
    homeAddressID
    	uid
      address_name
    	street_number
    	street
    	city
    	state
    	zip
      lat
      lon
      placekey
      home{
        uid
      }
      	
        }
    
  }
 
    }
  
`;



@Component({
  selector: 'app-setup-home-address',
  templateUrl: './setup-home-address.page.html',
  styleUrls: ['./setup-home-address.page.scss'],
})
export class SetupHomeAddressPage implements OnInit {

  ionicForm: FormGroup;
  addressForm: FormGroup;
  defaultDate = "1987-06-30";
  isSubmitted = false;
  homes: any[];
  Homes: any[];
  categories: any[];

  homeID: any;
  loading = true;
  error: any;
  //uid = this.navParams.get('uid');

  uid = this.authService.getUid(); 
  //uid:any;
  zip: any= "";
  
  zips: any[];
  nameForm: FormGroup;
  name_first: string;
  name_last: string;
  homeAddressID: string;
  //addressForm: FormGroup;
  address_name: string;
  street_number: string;
  street: string;
  city: string;
  state: string;
  //zip: string;
  latitude :number;
  longitude :number;
  lat :number;
  lon :number;
  dataReturned: any[];
  dayID:any;
  weekID:any;
  day:any;
  todays:any;
  


 
  
  
  constructor( private modalController: ModalController,
               private fb: FormBuilder, 
               private afAuth: AngularFireAuth,
               private authService: AuthService, 
               private navService: NavService,
               //private navParams: NavParams,
               private activatedRoute: ActivatedRoute,
               private loadingCtrl: LoadingController,
               private toastCtrl: ToastController, 
               private alertCtrl: AlertController,
               private apollo: Apollo, 
               private router: Router) {}

  ngOnInit() {
   // this.dayID = this.activatedRoute.snapshot.paramMap.get('dayID');
    //this.weekID = this.activatedRoute.snapshot.paramMap.get('weekID');
    this.dayID=this.dayID;
    this.weekID=this.weekID;
    let uid = this.uid;
  
   console.log('uid',uid);
   console.log('dayID',this.dayID);
   console.log('weekID',this.weekID);


   


    this.ionicForm = this.fb.group({
     
      zip: ['', [Validators.required, Validators.minLength(5)]],
   
      role: ['BUYER', Validators.required]
    });

    this.addressForm = this.fb.group({
      uid:               [''],
      address_name:      [''],
      street_number:     ['', [Validators.required]],
      street:            ['', [Validators.required]],
      city:              ['', [Validators.required]],
      state:             ['', [Validators.required]],
      zip:               ['', [Validators.required]],
      placekey:          [''],
      
     
     });
    
   
  }
  getDate(e) {
    let date = new Date(e.target.value).toISOString().substring(0, 10);
    this.ionicForm.get('dob').setValue(date, {
      onlyself: true
    })
  }
  get errorControl() {
    return this.ionicForm.controls;
  }
  submitForm(zip) {
    this.isSubmitted = true;
    if (!this.ionicForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {

      
      console.log(this.ionicForm.value)
    }
   
 
    this.apollo
    .watchQuery({
      query: gql`
        query homes
    {
    homes{
    homeID
    uid
    dayID
    weekID
      homeAddress{
        
        homeAddressID
        street_number
        street
        city
        state
        zip
        
        
        
        
        
        
      }
      homeZip{
        zip
        town
        zipTerritory{
          territory
        }
      }
      
    
    }
    }
    
    
      `,
             variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });
      console.log('uid',this.uid)
  }
  closeModal():void{
    this.modalController.dismiss();

    console.log('zip',this.zip);
    console.log('uid',this.uid)

    

  }
      closeModalSetup():void{
        //this.modalController.dismiss();
        this.modalController.dismiss(null, null, "set");
      
  }
  signOut() {
    this.authService.signOut();
  }

  async openModalAddress(uid,zip,latitude) {
    let loading = await this.loadingCtrl.create({
      message: 'Loading Geospatial Data ...',
      duration: 7000,
    });
    await loading.present();
    const modal = await this.modalController.create({
      component: AddressHomePage,
      id:"AddressHomePage",
      componentProps: {
        uid:uid,
        zip:this.zip,
        latitude:this.latitude,
        //this.longitude:this.longitude
     
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
  
    return await modal.present();
}
  

  
  async updateHomes(uid,dayID,weekID) {
    this.isSubmitted = true;
    if (!this.addressForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {

      
      console.log(this.addressForm.value)
    }

    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation:  updateHomes,
      
      variables: {
      uid: this.afAuth.auth.currentUser.uid,
      //zip:this.addressForm.value.zip,
      dayID:dayID,
      weekID:weekID
      
      
    },
      
    }).subscribe(({ data }) => {
     // console.log('sendMessage: ', uuid);
     console.log('dayID',dayID);
       console.log(data);
       //this.router.navigateByUrl('seller/entry');
  console.log("Form Submitted!!!!");
  //this.router.navigateByUrl('/seller/entry');
  //this.navCtrl.navigateForward('/setup'); 
  // this.router.navigate(['/app/setup/',this.id ]);
  //this.router.navigateByUrl('/seller/entry');
  
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      //this.router.navigate(['/seller/entry' ]); 
      //this.navCtrl.navigateRoot('/setup');
    });
  }
    
    
  
  gotoSellerEntry() {
     this.navService.gotoSellerEntry();
   
   this.apollo
      .watchQuery({

        // THIS IS THE QUERY...
        query: gql`
        query zips {
          zips 
                         {
          zip
          town
          }
        }



        `,
        //variables: {uid:this.uid} ,
        
      })

      
      // WHEN THE VALUE CHANGES, WE GET A STREAM FROM THE SUBSCRIPTION
      .valueChanges.subscribe((result: any) => {
        this.zips = result?.data?.zips;
        this.loading = result.loading;
        this.error = result.error;
      });

    }
      async createHomeAddresses() {
        this.isSubmitted = true;
        if (!this.addressForm.valid) {
          console.log('Please provide all the required values!')
          return false;
        } else {
    
          
          console.log(this.addressForm.value)
        }
  
  this.apollo.mutate({
    mutation: createHomeAddresses,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    address_name:this.addressForm.value.address_name,
    street_number:this.addressForm.value.street_number,
    street:this.addressForm.value.street,
    city:this.addressForm.value.city,
    state:this.addressForm.value.state,
    zip:this.addressForm.value.zip,
    
   
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
    
     
     
     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);

    refetchQueries: [{
      query: GET_ADDRESS
    }]
  
    
    //this.navCtrl.navigateRoot('/setup');
  });

this.apollo
  .watchQuery({
    query: gql`
       query homes($uid:ID)
{
homes(where:{uid:$uid}){
uid
homeID
username
password
email
dayID
weekID
homeInfo{
  name_first
  name_last
}
homeAddress{
  homeAddressID
  street_number
  street
  city
  state
  zip
  latitude
  longitude
  placekey
}
location{
  latitude
  longitude
}
}
}


    `,
           //variables: {uid:this.uid} 
       variables:    {uid: this.afAuth.auth.currentUser.uid},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.homes = result?.data?.homes;
    this.loading = result.loading;
    this.error = result.error;
  });
}
ionViewDidEnter(){

  

  this.apollo
  .watchQuery({
    query: gql`
       query todays
      {
      todays{
      todayID
      month{
      monthID
      month
      }

      day{
          day
          dayID
          date
          weekNumber
          month
          year
      }
        week{
          weekID
          week
          banner_placement{
            bannerUID
            bannerName
            
          }
          promos_placement{
            promoUID
            promotionName
          }
         
}
}
}

    `,
           //variables: {uid:this.uid} , 
      // variables:    {uid: this.afAuth.auth.currentUser.uid},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.todays = result?.data?.todays;
    this.loading = result.loading;
    this.error = result.error;
  });
  console.log('todays',this.todays);





  
  this.apollo
  .watchQuery({
    query: gql`
        query homes($uid:ID)
{
homes(where:{uid:$uid}){
uid
homeID
username
password
email
dayID
weekID
homeInfo{
  name_first
  name_last
}
homeAddress{
  homeAddressID
  street_number
  street
  city
  state
  zip
  latitude
  longitude
  placekey
}
location{
  latitude
  longitude
}
}
}


    `,
           //variables: {uid:this.uid} 
       variables:    {uid: this.afAuth.auth.currentUser.uid},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.homes = result?.data?.homes;
    this.loading = result.loading;
    this.error = result.error;
  });
}

 
}
  


