import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProInvitePageRoutingModule } from './pro-invite-routing.module';

import { ProInvitePage } from './pro-invite.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ProInvitePageRoutingModule
  ],
  declarations: [ProInvitePage]
})
export class ProInvitePageModule {}
