import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AcceptDetailPage } from '../accept-detail/accept-detail.page';
//import { NavParams } from '@ionic/angular';
import { NavService } from '../../services/nav.service';




@Component({
  selector: 'app-accept',
  templateUrl: './accept.page.html',
  styleUrls: ['./accept.page.scss'],
})
export class AcceptPage implements OnInit {

  uid = this.authService.getUid(); 

  loading = true;
  error: any;
  dataReturned: string[];
  suppliers: any[];
  Suppliers: any[];
  accepts:any[];
  inviteID:any[];
  cart:any;
  task:any;
  supplierID:any;
  taskID:any;
  homeID:any;
  categoryID:any;
  subcategoryID:any;
  territoryID:any;
  invite:any;
  connectID:any;
  h310:any;

  constructor(private modalController: ModalController,
              private apollo: Apollo,
              private authService: AuthService,
              private afAuth: AngularFireAuth, 
              //private navParams: NavParams,
              private navService: NavService,
    ) { }

  ngOnInit() {
   
    this.apollo
    .watchQuery({
      query: gql`
        query Supplier($uid:ID) {
  suppliers(where:{uid:$uid}){
    supplierID
    email
    userInvites{
      task
      inviteID
      
      	inviteAccept{
          date_create
          acceptID
          acceptTask{
            task_description
            item_tag
            taskItem{
              item
            }
          }
          acceptHome{
            email
           
              homeInfo{
                name_first
                name_last
              }
              
              homeAddress{
                street_number
                street
                city
                state
                zip
                    
              }
              location{
                        h310
                }
          }
        }
    }
    
    
    
  }
}


`,
    variables:    {uid: this.afAuth.auth.currentUser.uid},

})
    .valueChanges.subscribe((result: any) => {
      this.suppliers = result?.data?.suppliers;
      this.loading = result.loading;
      this.error = result.error;
    });

    this.apollo
    .watchQuery({
      query: gql`
       query Accepts($supplierUID:ID) {
       			accepts(where:{supplierUID:$supplierUID},options:{sort:{date_create:DESC}}){
           			acceptID
    						item_tag
    						supplierUID
    						homeID
    						task
    
    						inviteID
    						date_create
    						acceptHome{
                  homeID
                  email
                  latitude
                  longitude
                  homeInfo{
             name_first
             name_last
           }
                  homeAddress{
                    street_number
                    street
                    city
                    state
                    zip  
                  }
                  location{
                      h310
                }
                }
    						acceptSupplier{
                  supplierID
                }
    							
           
    }
                
          
   }


`,
    variables:    {supplierUID: this.afAuth.auth.currentUser.uid},

})
    .valueChanges.subscribe((result: any) => {
      this.accepts = result?.data?.accepts;
      this.loading = result.loading;
      this.error = result.error;
    });
}


closeModal():void{
  this.modalController.dismiss();
  this.modalController.dismiss(null, null, "accepts");


}
async openModal(acceptID,h310,latitude,longitude,h313) {
  const modal = await this.modalController.create({
    component: AcceptDetailPage,
    componentProps: {
      
      uid:this.uid,
      acceptID:acceptID,
      h310:h310,
      h313:h313,
      latitude:latitude,
      longitude:longitude,
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}



}