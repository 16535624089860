import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
const updateSupplierInfos = gql`
 
mutation updateSupplierInfos($supplierinfoID:ID, 
  													
														$company:String,
  													$url: String,
  													$founded: Int,
  													$employee: Int,
  													$tagline: String,
  													$base_location: String,
  													$bio: String,
  													$insurance: Boolean,
  													$license: Boolean
  
														) {
 
    updateSupplierInfos(where: {supplierinfoID: $supplierinfoID }
    														
   													update:{
															company:$company,
      													url:$url,
      													founded:$founded,
      													employee:$employee,
      													tagline:$tagline,
      													base_location: $base_location,
      													bio: $bio,
      													insurance:$insurance,
      													license:$license			
                            }
       
    ){
        supplierInfos{
         
          supplierID
         company
          url
          founded
          employee
          tagline
          base_location
          bio
         
          
        }
    
  }
}

  
`;

const deleteSupplierInfos = gql`
 mutation deleteSupplierInfos($supplierinfoID:ID){
  deleteSupplierInfos (where:{supplierinfoID:$supplierinfoID}){
    nodesDeleted
   
  }
}

  
`;




@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.page.html',
  styleUrls: ['./info-modal.page.scss'],
})
export class InfoModalPage implements OnInit {
    uid = this.authService.getUid(); 
    suppliers: string[];
    infos:string[];
    loading = true;
    error: any;
    isSubmitted = false;
    data:any;

    //info Form
   infoForm: FormGroup;
   company: string;
   founded: number;
   employee: number;
   tagline: string;
   base_location: string;
   bio: string;
   url: string;
   insurance: boolean;
   license: boolean;
   supplierinfoID:string;

  

  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

  ngOnInit() {
    let supplierinfoID = this.navParams.get('supplierinfoID');
    let company = this.navParams.get('company');
    
    console.log('supplierinfoID', supplierinfoID);
    console.log('company', company)

    this.infoForm = this.fb.group({
      company:      [this.company],
      founded:      [this.founded],
      employee:     [this.employee],
      tagline:      [this.tagline],
      base_location:[this.base_location],
      bio:          [this.bio],
      url:          [this.url],
      insurance:    [this.insurance],
      license:      [this.license],
     });


    this.apollo
    .watchQuery({
      query: gql`
         query suppliers($uid:ID)
        {
          suppliers(where:{uid:$uid}){
          uid
          supplierID
          email
            
          
            
            supplierSupplierInfo{
              company
              founded
              employee
              tagline
              base_location
              bio
              url
              insurance
              license
            }
            
            
  
}
}
  

      `,
             
         variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.suppliers = result?.data?.suppliers;
      this.loading = result.loading;
      this.error = result.error;
    });

      //Main Search Query Suppliers
  this.apollo
  .watchQuery({
    query: gql`
          query supplierInfos($supplierinfoID:ID)
        {
          supplierInfos(where:{supplierinfoID:$supplierinfoID}){
          uid
         company
          url
          founded
          employee
          tagline
          bio
          base_location
          insurance
          license
            
            suppliers{
              supplierID
              uid
            }
               
  
}
}


    `,
           
       variables:    {supplierinfoID: supplierinfoID},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.infos = result?.data?.infos;
    this.loading = result.loading;
    this.error = result.error;
  });
  console.log('uid',this.uid);
 

  }
closeModal():void{
    this.modalController.dismiss();
}
insuranceChange() {
  this.insurance = !this.insurance;
  console.log(this.insurance);
}
licenseChange() {
  this.license = !this.license;
  console.log(this.license);
}
  async updateSupplierInfos() {
    this.isSubmitted = true;
    if (!this.infoForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {
  
      
      console.log(this.infoForm.value)
    }
  
    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation: updateSupplierInfos,
      
      variables: {
      uid: this.afAuth.auth.currentUser.uid,
      company:this.infoForm.value.company,
      founded:this.infoForm.value.founded,
      employee:this.infoForm.value.employee,
      tagline:this.infoForm.value.tagline,
      base_location:this.infoForm.value.base_location,
      bio:this.infoForm.value.bio,
      url:this.infoForm.value.url,
      //insurance:this.infoForm.value.insurance,
      insurance:this.insurance,
      //license:this.infoForm.value.license,
      license:this.license,
      supplierID:this.uid
     
      
      
       
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);    
      console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    });
}
async deleteSupplierInfos() {
  
  this.apollo.mutate({
    mutation: deleteSupplierInfos,
    
    variables: {
    
    supplierinfoID:this.supplierinfoID
   
    
    
     
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);    
    console.log("Deleted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
}
}