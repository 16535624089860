import { Component, OnInit } from '@angular/core';
import {Apollo,QueryRef, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//import { Subscription } from 'rxjs';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { BaseTerritoryPage } from '../base-territory/base-territory.page';

const getBases = gql`
         query bases($supplierUID:ID) {
         bases(where:{supplierUID:$supplierUID}) 
        {
              baseID
              uid
              supplierUID
      baseSubscription{
        subscriptionID
      }
      basePlan{
        planID
        description
        territory
        business_type
      
      }
    
    
  }
}
`;

// types for Bases Response
interface Bases {
baseID: string;
id: string;
supplierUID:string;
   
  }
  
interface GetMyBasesResponse {
bases: Bases[];
   }


@Component({
  selector: 'app-base',
  templateUrl: './base.page.html',
  styleUrls: ['./base.page.scss'],
})
export class BasePage implements OnInit {
  uid = this.authService.getUid(); 
  supplierUID = this.uid; 
  segmentModel = "base";
  segment = 0;
  loading = true;
  error: any;
  dataReturned: string[];

 // basesQuery: QueryRef<any>;
  
  bases: any[];

  territories: any[];
  filter = "all";
  filteredBases: any;


  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
   // private querySubscription: Subscription,
    
    private router: Router) {}


  ngOnInit() {

    let supplierUID=this.uid

    this.apollo.watchQuery<GetMyBasesResponse>({
     query: getBases
       })
      .valueChanges
      .subscribe(({ data, loading }) => {
      this.loading = loading;
      this.bases = data.bases;
      this.filteredBases = this.bases;
       });
       


  //refresh() {
    //this.basesQuery.refetch()
  
    
    this.apollo
      .watchQuery({
        query: gql`
           query bases($supplierUID:ID) {
         bases(where:{supplierUID:$supplierUID}) 
        {
              baseID
              uid
              supplierUID
      baseSubscription{
        subscriptionID
      }
      basePlan{
        planID
        description
        territory
        business_type
      
      }
    
    
  }
}
        `, 
            variables:    {supplierUID: this.uid},
           // variables:    {supplierUID: this.afAuth.auth.currentUser.uid},
          //variables:    {uid:uid},
          
           
      })
      .valueChanges.subscribe((result: any) => {
        this.bases = result?.data?.bases;
        this.loading = result.loading;
        this.error = result.error;

        
      });
     

      this.apollo
      .watchQuery({
        query: gql`
           query territories {
          territories 
        {
          territoryID
          territory
          territory_number
          county
          state
   
    
  }
  }
        `,
               //variables: {uid:this.uid} , 
           //variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.territories = result?.data?.territories;
        this.loading = result.loading;
        this.error = result.error;
      });





  }
closeModal():void{
  this.modalController.dismiss(null, null, "base");
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
territory(){
  this.segmentModel = "territory";
}
business(){
  this.segmentModel = "business";
}
async openModalBaseTerritory(territoryID, territory, business, planID, baseID, supplierUID) {
  const modal = await this.modalController.create({
    component: BaseTerritoryPage,
    id:"baseTerritory",
    componentProps: {
      
      uid:this.uid,
      territoryID:territoryID,
      territory:territory,
      business:business,
      planID:planID,
      baseID:baseID,
      supplierUID:supplierUID
      
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();



  
 
}

}