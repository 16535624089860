import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CheckoutpaidPageRoutingModule } from './checkoutpaid-routing.module';

import { CheckoutpaidPage } from './checkoutpaid.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CheckoutpaidPageRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [CheckoutpaidPage]
})
export class CheckoutpaidPageModule {}
