import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { NavService } from '../../services/nav.service';

import { ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';




const now = new Date();

const CreateCheckout = gql`
  mutation CreateCheckout($now:Float, $uuid:ID, $cartID:ID, $userID:ID, $notes:String,  $timeline:String, $taskID:ID  ) {
    CreateCheckout(uuid:$uuid, now:$now, cartID:$cartID, userID:$userID, timeline:$timeline, notes:$notes, is_active: true, taskID:$taskID ) 
{
    uuid
    userID
    cartID
    timeline
    now
    is_active
    taskID  
     
    
}
 
  }
  
`;


const CreateCartitem = gql`
  mutation CreateCartitem($cartID:ID,$now:Float,$uuid:ID, $projectID:ID, $project:String, $task:String, $taskID:ID, $homeID:ID, $userID:ID, $zip:ID, $terID:ID, $subID:ID, $catID:ID, $connectID:ID, $timeline:String, $fee:Int,$amount:Int, $description:String ) {
    CreateCartitem(cartID:$cartID,now:$now, fee:$fee, connectID:$connectID, taskID:$taskID, uuid:$uuid, project:$project, task:$task, homeID:$homeID, userID:$userID, zip:$zip, terID:$terID, subID:$subID, catID:$catID, timeline:$timeline, is_active: true, projectID:$projectID , amount:$amount, description:$description ) 
{
      uuid
      projectID
      project
      projecttimeline
      task
      taskID
      homeID
      userID
      zip
      terID
      subID
      catID
      connectID
      description
      note
      timeline
      is_active
      fee             
      amount
      now
      cartID
     
    
}
 
  }
  
`;



const createConnect = gql`
  mutation CreateConnect($fee: Int, $timeline:String, $id:Int, $uuid:ID, $task:String, $homeID:ID, $userID:ID, $subID:ID, $catID:ID, $taskID:ID, $zip:ID,  $description:String, $day:Int, $month: Int, $year:Int, $terID:ID) {
   CreateConnect(fee:$fee,timeline:$timeline,id:$id, uuid:$uuid, task:$task,  homeID:$homeID, userID:$userID, subID:$subID, catID:$catID, taskID:$taskID, is_active: true, zip:$zip, description:$description, day:$day, month:$month, year:$year, terID:$terID) 
{
  uuid
  task
  description
  id
  taskID
  homeID
  userID
  subID
  catID
  terID
  timeline
  is_active 
  zip
  day
  month
  year 
  dmy
  fee
 
 
  
 
  }
 
  }
  
`;



const AddDayConnect = gql`
  mutation AddDayConnect($from:_DayInput!,$to:_ConnectInput!){
  AddDayConnect(from:$from, to:$to)	{
    from	{
     id
    }
    to	{
      uuid
    }
  }
}
  
`;


const addTaskConnect = gql`
  mutation AddTaskConnect($from:_TaskInput!,$to:_ConnectInput!){
  AddTaskConnect(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      id
      
    }
  }
}
  
`;
const addConnectTasks = gql`
  mutation AddConnectTasks($from:_ConnectInput!,$to:_TaskInput!){
  AddConnectTasks(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      uuid
      
    }
  }
}
  
`;
//newly added
const AddConnectSuppliers = gql`
  mutation AddConnectSuppliers($from:_ConnectInput!,$to:_SupplierInput!){
    AddConnectSuppliers(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      id
      
      
    }
  }
}
  
`;
const addTaskSuppliers = gql`
  mutation AddTaskSuppliers($from:_TaskInput!,$to:_SupplierInput!){
  AddTaskSuppliers(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      uid
      
      
    }
  }
}
  
`;
const addSupplierTasks = gql`
  mutation AddSupplierTasks($from:_SupplierInput!,$to:_TaskInput!){
  AddSupplierTasks(from:$from, to:$to)	{
    from	{
      id
    }
    to		{
    
      uuid
      
      
    }
  }
}
  
`;

const AddInviteUser = gql`
  mutation AddInviteUser($from:_InviteInput!,$to:_UserInput!){
  AddInviteUser(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      id
      
      
    }
  }
}
  
`;

const AddUserInvite = gql`
  mutation AddUserInvite($from:_UserInput!,$to:_InviteInput!){
    AddUserInvite(from:$from, to:$to)	{
    from	{
      id
    }
    to		{
    
      uuid
      
      
    }
  }
}
  
`;

const AddCartCartitems = gql`
  mutation AddCartCartitems($from:_CartInput!,$to:_CartitemInput!){
    AddCartCartitems(from:$from, to:$to)	{
    from	{
      now
    }
    to		{
    
      now
      
      
    }
  }
}
  
`;





const AddConnectZips = gql`
  mutation AddConnectZips($from:_ConnectInput!,$to:_ZipInput!){
  AddConnectZips(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      id
      
      
    }
  }
}
  
`;


const AddConnectCategory = gql`
  mutation AddConnectCategory($from:_ConnectInput!,$to:_CategoryInput!){
  AddConnectCategory(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      id
      
      
    }
  }
}
  
`;

const AddConnectSubcategory = gql`
  mutation AddConnectSubcategory($from:_ConnectInput!,$to:_SubcategoryInput!){
  AddConnectSubcategory(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      id
      
      
    }
  }
}
  
`;


const AddSupplierConnect = gql`
  mutation AddSupplierConnect($from:_SupplierInput!,$to:_ConnectInput!){
  AddSupplierConnect(from:$from, to:$to)	{
    from	{
      id
    }
    to		{
    
      uuid
      
      
    }
  }
}
  
`;



const AddSupplierTasks = gql`
  mutation AddSupplierTasks($from:_SupplierInput!,$to:_TaskInput!){
  AddSupplierTasks(from:$from, to:$to)	{
    from	{
      id
    }
    to		{
    
      uuid
      
      
    }
  }
}
  
`;

const AddTaskConnect = gql`
  mutation AddTaskConnect($from:_TaskInput!,$to:_ConnectInput!){
  AddTaskConnect(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      uuid
      
      
    }
  }
}
  
`;
const AddTaskSuppliers = gql`
  mutation AddTaskSuppliers($from:_TaskInput!,$to:_SupplierInput!){
  AddTaskSuppliers(from:$from, to:$to)	{
    from	{
      uuid
    }
    to		{
    
      id
      
      
    }
  }
}
  
`;

@Component({
  selector: 'app-task',
  templateUrl: './task.page.html',
  styleUrls: ['./task.page.scss'],
})
export class TaskPage implements OnInit {

  
  uid = this.authService.getUid(); 

  timeline=new Date();

  supplier: any[];
  Supplier: any[];
  email: string[];
  loading = true;
  error: any;

  business_type: string[];
  Business_Type: string[];

  businesstype: string[];
  BusinessType: string[];

  territory: string[];
  Territory: string[];

  task: string[];
  Task: string[];

  TaskSupplier: string[];
  taskSupplier: string[];


  category: string[];
  Category: string[];
  data:any;
  
  date = now;
Date: string;
now= Date.now();
uuid:any;
id:any;
taskID:any;
Business: string[];


Suppliers: string[];
suppliers: string[];
Connect: string[];
connect: string[];

User: string[];
user: string[];


is_active: boolean;

Cart: string[];
cart: string[];
Cartitem: string[];
cartitem: string[];

Checkout: string[];
checkout: string[];
length:any;

year:number= now.getFullYear();
month:number= now.getMonth()+1;
day:number= now.getDate();

//use for modal value from Jobs.ts
value:any;

  //Date: any;
  //date: any;
  dmy: string;

  constructor(private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo,
    private activatedroute:ActivatedRoute, 
    public alertController: AlertController,
    private modalController: ModalController,
    private router: Router) {}

  ngOnInit() {
    //this one works for page link --keep
    //let uuid = this.activatedroute.snapshot.paramMap.get('uuid');
    this.uuid = this.value;
    let catID =  this.activatedroute.snapshot.paramMap.get('CatID');
    //let id =  this.activatedroute.snapshot.paramMap.get('id');
    let uid = this.uid;
    

   let month = now.getMonth()+1;
    let year = now.getFullYear();
    let day = now.getDate();
    

    this.apollo
      .watchQuery({

        // THIS IS THE QUERY...
        query: gql`
         query Task($uuid:ID) {
          Task(uuid:$uuid) 
        {
          task
          description
          homeID
          uuid
          userID
          zip
          terID
          subID
          catID
          timeline
          is_active
          day
          month
          year
          dmy
          
          category{
            category
            image_path
          }
          subcategory{
            subcategory
            fee{
              fee
              inviteFee
            }
          }
          
          zips{
            zip
            town
          }
          suppliers	{
            id
            uid
            email
          }
          user	{
            id
            email
          }
          action	{
            id
            action
          }
          problem	{
            id
            problem
          }
           item	{
            id
            item
          }
          timelines{
            timeline
          } 
          emergency{
            emergency
          }
          supplied{
            supplied
            id
            isSupplied
          }
          connect{
            uuid
            task
            	invites{
                uuid
                userID
                homeID
                timeline
                	user{
                    id
                    name_first
                    name_last
                    email
                      user_info{
                        name_first
                        name_last
                        }
                          user_address{
                            street_number
                            street
                            city
                            state
                            zip
                          }
                              user_phone{
                                phone_type
                                phone_number
                              }
                      }
                  
                  
                suppliers{
                  uid
                  id
                  email
                  name_first
                  name_last
                  company
                }
              }
          }
              }
            }

        `,
        //variables: {uuid:this.activatedroute.snapshot.paramMap.get('uuid')} ,
        variables: {uuid: this.uuid} ,
        
      })

      
      // WHEN THE VALUE CHANGES, WE GET A STREAM FROM THE SUBSCRIPTION
      .valueChanges.subscribe((result: any) => {
        this.Task = result?.data?.Task;
        this.loading = result.loading;
        this.error = result.error;
      });

     

 this.apollo
      .watchQuery({

        // THIS IS THE QUERY...
        query: gql`
        query Supplier($uid:ID) {
          Supplier(uid:$uid) 
                         {
          username
            id
            uid
            email
            
              business_type	{
                id
                business_type
                category	{
                  id
                  image_path
                  category
                  task{
                    task
                    description
                    timeline
                  
                }
                
              }
            }
          }
        }



        `,
        variables: {uid:this.uid} ,
        
      })

      
      // WHEN THE VALUE CHANGES, WE GET A STREAM FROM THE SUBSCRIPTION
      .valueChanges.subscribe((result: any) => {
        this.Supplier = result?.data?.Supplier;
        this.loading = result.loading;
        this.error = result.error;
      });
  }
async createConnect(_formValue){
           
         
            let params = Object.assign(_formValue)
            //let uuid = this.uuid;
            let id = this.id;
            let uid = this.uid;
            let uuid = this.uuid;
            console.log('uuid', uuid);
            console.log('id', id);
            //console.log('taskID', taskID);
            console.log('uid', uid)

           
          
            this.apollo.mutate({

                mutation: createConnect,
                variables: params,
                refetchQueries: [`Connect`],
          
                // this will provide an update of the main AllUsers
                // query so the list gets updated...
                
              }).subscribe(({ data }) => {
                console.log(data);
                
                
                //this.router.navigate(['/buyer/connect/',this.activatedroute.snapshot.paramMap.get('uuid') ]);
                

                
              }, 
              (error) => {
                console.log('there was an very big ass error sending the query', error);
                alert("You broke the Internet!");
                
                console.log("this is a test",uuid);
             
              });   
              
              
              this.apollo
              .watchQuery({

               // THIS IS THE QUERY...
        query: gql`
        query Connect($taskID:ID,$userID:ID){
      Connect(orderBy:timeline_desc, first:1, taskID:$taskID, userID:$userID){
     uuid
      task
      taskID
      description
      homeID
      dmy
      zip
      terID
      subID
      catID
      userID
      fee
      timeline
       zips{
        town
      }
            category{
            category
            image_path
      }
          
    }
  }
        `,
      //variables: {taskID:this.activatedroute.snapshot.paramMap.get('uuid'),userID:this.uid} ,
      variables: {taskID:this.uuid,userID:this.uid} ,
        //variables:{taskID=this.taskID},
      })

      
      // WHEN THE VALUE CHANGES, WE GET A STREAM FROM THE SUBSCRIPTION
       .valueChanges.subscribe((result: any) => {
        this.Connect = result?.data?.Connect;
       
        this.loading = result.loading;
        this.error = result.error;
      });


      console.log('uuid', this.uuid);
      console.log('length', uuid.length);
      

}




AddConnectSuppliers(alpha, beta) {
  // let uuid = this.uuid;
  //let id = this.activatedroute.snapshot.paramMap.get('id');
  
  console.log("uuid",alpha);
  console.log("id",beta);
  //console.log("taskID",this.taskID);
  
  this.apollo.mutate({
    mutation:  AddConnectSuppliers,
    variables: {
      to: {
         id: beta
                },
         from: {
                uuid:alpha
                 }
          },
    
    
  }).subscribe(({ data }) => {
    console.log('uuid: ', alpha);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ASS error sending the query', error);
  });
}
ionViewDidLoad() {
    let uuid = this.uuid;
    console.log(this.uuid);
     let taskID = this.taskID;
     let uid = this.uid;
    console.log(this.taskID);
    console.log('uid',this.uid);
    
}
AddTaskConnect( vary,test) {
  console.log("test",test);

  console.log("vary",vary);
  
  
  
  
 

  this.apollo.mutate({
    mutation:  addTaskConnect,
    variables: {
      to: {
         uuid: test
                },
         from: {
                uuid:vary
                 }
           
          },
    
    
  }).subscribe(({ data }) => {
    //console.log('id: ', id);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });
}
AddConnectTasks( test, vary) {
  console.log("test",test);

  console.log("vary",vary);
  
  
  
  
 

  this.apollo.mutate({
    mutation:  addConnectTasks,
    variables: {
      to: {
         uuid: vary
                },
         from: {
                uuid:test
                 }
           
          },
    
    
  }).subscribe(({ data }) => {
    //console.log('id: ', id);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });
}
AddTaskSuppliers( test,id) {
  console.log("id",id);
  console.log("vary",test);
  
  this.apollo.mutate({
    mutation:  addTaskSuppliers,
    variables: {
      to: {
         id: id
                },
         from: {
                uuid:test
                 }
          },
    
    
  }).subscribe(({ data }) => {
    console.log('id: ', id);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });
}
AddSupplierTasks( id, uuid) {
  
  console.log("id",id);

  console.log("uuid",uuid);
 
  this.apollo.mutate({
    mutation:  addSupplierTasks,
    variables: {
      to: {
         uuid: uuid
                },
         from: {
                id:id
                 }
           
          },
    
    
  }).subscribe(({ data }) => {
    console.log('id: ', id);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });
}
AddSupplierConnect(id, uuid) {
  // let uuid = this.uuid;
  //let id = this.activatedroute.snapshot.paramMap.get('id');
  
  console.log("uuid",uuid);
  console.log("id",id);
  
  this.apollo.mutate({
    mutation:  AddSupplierConnect,
    variables: {
      to: {
         uuid: uuid
                },
         from: {
                id:id
                 }
          },
    
    
  }).subscribe(({ data }) => {
    console.log('uuid: ', uuid);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });


}
AddInviteUser( uuid, homeID) {
  
  console.log("uuid",uuid);

  console.log("homeID",homeID);
 
  this.apollo.mutate({
    mutation:  AddInviteUser,
    variables: {
      to: {
         id: homeID
                },
         from: {
                uuid:uuid
                 }
           
          },
    
    
  }).subscribe(({ data }) => {
    console.log('uuid: ', uuid);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });


}
AddUserInvite( homeID, uuid ) {
  
  console.log("uuid",uuid);

  console.log("homeID",homeID);
 
  this.apollo.mutate({
    mutation:  AddUserInvite,
    variables: {
      to: {
         uuid: uuid
                },
         from: {
          id: homeID
                 }
           
          },
    
    
  }).subscribe(({ data }) => {
    console.log('uuid: ', uuid);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });



  


  }

  async CreateCartitem(_formValue2, taskID){
             
           
              let params = Object.assign(_formValue2)
              let uuid = this.uuid;
              let id = this.id;
              console.log('uuid', uuid);
              console.log('id', id);
              console.log('taskID', taskID)
  
             
            
              this.apollo.mutate({
  
                  mutation: CreateCartitem,
                  variables: params,
                  refetchQueries: [`Cartitem`],
            
                  // this will provide an update of the main AllUsers
                  // query so the list gets updated...
                  
                }).subscribe(({ data }) => {
                  console.log(data) 
   
   //this.router.navigate(['cartitem',this.activatedroute.snapshot.paramMap.get('uuid'),this.activatedroute.snapshot.paramMap.get('id'),this.now ]);          
            
                }, 
                (error) => {
                  console.log('there was an very big ass error sending the query', error);
                  alert("You broke the Internet!")
                //console.log('uuid', uuid);
                console.log('id', id);
                });


              }
              AddCartCartitems() {
               // console.log("uuid",uuid);
                //console.log("id",id);
                
                this.apollo.mutate({
                  mutation:  AddCartCartitems,
                  variables: {
                    to: {
                       now: this.now
                              },
                       from: {
                              now:this.now
                               }
                        },
                  
                  
                }).subscribe(({ data }) => {
                  
                  
                  //console.log('uuid: ', uuid);
                  //console.log('territory: ', territory);
                  //console.log('data',data);
                
                }, (error) => {
                  console.log('there was an big ass error sending the query', error);
                });
             

}
 openDetails() {
   let navigationExtras: NavigationExtras = {
      state: {
        connect: this.connect
      }
    };
    this.router.navigate(['app/connect'], navigationExtras);
 
}
navigateToHome(){
  this.router.navigate(['app',this.activatedroute.snapshot.paramMap.get('id'),'home',this.activatedroute.snapshot.paramMap.get('id') ]);
  
}
navigateToCartitem(){
  //this.router.navigate(['cartitem',this.activatedroute.snapshot.paramMap.get('uuid'),this.activatedroute.snapshot.paramMap.get('id'),this.now ]); 
  this.router.navigate(['cartitem',this.activatedroute.snapshot.paramMap.get('uuid'),this.activatedroute.snapshot.paramMap.get('id'),this.now ]); 

  this.apollo
      .watchQuery({ 

        // THIS IS THE QUERY...
        query: gql`
        query Cartitem($now:Float) {
          Cartitem(now:$now) 
        {
         uuid
         now
         fee
         timeline
         description
         task
          }
        }


        `,
        variables: {now:parseFloat(this.activatedroute.snapshot.paramMap.get('now'))} ,
        
      })

      
      // WHEN THE VALUE CHANGES, WE GET A STREAM FROM THE SUBSCRIPTION
      .valueChanges.subscribe((result: any) => {
        this.Cartitem = result?.data?.Cartitem;
        this.loading = result.loading;
        this.error = result.error;
      }); 
      
       }
    async CreateCheckout(_formValue){
               
             
                let params = Object.assign(_formValue)
                let uuid = this.uuid;
                let id = this.id;
                console.log('uuid', uuid);
                console.log('id', id);
                
    
               
              
                this.apollo.mutate({
    
                    mutation: CreateCheckout,
                    variables: params,
                    refetchQueries: [`Checkout`],
              
                    // this will provide an update of the main AllUsers
                    // query so the list gets updated...
                    
                  }).subscribe(({ data }) => {
                    console.log(data) 
     //this.router.navigate(['app',this.activatedroute.snapshot.paramMap.get('id'),'connect',this.activatedroute.snapshot.paramMap.get('uuid'),this.activatedroute.snapshot.paramMap.get('id') ]);
                  
              
                  }, 
                  (error) => {
                    console.log('there was an very big ass error sending the query', error);
                    alert("You broke the Internet!")
                    console.log('uuid', uuid);
                    console.log('id', id);
                  });

                  this.apollo
                  .watchQuery({
            
                    // THIS IS THE QUERY...
                    query: gql`
                    query Checkout($now:Float) {
                      Checkout(now:$now) 
                    {
                     uuid
                     now
                     
                     timeline
                     cartID
                      }
                    }
            
            
                    `,
                    //variables: {now:parseFloat(this.activatedroute.snapshot.paramMap.get('now'))} ,
                    variables: {now:this.now} ,
                  })
            
                  
                  // WHEN THE VALUE CHANGES, WE GET A STREAM FROM THE SUBSCRIPTION
                  .valueChanges.subscribe((result: any) => {
                    this.Checkout = result?.data?.Checkout;
                    this.loading = result.loading;
                    this.error = result.error;
                  }); 
            
                }

                gotoConnect(uuid) {
                  let navigationExtras: NavigationExtras = {
                     state: {
                       uuid: uuid
                     }
                     
                   };
                   console.log('uuid button',uuid);
                   this.router.navigate(['/buyer/connect/',navigationExtras]);
                   
               }
              
              async presentAlert() {
                const alert = await this.alertController.create({
                  header: 'Alert',
                  subHeader: 'Fees',
                  message: 'I have reviewed the legal agreements and have agreed upon the fee assigned to this project, Please track progress on the connect Tab below',
                  buttons: ['OK'],
                });
              
                await alert.present();
                let result = await alert.onDidDismiss();
                console.log(result);
              }

              //newly added from task ProV4

              AddConnectCategory(uuid, catID) {
                // let uuid = this.uuid;
                //let id = this.activatedroute.snapshot.paramMap.get('id');
                
                console.log("uuid",uuid);
                console.log("catID",catID);
                //console.log("taskID",this.taskID);
                
                this.apollo.mutate({
                  mutation:  AddConnectCategory,
                  variables: {
                    to: {
                       id: catID
                              },
                       from: {
                              uuid:uuid
                               }
                        },
                  
                  
                }).subscribe(({ data }) => {
                  console.log('uuid: ', uuid);
                  //console.log('territory: ', territory);
                  //console.log('data',data);
                
                }, (error) => {
                  console.log('there was an big ass error sending the query', error);
                });
              
                }

                AddConnectSubcategory(uuid, subID) {
                  // let uuid = this.uuid;
                  //let id = this.activatedroute.snapshot.paramMap.get('id');
                  
                  console.log("uuid",uuid);
                  console.log("subID",subID);
                  //console.log("taskID",this.taskID);
                  
                  this.apollo.mutate({
                    mutation:  AddConnectSubcategory,
                    variables: {
                      to: {
                         id: subID
                                },
                         from: {
                                uuid:uuid
                                 }
                          },
                    
                    
                  }).subscribe(({ data }) => {
                    console.log('uuid: ', uuid);
                    //console.log('territory: ', territory);
                    //console.log('data',data);
                  
                  }, (error) => {
                    console.log('there was an big ass error sending the query', error);
                  });



                } 
                AddConnectZips(uuid, zip) {
  // let uuid = this.uuid;
  //let id = this.activatedroute.snapshot.paramMap.get('id');
  
  console.log("uuid",uuid);
  console.log("zip",zip);
  console.log("taskID",this.taskID);
  
  this.apollo.mutate({
    mutation:  AddConnectZips,
    variables: {
      to: {
         id: zip
                },
         from: {
                uuid:uuid
                 }
          },
    
    
  }).subscribe(({ data }) => {
    console.log('uuid: ', uuid);
    //console.log('territory: ', territory);
    //console.log('data',data);
  
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
  });




  

}
AddDayConnect(dmy, uuid) {
  console.log("dmy",dmy);
    console.log("uuid",uuid);
   
    this.apollo.mutate({
      mutation:  AddDayConnect,
      variables: {
        from: {
         id:dmy
                  },
            to: {
                  uuid:uuid
                   }
             
            },
      
      
    }).subscribe(({ data }) => {
      console.log('sendMessage: ', dmy);
       console.log(data);
          
    
    }, (error) => {
      console.log('there was an Big ass error sending the query', error);
    });
  }
    closeModal():void{
      this.modalController.dismiss();
  
 
 
}
} 