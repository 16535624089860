import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-markup',
  templateUrl: './markup.page.html',
  styleUrls: ['./markup.page.scss'],
})
export class MarkupPage implements OnInit {

  segmentModel = "markup";

  markup = { "cogs": 100,
             "markup10":10,   
             "markup15":15, 
             "markup20":20,
             "markup25":25, 
             "markup30":30,
             "markup35":35, 
             "markup40":40, 
             "markup45":45,
             "markup50":50, 
             "markup55":55, 
             "markup60":60, 
             "markup65":65,
             "markup70":70,
             "markup75":75,
             "markup80":80,
             "markup85":85,
             "markup90":90,
             "markup95":95,
             "markup100":100,
             "markup125":125,
             "markup150":150,
             "markup175":175,
             "markup200":200,
             "markup225":225, 
             "markup250":250,       
            
            };
  
profit10() : any {
    return this.markup.cogs * this.markup.markup10/100;
}
profit15() : any {
  return this.markup.cogs * this.markup.markup15/100;
}
profit20() : any {
  return this.markup.cogs * this.markup.markup20/100;
}
profit25() : any {
  return this.markup.cogs * this.markup.markup25/100;
}
profit30() : any {
  return this.markup.cogs * this.markup.markup30/100;
}
profit35() : any {
  return this.markup.cogs * this.markup.markup35/100;
}
profit40() : any {
  return this.markup.cogs * this.markup.markup40/100;
}
profit45() : any {
  return this.markup.cogs * this.markup.markup45/100;
}
profit50() : any {
  return this.markup.cogs * this.markup.markup50/100; 
}
profit55() : any {
  return this.markup.cogs * this.markup.markup55/100;
}
profit60() : any {
  return this.markup.cogs * this.markup.markup60/100;
}
profit65() : any {
  return this.markup.cogs * this.markup.markup65/100;
}
profit70() : any {
  return this.markup.cogs * this.markup.markup70/100;
}
profit75() : any {
  return this.markup.cogs * this.markup.markup75/100;
}
profit80() : any {
  return this.markup.cogs * this.markup.markup80/100;
}
profit85() : any {
  return this.markup.cogs * this.markup.markup85/100;
}
profit90() : any {
  return this.markup.cogs * this.markup.markup90/100;
}
profit95() : any {
  return this.markup.cogs * this.markup.markup95/100;
}
profit100() : any {
  return this.markup.cogs * this.markup.markup100/100;
}
profit125() : any {
  return this.markup.cogs * this.markup.markup125/100;
}
profit150() : any {
  return this.markup.cogs * this.markup.markup150/100;
}
profit175() : any {
  return this.markup.cogs * this.markup.markup175/100;
}
profit200() : any {
  return this.markup.cogs * this.markup.markup200/100;
}
profit225() : any {
  return this.markup.cogs * this.markup.markup225/100;
}
profit250() : any {
  return this.markup.cogs * this.markup.markup250/100;
}

price10() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup10/100) ;
}
price15() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup15/100) ;
}
price20() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup20/100) ;
}
price25() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup25/100) ;
}
price30() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup30/100) ;
}
price35() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup35/100) ;
}
price40() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup40/100) ;
}
price45() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup45/100) ;
}
price50() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup50/100) ;
}
price55() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup55/100) ;
}
price60() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup60/100) ;
}
price65() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup65/100) ;
}
price70() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup70/100) ;
}
price75() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup75/100) ;
}
price80() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup80/100) ;
}
price85() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup85/100) ;
}
price90() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup90/100) ;
}
price95() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup95/100) ;
}
price100() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup100/100) ;
}
price125() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup125/100) ;
}
price150() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup150/100) ;
}
price175() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup175/100) ;
}
price200() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup200/100) ;
}
price225() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup225/100) ;
}
price250() : any {
  return this.markup.cogs + (this.markup.cogs * this.markup.markup250/100) ;
}

margin10() : any {
  return ((this.markup.cogs * this.markup.markup10/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup10/100)))*100).toFixed(2); 
}
margin15() : any {
  return ((this.markup.cogs * this.markup.markup15/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup15/100)))*100).toFixed(2); 
}
margin20() : any {
  return ((this.markup.cogs * this.markup.markup20/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup20/100)))*100).toFixed(2); 
}
margin25() : any {
  return ((this.markup.cogs * this.markup.markup25/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup25/100)))*100).toFixed(2); 
}
margin30() : any {
  return ((this.markup.cogs * this.markup.markup30/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup30/100)))*100).toFixed(2); 
}
margin35() : any {
  return ((this.markup.cogs * this.markup.markup35/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup35/100)))*100).toFixed(2); 
}
margin40() : any {
  return ((this.markup.cogs * this.markup.markup40/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup40/100)))*100).toFixed(2); 
}
margin45() : any {
  return ((this.markup.cogs * this.markup.markup45/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup45/100)))*100).toFixed(2); 
}
margin50() : any {
  return ((this.markup.cogs * this.markup.markup50/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup50/100)))*100).toFixed(2); 
}
margin55() : any {
  return ((this.markup.cogs * this.markup.markup55/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup55/100)))*100).toFixed(2); 
}
margin60() : any {
  return ((this.markup.cogs * this.markup.markup60/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup60/100)))*100).toFixed(2); 
}
margin65() : any {
  return ((this.markup.cogs * this.markup.markup65/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup65/100)))*100).toFixed(2); 
}
margin70() : any {
  return ((this.markup.cogs * this.markup.markup70/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup70/100)))*100).toFixed(2); 
}
margin75() : any {
  return ((this.markup.cogs * this.markup.markup75/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup75/100)))*100).toFixed(2); 
}
margin80() : any {
  return ((this.markup.cogs * this.markup.markup80/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup80/100)))*100).toFixed(2); 
}
margin85() : any {
  return ((this.markup.cogs * this.markup.markup85/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup85/100)))*100).toFixed(2); 
}
margin90() : any {
  return ((this.markup.cogs * this.markup.markup90/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup90/100)))*100).toFixed(2); 
}
margin95() : any {
  return ((this.markup.cogs * this.markup.markup95/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup95/100)))*100).toFixed(2); 
}
margin100() : any {
  return ((this.markup.cogs * this.markup.markup100/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup100/100)))*100).toFixed(2); 
}
margin125() : any {
  return ((this.markup.cogs * this.markup.markup125/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup125/100)))*100).toFixed(2); 
}
margin150() : any {
  return ((this.markup.cogs * this.markup.markup150/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup150/100)))*100).toFixed(2); 
}
margin175() : any {
  return ((this.markup.cogs * this.markup.markup175/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup175/100)))*100).toFixed(2); 
}
margin200() : any {
  return ((this.markup.cogs * this.markup.markup200/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup200/100)))*100).toFixed(2); 
}
margin225() : any {
  return ((this.markup.cogs * this.markup.markup225/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup225/100)))*100).toFixed(2); 
}
margin250() : any {
  return ((this.markup.cogs * this.markup.markup250/100) /  ((this.markup.cogs + (this.markup.cogs * this.markup.markup250/100)))*100).toFixed(2); 
}




  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private router: Router) {}

  ngOnInit() {

    
  }
  closeModal():void{
    this.modalController.dismiss();
    
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
}
