import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { SubscriptionPage } from '../subscription/subscription.page';

const createBases = gql`
mutation CreateBases($uid:ID
                      
                      ){
                        createBases(input:
                          {

                            supplierUID:$uid
                            is_active: true
                            supplier:{connect:{
                              where: {
                                node:{uid:$uid}
                              } 
                            }}
                           
                            
                          }
                          
                        ) 
                        
                        {
                         bases{
                           baseID
                           uid
                           supplierID
                           supplierUID
                            is_active
                            baseSupplier{
                              supplierID
                              uid
                              count_base
                            }
                            
                              supplier{
                               supplierID
                                uid
                              }
                          
                          
                              }
                          
                        }
                       
                          }
                          
                                        

`;

const updateSuppliers = gql`
  mutation updateSuppliers(
 					 	$uid:ID,
  					$dayID:ID,
            $weekID:ID,
		){
    		updateSuppliers(
        where: { uid:$uid }
        update:{dayID:$dayID,weekID:$weekID}
        connect: { days: { where: {node:{dayID:$dayID}}}, weeks:{where:{node:{weekID:$weekID}}}}
        
         
        
          
        
    
    ) {
      suppliers {
        uid
        			days{
                dayID
               
                
              }
            weeks{
              weekID
            }
        }
    }
}

        

  
`;
const createToolbelts = gql`
mutation CreateToolbelts($uid:ID
                      
                      ){
                        createToolbelts(input:
                          {

                            supplierUID:$uid
                            
                            suppliers:{connect:{
                              where: {
                                node:{uid:$uid}
                              } 
                            }}

                            program:{connect:{
                              where: {
                                node:{program_no:10}
                              } 
                            }}

                            
                           
                            
                          }
                          
                        ) 
                        
                        {
                         toolbelts{
                           toolbeltID
                           supplierID
                           supplierUID
                            
                            suppliers{
                              supplierID
                              uid
                             
                            }
                            
                              program{
                               programID
                              description
                              points_award
                              xconnect
                              xinvite
                              xaccept
                              xaward
                              }
                          token{
                            tokenID
                          }
                          
                              }
                          
                        }
                       
                          }
                          
                                        

`;

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.page.html',
  styleUrls: ['./base-modal.page.scss'],
})
export class BaseModalPage implements OnInit {

 //uid:any;
 uid = this.authService.getUid(); 
 loading = true;
 error: any;
 suppliers: any[];
 
 dataReturned:any;
 todays:any;
 dayID:any;
 weekID:any;

 constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

 ngOnInit() {
   let supplierUID = this.navParams.get('uid');
   this.dayID=this.dayID;
  this.weekID=this.weekID;
  
   console.log('uidsupplierUID',supplierUID);
   console.log('dayID',this.dayID);
   console.log('weekID',this.weekID);

   this.apollo
  .watchQuery({
    query: gql`
       query todays
      {
      todays{
      todayID
      month{
      monthID
      month
      }

      day{
          day
          dayID
          date
          weekNumber
          month
          year
      }
        week{
          weekID
          week
          banner_placement{
            bannerUID
            bannerName
            
          }
          promos_placement{
            promoUID
            promotionName
          }
         
}
}
}

    `,
           //variables: {uid:this.uid} , 
      // variables:    {uid: this.afAuth.auth.currentUser.uid},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.todays = result?.data?.todays;
    this.loading = result.loading;
    this.error = result.error;
  });
  console.log('todays',this.todays);



   this.apollo
                 .watchQuery({
                   query: gql`
                      query suppliers($uid:ID) {
         suppliers(where:{uid:$uid}) 
                     {
            username
            supplierID
            uid
            email
            count_base
             
             }
         }
 
 
           `,
                // variables:    {uid: this.uid},
                variables:    {uid: this.afAuth.auth.currentUser.uid}
           })
                 .valueChanges.subscribe((result: any) => {
                   this.suppliers = result?.data?.suppliers;
                   this.loading = result.loading;
                   this.error = result.error;
                 });
                 console.log('uid',this.uid)
 }
 closeModalBase():void{
   this.modalController.dismiss(null, null, "base");
   
}


createBases(uid) {

 //let params = Object.assign(credentials)
 this.apollo.mutate({
   mutation:  createBases,
   
   variables: {
     uid: this.afAuth.auth.currentUser.uid
 //uid:uid
  //uid: this.afAuth.auth.currentUser.uid
  //uid: this.afAuth.auth.currentUser.uid

  
 
   
   
 },
   
 }).subscribe(({ data }) => {
  // console.log('sendMessage: ', uuid);

    console.log(data);
    console.log('uid',uid);
    
console.log("Base ID Created ");
//this.openModalSubscriptions(),
this.closeModalBase()


//this.navCtrl.navigateForward('/setup'); 
//this.router.navigate(['/buyer/home/']);
  
 }, (error) => {
   console.log('there was an big ass error sending the query', error);
   
   //this.navCtrl.navigateRoot('/setup');
 });


}
async updateSuppliers(uid,dayID,weekID) {
  
  this.apollo.mutate({
    mutation:  updateSuppliers,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    //zip:this.addressForm.value.zip,
    dayID:dayID,
    weekID:weekID
    
    
  },
    
  }).subscribe(({ data }) => {
   // console.log('sendMessage: ', uuid);
   console.log('dayID',dayID);
     console.log(data);
     //this.router.navigateByUrl('seller/entry');
console.log("Form Submitted!!!!");
//this.router.navigateByUrl('/seller/entry');
//this.navCtrl.navigateForward('/setup'); 
// this.router.navigate(['/app/setup/',this.id ]);
//this.router.navigateByUrl('/seller/entry');

   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    //this.router.navigate(['/seller/entry' ]); 
    //this.navCtrl.navigateRoot('/setup');
  });
}
  createToolbelts(uid) {

    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation:  createToolbelts,
      
      variables: {
        uid: this.afAuth.auth.currentUser.uid
    //uid:uid
     //uid: this.afAuth.auth.currentUser.uid
     //uid: this.afAuth.auth.currentUser.uid
   
     
    
      
      
    },
      
    }).subscribe(({ data }) => {
     // console.log('sendMessage: ', uuid);
   
       console.log(data);
       console.log('uid',uid);
       
   console.log("Toolbelt Created ");
   //this.openModalSubscriptions(),
   this.closeModalBase()
   
   
   //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/buyer/home/']);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    });
}
}
