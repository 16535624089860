import { Component, OnInit, ViewChild } from '@angular/core';
//import { IonSlides } from '@ionic/angular';
//slides add functions below
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';
import { NavService } from '../../services/nav.service';
import {Apollo, gql} from 'apollo-angular';
import { ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import { ModalController } from '@ionic/angular';



@Component({
  selector: 'app-pro-connect',
  templateUrl: './pro-connect.page.html',
  styleUrls: ['./pro-connect.page.scss'],
})
export class ProConnectPage implements OnInit {

  uid = this.authService.getUid(); 
  suppliers: any[];
  Suppliers: any[];
  connects: any[];
  loading = true;
  error: any;

  constructor(private authService: AuthService,
    private afAuth: AngularFireAuth, 
    private navService: NavService,
    private activatedroute:ActivatedRoute,
    private modalController: ModalController,

    private apollo: Apollo) { }


  ngOnInit() {

    this.apollo
        .watchQuery({
          query: gql`
            query connects($supplierID:ID){
    connects(where:{supplierID:$supplierID},options: {sort:{date_create:DESC}}){
      date_create
      supplierID
      supplierUID
      uid
      connectTask{
        taskID
        task_description
        task_detail
        date_create
        category_tag
        subcategory_tag
        item_tag
        city_tag
        is_active
        count_connect
        count_decline
        taskDaysAgo
      }
    }
  }
  

    
    
    `,
        variables:    {supplierID: this.afAuth.auth.currentUser.uid},
            //variables:    {supplierID: uid}

    })
        .valueChanges.subscribe((result: any) => {
          this.connects = result?.data?.connects;
          this.loading = result.loading;
          this.error = result.error;
        });
  }
  closeModal():void{
    this.modalController.dismiss();
    }
}
