import { Component, OnInit, Input,AfterViewInit, ViewChild, ElementRef } from '@angular/core';
//import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql, QueryRef} from 'apollo-angular';
//services
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { CartModalPage } from '../cart-modal/cart-modal.page';


import { ActivatedRoute} from '@angular/router';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import {H3Index} from "h3-js";
import {H3IndexInput} from "h3-js";
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//mport {h3} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";
import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {isPentagon} from "h3-js";
import {getResolution} from "h3-js";
import {isValidCell} from "h3-js";


//newly added please see change log  https://h3geo.org/docs/library/migration-3.x/functions/
//https://h3geo.org/docs/library/migration-3.x/functions
//this map code from https://github.com/matthiasfeist/what-the-h3index/blob/main/src/index.js

//geoToH3 updated to latLngToCell
//h3ToGeo updated to cellToLatLng
//polyfill updated to polygonToCells
//h3GetResolution updated to getResolution
//h3IsPentagon updated to isPentagon
//kring updated to gridDisk
//h3IsValid updated to isValidCell

//import {geoToH3} from "h3-js";
//import {h3ToGeo} from "h3-js";

//mapbox

//mapbox
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';
//placekey  https://github.com/Placekey/placekey-js
import {geoToPlacekey} from '@placekey/placekey';
import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';
//environment
//import { environment } from "../../../environments/environment";
//npm install geojson2h3
import geojson2h3 from 'geojson2h3';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
//H3
import  { h3SetToFeatureCollection } from 'geojson2h3';
import {cellToChildren} from "h3-js";
let h3IndexToHighlight = '';
//const h3Input = document.getElementById('zoomToIndex');
const h3Input = '872a10545ffffff';

const GET_TODAYS = gql`
  query GetToday {
    todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
`;

@Component({
  selector: 'app-invite-detail',
  templateUrl: './invite-detail.page.html',
  styleUrls: ['./invite-detail.page.scss'],
})
export class InviteDetailPage implements OnInit {

  
  
  cartItemCount: BehaviorSubject<number> = this.cartService.getCartItemCount();
  @Input() dataArr: string;
  segmentModel = "specs";

  inviteID:any;
  uid = this.authService.getUid(); 
  invites: string[];
  items: string[];
  tasks: string[];
  task: string[];
  inviteTask:any[];

  suppliers: string[];
  loading = true;
  error: any;
  item:any;
  fee:any;
  //this added for cart checkout
  product:any;
  productID:any;
  amount:any;
  todays:any;
  today:any;
  day:any;
  dayID:any;
  weekID:any;
  week:any;
  data:any;
  result:any

  //map 

  h3Input:any;
  latitude:any;
  longitude:any;
  
  supplierID:any;
  h310:any[];
  h39:any[];
  h37:any[];
  h36:any[];
  h35:any[];
  h34:any[];
  h33:any[];
  h32:any[];
  h31:any[];
  h30:any[];
monthID:any;
  //map:any[];


  geomap:any[];

  
  private mapbox: mapboxgl.Map;
  private map = {};
  private directions = {};
  private container = {};
  private value: any;
  private values: any;

 
  constructor( private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    private activatedroute:ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private cartService: CartService,
    private navParams: NavParams) {
      mapboxgl.accessToken = environment.mapboxgl.accessToken;

    console.log(JSON.stringify(navParams.get('dataArr')));
   }
   private querySubscription: Subscription;
   


  ngOnInit() {

    
  
    
    // this.dayID=12;
     this.day=this.dayID;
     this.week=this.weekID;
    this.monthID= this.navParams.get('monthID');
    let inviteID = this.navParams.get('inviteID');
    let inviteH310 = this.navParams.get('inviteH310');
    let latitude = this.navParams.get('latitude');
    let longitude = this.navParams.get('longitude');




    this.item = this.invites;
    //this.product.id = this.inviteID;
    this.amount = this.cartService.getItemCount(this.inviteID);
    console.log('inviteID',inviteID);
    console.log('uid',this.uid)
    console.log('amount',this.amount);
    console.log('dayID',this.dayID);
    console.log('monthID',this.monthID);


    let h310geo =cellToBoundary(inviteH310,true);
    
    console.log('h310geo',h310geo);

    

    

    let julie = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]]
     // coordinates: geo,
      coordinates: [h310geo],
     
      }
    };
    console.log('julie',julie);

    
    mapboxgl.accessToken = mapboxgl.accessToken;

    const map = new mapboxgl.Map({
          
      container: 'map',
      
      style: 'mapbox://styles/mapbox/streets-v12',

      //center: [-74.08097014877629,40.98385513546514],
      center: [longitude,latitude],
      zoom: 16,
      maxBounds: [[-170, -85], [170, 85]]
      });

     // map.addControl(
       // new MapboxGeocoder({
       // accessToken: mapboxgl.accessToken,
       // mapboxgl: mapboxgl
       // })
        //);

       // add navigation controls \(zoom buttons, pitch & rotate\)
      map.addControl(new mapboxgl.NavigationControl());
      //full screen control
      map.addControl(new mapboxgl.FullscreenControl({container: document.querySelector('body')}));

      map.on('load', function () {
        map.resize();
    });
       
      map.on('load', () => {
        map.addSource('tiles-geojson', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      
        map.addSource('tiles-centers-geojson', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        //newly added for Task Map

        
          map.addSource("tasks", {
          type: "geojson",
          ///////////data: hashtag
          data: julie
          });

        
             map.addLayer({
              'id': 'blue',
              'type': 'fill',
              'source': 'tasks', // reference the data source
              'layout': {},
              'paint': {
              'fill-color': '#0080ff', // blue color fill
              //'fill-color': 'red', // blue color fill

              'fill-opacity': .075
              }
              });
             
              // Add a black outline around the polygon.
              map.addLayer({
              'id': 'outline11',
              'type': 'line',
              'source': 'tasks', // reference the data source

              'layout': {},
              'paint': {
              'line-color': 'black',
              'line-width': 1.0
              }
              });
              

              //add map layers
              // Add a new layer to visualize the polygon.
            map.addLayer({
            'id': 'h39Jobs',
            'type': 'fill',
            'source': 'tasks', // reference the data source
            'layout': {},
            'paint': {
            'fill-color': '#0080ff', // blue color fill
            //'fill-color': 'red', // blue color fill

            'fill-opacity': 0.10
            }
            });
           
            
      //end of task map data
        map.addLayer({
          id: 'tiles',
          source: 'tiles-geojson',
          type: 'line',
          paint: {
            'line-color': '#000'
          }
        });
      
        map.addLayer({
          id: 'tiles-shade',
          source: 'tiles-geojson',
          type: 'fill',
          paint: {
            'fill-color': ['case', ['get', 'pentagon'], 'rgba(255,0,0,0.5)', 'rgba(0,0,0,0.0)']
          }
        });
      
        map.addLayer({
          id: 'tiles-centers',
          source: 'tiles-centers-geojson',
          type: 'symbol',
          layout: {
            'text-field': ['format', ['get', 'text'], { 'font-scale': .5 }],
            'text-offset': [0, -1],
          },
          paint: {
            'text-color': '#000',
            'text-color-transition': {
              duration: 0
            },
            'text-halo-color': ['case', ['get', 'highlight'], '#0f0', '#fff'],
            'text-halo-width': 1,
            'text-halo-blur': 1
          }
        });
      
       
      });



    this.apollo
    .watchQuery({
      query: gql`
         query todays
 {
 todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
 
      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays)
   
    this.apollo
                  .watchQuery({
                    query: gql`
                       query Supplier($uid:ID) {
          suppliers(where:{uid:$uid}) 
                      {
            username
             supplierID
          
              uid
              email
              subscriptionConnect{
                supplierID
              }
              subscriptionTerritory{
                territoryID
                territory
              }
              subscriptionBusinessType{
                businessTypeID
                business_type
              }
              subscriptionTasks{
                taskID
                count_connect
                connectSupplierID
                territoryID
                task_description
                task_detail
                date_create
                mytaskConnects{
                  supplierID
                
                }
                  category{
                    category
                  }
                  subcategory{
                    subcategory
                  }
                  item{
                    item
                    image_path
                  }
                  zip{
                zip
                town
              }
              }
              
              

              territory{
                          territoryID
                          territory
                        }
              
                business_type	{
                  businessTypeID
                  image_path
                  business_type
                  
                
                  category	{
                    categoryID
                    image_path
                    category
                    connect{
                      connectID
                      task
                    }
                    tasks{
                      taskID
                      
                     
                      
                        subcategory{
                          subcategory
                          image_path
                          
                        }
                    
                    
                  }
                  
                }
           
            
              }
                      }}
  
  
            `,
                  variables:    {uid: this.afAuth.auth.currentUser.uid},
            
            })
                  .valueChanges.subscribe((result: any) => {
                    this.suppliers = result?.data?.suppliers;
                    this.loading = result.loading;
                    this.error = result.error;
                  });
                  
           
              

    
    this.apollo
    .watchQuery({
      query: gql`
       query Invites($inviteID:ID) {
          invites(where:{inviteID:$inviteID}){
            inviteID
            inviteSupplierID
            acceptSupplierID
            task
            description
      			date_create
      			schedule
            supplierID
            supplierUID
            homeID
            taskID
            connectID
            categoryID
            subcategoryID
            territoryID
      			note_to_supplier
                inviteTask{
                task_description
                city_tag
                date_create
                taskDaysAgo
                count_connect
                count_accept
                count_invites
                    taskLocation{
                    h39
                }
                
            }

            inviteAccept{
              supplierID
              supplierUID
              date_create

                acceptHome{
                  email
                  homeInfo{
                    name_first
                    name_last
                  }
                  homeAddress{
                    street_number
                    street
                    city
                    state
                    zip
                  }
                }
            }
      				inviteCategory{
                category
                image_path
              }
      				inviteSubcategory{
                subcategory
                
              }
      				inviteItem{
                item
                image_path
              }
      				inviteZip{
                town
                zip
              }
      				inviteFee{
        			amount
      				}
      				inviteTerritory{
                territory
              }
   
    
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {inviteID:inviteID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.invites = result?.data?.invites;
      this.loading = result.loading;
      this.error = result.error;
    });
   
  console.log('data',this.invites)
 
}
  closeModal():void{
    this.modalController.dismiss();
}
addToCart(i) {
  this.cartService.addProduct(i);
  
}

removeFromCart(i) {
  this.cartService.decreaseProduct(i);
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
async openCart(dayID,weekID,uid,monthID) {
  const modal = await this.modalController.create({
    component: CartModalPage,
    cssClass: 'my-modal-class',
    id:"cart",
    componentProps: {
      dayID:dayID,
      weekID:weekID,
      uid:uid,
      monthID:monthID
     
   
    }
  });
  modal.present();
  console.log('dayID',this.dayID);
  console.log('weekID',this.weekID);
  console.log('monthID',this.monthID);

}
showToast() {
  this.toastController.create({
    header: 'Success',
    message: 'Added to Cart!',
    position: 'bottom',
    cssClass: 'my-custom-class',
    color: 'pro',
    duration: 3000,
    buttons: [
      {
        side: 'end',
        icon: 'cart',
       
       
       
      }, {
        side: 'end',
        text: 'Close',
        role: 'cancel',
        handler: () => {
          console.log('Close clicked');
          this.closeModal()
        }
      }
    ]
  }).then((obj) => {
    obj.present();
  });
 


}






}
