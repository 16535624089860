import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-ad-modal',
  templateUrl: './ad-modal.page.html',
  styleUrls: ['./ad-modal.page.scss'],
})
export class AdModalPage implements OnInit {
  uid = this.authService.getUid(); 
  
  adPlacements: any[];
  loading = true;
  error: any;
  dataReturned: any;
  adPlacementUID: any;


  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let adPlacementUID = this.navParams.get('adPlacementUID');
    // let territoryID = this.navParams.get('territoryID');
     //let zipID = this.navParams.get('zipID');
     let todayID = this.navParams.get('todayID');
     let dayID = this.navParams.get('dayID');
     //console.log('territoryID', territoryID);
     //console.log('zipID', zipID);
     console.log('todayID1', todayID);
     console.log('dayID', dayID);
 
     this.apollo
     .watchQuery({
       query: gql`
         query adPlacements($adPlacementUID:ID)
 {
   adPlacements(where:{adPlacementUID:$adPlacementUID}){
    adID
    adPlacementUID
    headline
    subheader
    body
    image_path
    url
     baseIndex
     date_placement
     week
     year
     
     
   }
 }
 
       `,
              //variables: {uid:this.uid} , 
          variables:    {adPlacementUID:adPlacementUID},
          
     })
     .valueChanges.subscribe((result: any) => {
       this.adPlacements = result?.data?.adPlacements;
       this.loading = result.loading;
       this.error = result.error;
     });
 
 
 
   }
 closeModal():void{
     this.modalController.dismiss();
 }
  }


