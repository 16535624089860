import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql, QueryRef} from 'apollo-angular';
//services
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

import { ActivatedRoute} from '@angular/router';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import {H3Index} from "h3-js";
import {H3IndexInput} from "h3-js";
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//mport {h3} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";
import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {isPentagon} from "h3-js";
import {getResolution} from "h3-js";
import {isValidCell} from "h3-js";


//newly added please see change log  https://h3geo.org/docs/library/migration-3.x/functions/
//https://h3geo.org/docs/library/migration-3.x/functions
//this map code from https://github.com/matthiasfeist/what-the-h3index/blob/main/src/index.js

//geoToH3 updated to latLngToCell
//h3ToGeo updated to cellToLatLng
//polyfill updated to polygonToCells
//h3GetResolution updated to getResolution
//h3IsPentagon updated to isPentagon
//kring updated to gridDisk
//h3IsValid updated to isValidCell

//import {geoToH3} from "h3-js";
//import {h3ToGeo} from "h3-js";

//mapbox

//mapbox
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';
//placekey  https://github.com/Placekey/placekey-js
import {geoToPlacekey} from '@placekey/placekey';
import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';
//environment
//import { environment } from "../../../environments/environment";
//npm install geojson2h3
import geojson2h3 from 'geojson2h3';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
//H3
import  { h3SetToFeatureCollection } from 'geojson2h3';
import {cellToChildren} from "h3-js";



@Component({
  selector: 'app-accept-detail',
  templateUrl: './accept-detail.page.html',
  styleUrls: ['./accept-detail.page.scss'],
})
export class AcceptDetailPage implements OnInit {
  //@Input() dataArr: string;
  uid = this.authService.getUid(); 
  accepts: string[];
  acceptID:string
  loading = true;
  error: any;
  h3190:any;
  h310:any;

  latitude:any;
  longitude:any;

  constructor( private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    private activatedroute:ActivatedRoute,
    private router: Router,
    
    private navParams: NavParams) {
    console.log(JSON.stringify(navParams.get('dataArr')));
   }

  ngOnInit() {
    let acceptID = this.navParams.get('acceptID');
    let h310 = this.navParams.get('h310');
    let h313 = this.navParams.get('h313');

    let latitude = this.navParams.get('latitude');
    let longitude = this.navParams.get('longitude');



    console.log('acceptID',acceptID);
    console.log('h310',h310)
    console.log('h313',h313)


    let h310geo =cellToBoundary(h310,true);
    let h313geo =cellToBoundary(h313,true);
    console.log('h310geo',h310geo);

    let julie = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]]
     // coordinates: geo,
      coordinates: [h310geo],
     
      }
    };
    console.log('julie',julie);

    
    mapboxgl.accessToken = mapboxgl.accessToken;

    const map = new mapboxgl.Map({
          
      container: 'map',
      
      style: 'mapbox://styles/mapbox/streets-v12',

      //center: [-74.08097014877629,40.98385513546514],
      center: [longitude,latitude],
      zoom: 16,
      maxBounds: [[-170, -85], [170, 85]]
      });

     // map.addControl(
       // new MapboxGeocoder({
       // accessToken: mapboxgl.accessToken,
       // mapboxgl: mapboxgl
       // })
        //);

       // add navigation controls \(zoom buttons, pitch & rotate\)
      map.addControl(new mapboxgl.NavigationControl());
      //full screen control
      map.addControl(new mapboxgl.FullscreenControl({container: document.querySelector('body')}));

      map.on('load', function () {
        map.resize();
    });
       
      map.on('load', () => {
        map.addSource('tiles-geojson', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      
        map.addSource('tiles-centers-geojson', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        //newly added for Task Map

        
          map.addSource("tasks", {
          type: "geojson",
          ///////////data: hashtag
          data: julie
          });

        
             map.addLayer({
              'id': 'blue',
              'type': 'fill',
              'source': 'tasks', // reference the data source
              'layout': {},
              'paint': {
              'fill-color': '#0080ff', // blue color fill
              //'fill-color': 'red', // blue color fill

              'fill-opacity': .075
              }
              });
             
              // Add a black outline around the polygon.
              map.addLayer({
              'id': 'outline11',
              'type': 'line',
              'source': 'tasks', // reference the data source

              'layout': {},
              'paint': {
              'line-color': 'black',
              'line-width': 1.0
              }
              });
              

              //add map layers
              // Add a new layer to visualize the polygon.
            map.addLayer({
            'id': 'h39Jobs',
            'type': 'fill',
            'source': 'tasks', // reference the data source
            'layout': {},
            'paint': {
            'fill-color': '#0080ff', // blue color fill
            //'fill-color': 'red', // blue color fill

            'fill-opacity': 0.10
            }
            });
           
            
      //end of task map data
        map.addLayer({
          id: 'tiles',
          source: 'tiles-geojson',
          type: 'line',
          paint: {
            'line-color': '#000'
          }
        });
      
        map.addLayer({
          id: 'tiles-shade',
          source: 'tiles-geojson',
          type: 'fill',
          paint: {
            'fill-color': ['case', ['get', 'pentagon'], 'rgba(255,0,0,0.5)', 'rgba(0,0,0,0.0)']
          }
        });
      
        map.addLayer({
          id: 'tiles-centers',
          source: 'tiles-centers-geojson',
          type: 'symbol',
          layout: {
            'text-field': ['format', ['get', 'text'], { 'font-scale': .5 }],
            'text-offset': [0, -1],
          },
          paint: {
            'text-color': '#000',
            'text-color-transition': {
              duration: 0
            },
            'text-halo-color': ['case', ['get', 'highlight'], '#0f0', '#fff'],
            'text-halo-width': 1,
            'text-halo-blur': 1
          }
        });
      
       
      });

   

    this.apollo
      .watchQuery({
        query: gql`
           query Accept($acceptID:ID) {
          accepts(where:{acceptID:$acceptID}) 
        {
         acceptID
         task
         date_create
         homes{
           homeID
           
           email
           homeInfo{
             name_first
             name_last
           }
           homeAddress{
             street_number
             street
             city
             state
             zip
             
           }
         }
         acceptTask{
           task_description
           date_create
           taskItem{
             item
             image_path
           }
         }
         
         acceptCategory{
           category
         }
         acceptHome{
           email
           homeZip{
             town
           }

         }
   
    
     
  }
}
        `,
               //variables: {uid:this.uid} , 
           variables:    {acceptID: this.acceptID},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.accepts = result?.data?.accepts;
        this.loading = result.loading;
        this.error = result.error;
      });



   
  }
  closeModal():void{
    this.modalController.dismiss();
}
}
