import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HomeTypePageRoutingModule } from './home-type-routing.module';

import { HomeTypePage } from './home-type.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomeTypePageRoutingModule
  ],
  declarations: [HomeTypePage]
})
export class HomeTypePageModule {}
