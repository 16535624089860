import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ActivatedRoute} from '@angular/router'
import { Observable, BehaviorSubject } from 'rxjs';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';




const createHashtags = gql`

mutation createHashtags($itemID:ID,$supplierID:ID, $uid:ID ){
  createHashtags(input:
    {
      itemID: $itemID
      supplierID:$supplierID                                               
      uid:$uid                                               
			                         
      
      items:{connect:{
         where:{
           node:{itemID:$itemID}
         }
       }}
    	
     
      
       
       suppliers:{connect:{
         where:{
           node:{uid:$uid}
         }
       }}
       
    }
    
  ) 
  
  {
   hashtags{
    hashtagID
    date_create
    	supplierID
      uid
    suppliers{
      supplierID
      uid
      email
    }
     itemID
    items{
      itemID
      item
      image_path
    }
     
        }
        }
        
    
  }
 
    


`;
@Component({
  selector: 'app-items',
  templateUrl: './items.page.html',
  styleUrls: ['./items.page.scss'],
})
export class ItemsPage implements OnInit {
  uid = this.authService.getUid(); 
  subcategories: any[];
  suppliers: any[];
  todays: any[];

  loading = true;
  error: any;
  dataReturned:any;

  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    ) {}

  ngOnInit() {
    let subcategoryID = this.navParams.get('subcategoryID');
    console.log('subcategoryID', subcategoryID);


  
  this.apollo
      .watchQuery({
        query: gql`
            query Supplier($uid:ID) {
          suppliers(where:{uid:$uid}) 
        {
          supplierID
          uid
          name_first
          name_last
          supplierBusinessType

    email
    password
    username
    supplierItem{
      item
    }

    supplierSubcategory{
      subcategory
    }
    supplierCategory{
            category
          }
           supplierSubcategory{
            subcategory
          }
    supplier_info{
     
      company
      founded
      license
      tagline
      bio
      employee
    }
    territory{
      territory
    }
    supplierBase{
      baseID
      	business_type{
          businessTypeID
          business_type
          image_path
          image_path_header
        }
    }
  }
}
        `,
               //variables: {uid:this.uid} , 
           variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.suppliers = result?.data?.suppliers;
        this.loading = result.loading;
        this.error = result.error;
      });
      console.log('suppliers',this.suppliers)

      this.apollo
      .watchQuery({
        query: gql`
           query todays
  {
  todays{
  todayID
  
  month{
    monthID
    month
  }
  year{
    yearID
    year
  }
        day{
            day
            dayID
            date
            dayOfWeek
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
  }
  
  
        `,
               //variables: {uid:this.uid} , 
          // variables:    {uid: this.afAuth.auth.currentUser.uid},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.todays = result?.data?.todays;
        this.loading = result.loading;
        this.error = result.error;
      });
      console.log('todays',this.todays)

    this.apollo
    .watchQuery({
      query: gql`
        query subcategory($subcategoryID:ID) {
       subcategories(where:{subcategoryID:$subcategoryID})
      {
       subcategoryID
       subcategory
       image_path
        	item{
            itemID
            item
            image_path
          }
       
            
      }}
      
 
  

      `,
          //variables: {categoryID:this.activatedroute.snapshot.paramMap.get('categoryID')} ,
          variables: {subcategoryID:subcategoryID} ,
    })
    .valueChanges.subscribe((result: any) => {
      this.subcategories = result?.data?.subcategories;
      this.loading = result.loading;
      this.error = result.error;
    });
  }
  closeModal():void{
    this.modalController.dismiss();
}
AddHashtags(itemID, uid,supplierID ) {
                
  this.apollo.mutate({
  mutation:  createHashtags,
  variables: {
  
  itemID:itemID,
  uid:uid,
  supplierID:supplierID
  
  
  
  },

  //newly added to test update function

 



  }).subscribe(({ data }) => {
  //console.log('id: ', id);
  
  console.log('data',data);

  }, (error) => {
  console.log('there was an big ass error sending the query', error);
  });
}
showToast() {
  this.toastController.create({
    header: 'Congratulation',
    message: 'You Have Added a new Hashtag',
    position: 'middle',
    cssClass: 'my-custom-class',
    color: 'home',
    duration: 3000
    
  }).then((obj) => {
    obj.present();
  });
  }
}