import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

const updateSupplierAddresses = gql`
 
 mutation updateSupplierAddresses($supplieraddressID:ID, 
  													
														$address:String,
  													$suite: String,
  													$city: String,
  													$county: String,
  													$state: String,
  													$zip: String,
  													
  
														) {
 
    updateSupplierAddresses(where: {supplieraddressID: $supplieraddressID }
    														
   													update:{
															address:$address,
      												suite:$suite,
                              city:$city,
                              county:$county,
                              state:$state,
                              zip:$zip
                            }
       
    ){
        supplierAddresses{
         
          									supplieraddressID
         										address
          									suite		
          									city			
          									county
          									state
          									zip
         
          
        }
    
  }
}
  
`;

const deleteSupplierAddresses = gql`
 mutation deleteSupplierAddresses($supplieraddressID:ID){
  deleteSupplierAddresses (where:{supplieraddressID:$supplieraddressID}){
    nodesDeleted
   
  }
}

  
`;






@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.page.html',
  styleUrls: ['./address-modal.page.scss'],
})
export class AddressModalPage implements OnInit {

  uid = this.authService.getUid();
  latitude = this.navParams.get('latitude'); 
  addresses: string[];
  infos:string[];
  loading = true;
  error: any;
  isSubmitted = false;
  data:any;
  supplieraddressID:string;

  //info Form
addressForm: FormGroup;
 address: string;
 suite: string;
 city: string;
 county: string;
 state: string;
 zip: string;
 
  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

  ngOnInit() {
    let supplieraddressID = this.navParams.get('supplieraddressID');
    let company = this.navParams.get('company');
    
    console.log('supplieraddressID', supplieraddressID);
    

    this.addressForm = this.fb.group({
      address:      [this.address],
      suite:        [this.suite],
      city:         [this.city],
      county:       [this.county],
      state:        [this.state],
      zip:          [this.zip],
      
     });

  }
  closeModal():void{
    this.modalController.dismiss();
}
async updateSupplierAddresses(supplieraddressID) {
  this.isSubmitted = true;
  if (!this.addressForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.addressForm.value)
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: updateSupplierAddresses,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    address:this.addressForm.value.address,
    suite:this.addressForm.value.suite,
    city:this.addressForm.value.city,
    county:this.addressForm.value.county,
    state:this.addressForm.value.state,
    zip:this.addressForm.value.zip,
    supplieraddressID:this.supplieraddressID
   
   
   
    
    
     
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);    
    console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
}
async deleteSupplierAddresses() {

this.apollo.mutate({
  mutation: deleteSupplierAddresses,
  
  variables: {
  
  supplieraddressID:this.supplieraddressID
 
  
  
   
},
  
}).subscribe(({ data }) => {
  

   console.log(data);    
  console.log("Deleted!");


//this.navCtrl.navigateForward('/setup'); 
//this.router.navigate(['/app/setup/',this.id ]);
 
}, (error) => {
  console.log('there was an big ass error sending the query', error);
  
  //this.navCtrl.navigateRoot('/setup');
});
}
}
