import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql, QueryRef} from 'apollo-angular';
//services
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
//import { NavParams } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { ActivatedRoute} from '@angular/router';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import {H3Index} from "h3-js";
import {H3IndexInput} from "h3-js";
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//mport {h3} from "h3-js";
import {gridDisk} from "h3-js";


import {cellToBoundary} from "h3-js";
import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {isPentagon} from "h3-js";
import {getResolution} from "h3-js";
import {isValidCell} from "h3-js";


//newly added please see change log  https://h3geo.org/docs/library/migration-3.x/functions/
//https://h3geo.org/docs/library/migration-3.x/functions
//this map code from https://github.com/matthiasfeist/what-the-h3index/blob/main/src/index.js

//geoToH3 updated to latLngToCell
//h3ToGeo updated to cellToLatLng
//polyfill updated to polygonToCells
//h3GetResolution updated to getResolution
//h3IsPentagon updated to isPentagon
//kring updated to gridDisk
//h3IsValid updated to isValidCell

//import {geoToH3} from "h3-js";
//import {h3ToGeo} from "h3-js";

//mapbox

//mapbox
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';
//placekey  https://github.com/Placekey/placekey-js
import {geoToPlacekey} from '@placekey/placekey';
import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';
//environment
//import { environment } from "../../../environments/environment";
//npm install geojson2h3
import geojson2h3 from 'geojson2h3';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
//H3
import  { h3SetToFeatureCollection } from 'geojson2h3';
import {cellToChildren} from "h3-js";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';




let h3IndexToHighlight = '';
//const h3Input = document.getElementById('zoomToIndex');
const h3Input = '872a10545ffffff';




const deleteGeomaps =gql`
mutation deleteGeomaps($mapID:ID){
  deleteGeomaps (where:{ mapID:$mapID}){
    nodesDeleted
   
  }
}

`;

const createGeomaps = gql`
  mutation createGeomaps( $uid:ID,$latitude:Float, $longitude:Float, $h39:String, $h37:String,$h36:String, $h35:String, $h34:String, $h33:String, $h32:String, $h31:String, $h30:String, $h310:String, $h38:String){
  createGeomaps(input:
    {
      uid:$uid
      latitude:$latitude
      longitude:$longitude
      h39:$h39
      h37:$h37
      h36:$h36
      h35:$h35
      h34:$h34      
      h33:$h33      
      h32:$h32
      h31:$h31
      h30:$h30
      h310:$h310
      h38:$h38






      

    
      

      supplier:{connect:{
        where: {
          node:{uid:$uid}
        }
      }}

     

      section:{connect:{
        where: {
          node:{baseIndex:$h33}
        }
      }}

      metro:{connect:{
        where: {
          node:{baseIndex:$h34}
        }
      }}


      region:{connect:{
        where: {
          node:{baseIndex:$h30}
        }
      }}


       
    }
    
  ) 
  
  {
   geomaps{
    mapID
    uid
    latitude
    longitude
    h310
    h39
    h38
    h37
    h36
    h35
    h34
    h33
    h32
    h31
    h30
    supplier{
      supplierID
      email
    }

    region{
      regionID
      
      baseIndex
    }
    
    section{
      sectionID
      
      baseIndex
    }

    metro{
      metroID
      
      baseIndex
    }
      
      	
        }
        }
    
  }
 
    
    

  
`;

@Component({
  selector: 'app-mapper',
  templateUrl: './mapper.page.html',
  styleUrls: ['./mapper.page.scss'],
})

export class MapperPage implements OnInit {
  segmentModel = "instructions";
  uid = this.authService.getUid(); 
  h3Input:any;
  latitude:any;
  longitude:any;
  loading = true;
  error: any;
  suppliers: any[];
  geomaps: any[];
  dataReturned: string[];


  supplierID:any;
  h310:any[];
  h39:any[];
  h37:any[];
  h36:any[];
  h35:any[];
  h34:any[];
  h33:any[];
  h32:any[];
  h31:any[];
  h30:any[];

  geomap:any[];
  map:any[];
  mapID:any[];

  



  private mapbox: mapboxgl.Map;
  //private map = {};
  private directions = {};
  private container = {};
  private value: any;
  private values: any;

  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private locationService: LocationService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    //private navParams: NavParams,
    public toastController: ToastController,
    //private fb: FormBuilder,
    private activatedroute:ActivatedRoute,
   
     
    ) {

        mapboxgl.accessToken = environment.mapboxgl.accessToken;
      }
      ionViewDidEnter() {

        

        this.apollo
            .watchQuery({
              query: gql`
                 query suppliers($uid:ID) {
                  suppliers(where:{uid:$uid}) 
                {
                  email
                  supplierID
                  supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h310
                        h39
                        h37
                        h36
                        
                      }
                  supplierCategory{
                    categoryID
                    category
                  }
                  supplierSubcategory{
                    subcategoryID
                    subcategory
                  }
                  supplierBusinessType
                    subscriptionTasksBT{
                    task_description
                    task_detail
                    h39
                      item{
                      item
                  }
                      category{
                      category
                  }
                      subcategory{
                      subcategory
                   }
                      territory{
                      territory
            }
                      
          }
        }
         
        }
      
    
        
        
        `,
            //variables:    {uid: this.afAuth.auth.currentUser.uid},
                variables:    {uid: this.uid},
    
        })
            .valueChanges.subscribe((result: any) => {
              this.suppliers = result?.data?.suppliers;
              //this.tasks = result?.data?.suppliers.subscriptionTasksBT;
              this.loading = result.loading;
              this.error = result.error;
            });
          
        this. suppliers=this.suppliers
        let suppliers = this.suppliers;
        const maps =suppliers.map((supplierMap)=> supplierMap.supplierMap);
        const h391 =suppliers.map((supplierMap)=> supplierMap.supplierMap.map((geomap)=>geomap.h39));//let h39hex    = h39.map((h39)=>cellToBoundary(h39,true)); 
        const h361 =suppliers.map((supplierMap)=> supplierMap);//let h39hex    = h39.map((h39)=>cellToBoundary(h39,true)); 
        const h36hex =suppliers.map((supplierMap)=> supplierMap.supplierMap.map((h361)=>cellToBoundary(h361.h36,true)));
        const h36geo =suppliers.map((supplierMap)=> supplierMap.supplierMap.map((maps)=>cellToBoundary(maps.h36,true)));
        const geo6 = h36geo[0];
        console.log('geo6',geo6)

         const h371 =suppliers.map((supplierMap)=> supplierMap);//let h39hex    = h39.map((h39)=>cellToBoundary(h39,true)); 
         const h37hex =suppliers.map((supplierMap)=> supplierMap.supplierMap.map((h371)=>cellToBoundary(h371.h37,true)));
         const h37geo =suppliers.map((supplierMap)=> supplierMap.supplierMap.map((maps)=>cellToBoundary(maps.h37,true)));

         const geo7 = h37geo[0];
         

         const h39hex =suppliers.map((supplierMap)=> supplierMap.supplierMap.map((h391)=>cellToBoundary(h391.h39,true)));
         const h39geo =suppliers.map((supplierMap)=> supplierMap.supplierMap.map((maps)=>cellToBoundary(maps.h39,true)));
 
         const geo = h39geo[0];

       

        let julie = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]]
        
          coordinates: geo6,
         
          }
        };
        console.log('julie',julie);
        
    
        const map = new mapboxgl.Map({
          
          container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      //style: 'mapbox://styles/mapbox/light-v10',

    
      center: [-74.08097014877629,40.98385513546514],
      zoom: 11,
      maxBounds: [[-170, -85], [170, 85]]
          });
    
           // add navigation controls \(zoom buttons, pitch & rotate\)
           map.addControl(
            new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
            })
            );
        map.addControl(new mapboxgl.NavigationControl());

           
          map.on('load', () => {
            map.addSource('tiles-geojson', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: []
              }
            });
          
            map.addSource('tiles-centers-geojson', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: []
              }
            });
            
             //newly added for Task Map

            
             map.addSource("tasks", {
              type: "geojson",
              data: julie
              });

              map.addSource('tasks1', {
              'type': 'geojson',
              'data': {
                'type': 'Feature',
                'geometry': {
                  
                  'type': 'Polygon',
                 //' coordinates':[supplier.map((subscriptionTasksBT)=> subscriptionTasksBT.subscriptionTasksBT.map((h39)=>cellToBoundary(h39.h39,true)))],
                  //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]],
                  //'coordinates':julie,
                  'coordinates':geo,
                  //'coordinates':h39hex,
  
                 
                 // properties: {h39},
                 // "h39": {h39},
                }
        
               }
                 
                 });
                 map.addLayer({
                  'id': 'blue',
                  'type': 'fill',
                  'source': 'tasks', // reference the data source
                  'layout': {},
                  'paint': {
                 // 'fill-color': '#0080ff', // blue color fill
                  'fill-color': 'green', // blue color fill
                  'fill-opacity': 0.50
                  }
                  });
                 
                  // Add a black outline around the polygon.
                  map.addLayer({
                  'id': 'outline11',
                  'type': 'line',
                  'source': 'tasks', // reference the data source

                  'layout': {},
                  'paint': {
                   
                   
                  'line-color': '#0080ff',
                  'line-width': 11.0
                  }
                  });
                  console.log('h39hex',h39hex)

                  //add map layers
                  // Add a new layer to visualize the polygon.
                map.addLayer({
                'id': 'h39Jobs',
                'type': 'fill',
                'source': 'tasks', // reference the data source
                'layout': {},
                'paint': {
                'fill-color': '#0080ff', // blue color fill
                'fill-opacity': 0.60
                }
                });
               
                
          //end of task map data
          
            map.addLayer({
              id: 'tiles',
              source: 'tiles-geojson',
              type: 'line',
              paint: {
                'line-color': '#000'
              }
            });
          
            map.addLayer({
              id: 'tiles-shade',
              source: 'tiles-geojson',
              type: 'fill',
              paint: {
                'fill-color': ['case', ['get', 'pentagon'], 'rgba(255,0,0,0.5)', 'rgba(0,0,0,0.1)']
              }
            });
          
            map.addLayer({
              id: 'tiles-centers',
              source: 'tiles-centers-geojson',
              type: 'symbol',
              layout: {
                'text-field': ['format', ['get', 'text'], { 'font-scale': 1.2 }],
                'text-offset': [0, -1],
              },
              paint: {
                'text-color': '#000',
                'text-color-transition': {
                  duration: 0
                },
                'text-halo-color': ['case', ['get', 'highlight'], '#0f0', '#fff'],
                'text-halo-width': 1,
                'text-halo-blur': 1
              }
            });
          
            updateTiles();
          });
          
          map.on('moveend', updateTiles);
          map.on('click', (e) => {
            const h3Index = latLngToCell(e.lngLat.lat, e.lngLat.lng, mapZoomToH3Res(map.getZoom()))
            const latitude = e.lngLat.lat;
            const longitude = e.lngLat.lng;
            const placekey=geoToPlacekey( latitude,longitude);
           // const geo = placekeyToGeo(placekey);//[40.98415841509838, -74.07913882721337]
           const h30 =latLngToCell(latitude,longitude, 0);
           const h31 =latLngToCell(latitude,longitude, 1);
           const h32 =latLngToCell(latitude,longitude, 2);
           const h33 =latLngToCell(latitude,longitude, 3);
           const h34 =latLngToCell(latitude,longitude, 4);
           const h35 =latLngToCell(latitude,longitude, 5);
           const h36 =latLngToCell(latitude,longitude, 6);
            const h37 =latLngToCell(latitude,longitude, 7);
            const h38 =latLngToCell(latitude,longitude, 8);
            const h39 =latLngToCell(latitude,longitude, 9);
            const h310 =latLngToCell(latitude,longitude, 10);



           
           

            console.log('latitude',latitude);
            console.log('longitude',longitude);
            console.log('placekey',placekey);
            //console.log('geo',geo);

            console.log('h30',h30);
            console.log('h31',h31);
            console.log('h32',h32);
            console.log('h33',h33);
            console.log('h34',h34);
            console.log('h35',h35);
            console.log('h36',h36);
            console.log('h37',h37);
            console.log('h38',h38);
            console.log('h39',h39);
            console.log('h310',h310);
            console.log('h3Index',h3Index);
            console.log('suppliers',suppliers);
            console.log('maps',maps);
            console.log('h391',h391);
            console.log('h361',h361);
            console.log('h36hex',h36hex);
            console.log('h36geo',h36geo);
            console.log('h371',h371);
            console.log('h37hex',h37hex);
            console.log('h37geo',h37geo);

            console.log('h30hex',h39hex);
            console.log('h30geo',h39geo);
            console.log('geo',geo);
            console.log('geo7',geo7);


            this.latitude=latitude;
            this.longitude=longitude;
            this.suppliers=this.suppliers;
            console.log('suppliers',this.suppliers);
            
            this.toastController.create({
              header: 'Congratulation',
              message: 'You have successfully selected this area to expand your business',
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'dark',
              duration: 5000
              
            }).then((obj) => {
              obj.present();
              
             
            });
            
            this.apollo.mutate({
              mutation:  createGeomaps,
              variables: {
                latitude:latitude,
                longitude:longitude,
                uid:this.uid,
                h310:h310,
                h39:h39,
                h38:h38,
                h37:h37,
                h36:h36,
                h35:h35,
                h34:h34,
                h33:h33,
                h32:h32,
                h31:h31,
                h30:h30
                    },
              
              
            }).subscribe(({ data }) => {
              console.log('latitude: ', latitude);
              console.log('longitude: ', longitude);
              console.log('h39: ', h39);
              console.log('h37: ', h37);
              console.log('h36: ', h36);
              console.log('h35: ', h35);
              console.log('h34: ', h34);
              console.log('h33: ', h33);

              console.log('h32: ', h32);

              console.log('h31: ', h31);

              console.log('h30: ', h30);

            
            }, (error) => {
              console.log('there was an big ass error sending the query', error);

              
            });
        


            
          });
          
          
          
          
          function updateTiles() {
            var extentsGeom = getExtentsGeom();
            const mapZoom = map.getZoom()
            let h3res = mapZoomToH3Res(mapZoom)
          
            const h3indexes = extendH3IndexesByOne(polygonToCells(extentsGeom, h3res, true))
          
            map.getSource('tiles-geojson').setData(
              {
                type: 'FeatureCollection',
                features: h3indexes.map(getTileFeature)
              });
          
            map.getSource('tiles-centers-geojson').setData({
              type: 'FeatureCollection',
              features: h3indexes.map(getTileCenterFeature)
            });
          }
          
          function extendH3IndexesByOne(indexes) {
            const set = new Set()
            indexes.forEach(index => {
              gridDisk(index, 1).forEach(ringIndex => set.add(ringIndex))
            })
            return Array.from(set)
          }
          
          function getExtentsGeom() {
            var e = map.getBounds();
            return [
              e.getSouthWest().toArray(),
              e.getNorthWest().toArray(),
              e.getNorthEast().toArray(),
              e.getSouthEast().toArray(),
              e.getSouthWest().toArray()
            ];
          }
          
          function getTileFeature(h3index) {
            const feature = geojson2h3.h3ToFeature(h3index, {
              pentagon: isPentagon(h3index),
            })
            fixTransmeridian(feature)
            return feature
          }
          
          function getTileCenterFeature(h3index) {
            var center = cellToLatLng(h3index)
            return {
              type: 'Feature',
              properties: {
                text: h3index + '\nRes: ' + getResolution(h3index),
                highlight: h3index === h3IndexToHighlight
              },
              geometry: {
                type: 'Point',
                coordinates: [center[1], center[0]]
              }
            };
          }
          
          function mapZoomToH3Res(zoom) {
            return Math.max(0, Math.floor((zoom - 3) * 0.8))
          }
          function h3ResToMapZoom(res) {
            return Math.ceil((res + 3) * 1.2)
          }
          
          /**************************** 
           * the follwing functions are copied from
           * https://observablehq.com/@nrabinowitz/mapbox-utils#fixTransmeridian
           ****************************/
          
          function fixTransmeridianCoord(coord) {
            const lng = coord[0];
            coord[0] = lng < 0 ? lng + 360 : lng;
          }
          
          function fixTransmeridianLoop(loop) {
            let isTransmeridian = false;
            for (let i = 0; i < loop.length; i++) {
              // check for arcs > 180 degrees longitude, flagging as transmeridian
              if (Math.abs(loop[0][0] - loop[(i + 1) % loop.length][0]) > 180) {
                isTransmeridian = true;
                break;
              }
            }
            if (isTransmeridian) {
              loop.forEach(fixTransmeridianCoord);
            }
          }
          
          function fixTransmeridianPolygon(polygon) {
            polygon.forEach(fixTransmeridianLoop);
          }
          
          function fixTransmeridian(feature) {
            const { type } = feature;
            if (type === 'FeatureCollection') {
              feature.features.map(fixTransmeridian);
              return;
            }
            const { type: geometryType, coordinates } = feature.geometry;
            switch (geometryType) {
              case 'LineString':
                fixTransmeridianLoop(coordinates);
                return;
              case 'Polygon':
                fixTransmeridianPolygon(coordinates);
                return;
              case 'MultiPolygon':
                coordinates.forEach(fixTransmeridianPolygon);
                return;
              default:
                throw new Error(`Unknown geometry type: ${geometryType}`);
            }}


            
            
    
    
            //building Task Map Data
            let supplier = this.suppliers;
            console.log('supplierID',supplier);
        }
        
       
  ngOnInit() {
    let uid=this.uid;
    let bob = "bob";
  
   
    console.log('uid',uid);

    this.apollo
    .watchQuery({
      query: gql`
         query geoMaps($uid:ID) {
          geomaps(where:{uid:$uid}) {
      uid
      h36
      mapID
    }
  
    }
 



`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
        variables:    {uid: this.uid},

})
    .valueChanges.subscribe((result: any) => {
      this.geomaps = result?.data?.geomaps;
      this.loading = result.loading;
      this.error = result.error;
    });
    
  
    this.apollo
    .watchQuery({
      query: gql`
         query suppliers($uid:ID) {
          suppliers(where:{uid:$uid}) {
          email
          supplierID
          supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h310
                        h39
                        h37
                        h36
                       
                      }
          supplierCategory{
            categoryID
          }
          supplierSubcategory{
            subcategoryID
            subcategory
          }
          supplierBusinessType

            subscriptionTasksBT{
            task_description
            task_detail
            h39
            item_tag
              item{
              item
          }
              category{
              category
          }
              subcategory{
              subcategory
           }
              territory{
              territory
    }
  }
}
 
}




`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
        variables:    {uid: this.uid},

})
    .valueChanges.subscribe((result: any) => {
      this.suppliers = result?.data?.suppliers;
      this.loading = result.loading;
      this.error = result.error;
    });
    
    const supplier = this.suppliers;
            console.log('supplierID',supplier);
    
  }
  closeModal():void{
    this.modalController.dismiss();
    }



    deleteGeomaps(mapID ) {
                     
      this.apollo.mutate({
      mutation:  deleteGeomaps,
      variables: {
      mapID:mapID
      
      
      },
  
      
    
      }).subscribe(({ data }) => {
      //console.log('id: ', id);
      
      console.log('data',data);
  
      }, (error) => {
      console.log('there was an big ass error sending the query', error);
      });
    }
      showMapDelete() {
        this.toastController.create({
          header: 'Congratulation',
          message: 'You Have Now Deleted Your Map',
          position: 'middle',
          cssClass: 'my-custom-class',
          color: 'danger',
          duration: 3000
          
        }).then((obj) => {
          obj.present();
        });
    }
    
    showMapDeleteTest(event) {
      this.toastController.create({
        header: 'Congratulation',
        message: 'You Have Updated Your Data',
        position: 'middle',
        cssClass: 'my-custom-class',
        color: 'pro',
        duration: 3000
        
      }).then((obj) => {
        obj.present();
      });
      setTimeout(()=>{
        event.target.complete();
        
      })
  }
    segmentChanged(event){
      console.log(this.segmentModel);
      
      console.log(event);
    }
    showToast() {
      this.toastController.create({
        header: 'Congratulation',
        message: 'Your Selected Territory Has Been Saved',
        position: 'middle',
        cssClass: 'my-custom-class',
        color: 'dark',
        duration: 5000
        
      }).then((obj) => {
        obj.present();
        
       
      });
}
async openModalMapper(uid) {
  const modal = await this.modalController.create({
    component: MapperPage,
    id:"subscription",
    componentProps: {
      
      uid:this.uid,
   
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
}