import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

const updateSupplierPhones = gql`
 
 mutation updateSupplierPhones($supplierphoneID:ID, 
  													
														$phone_type:String,
  													$phone_number: String,
  												
  													
  
														) {
 
    updateSupplierPhones(where: {supplierphoneID: $supplierphoneID }
    														
   													update:{
															phone_type:$phone_type,
      												phone_number:$phone_number,
                              
                            }
       
    ){
        supplierPhones{
         
          									supplierphoneID
         										 phone_type
                             phone_number
         
          
        }
    
  }
}
  
`;

const deleteSupplierPhones = gql`
 mutation deleteSupplierPhones($supplierphoneID:ID){
  deleteSupplierPhones (where:{supplierphoneID:$supplierphoneID}){
    nodesDeleted
   
  }
}

  
`;






@Component({
  selector: 'app-phone-modal',
  templateUrl: './phone-modal.page.html',
  styleUrls: ['./phone-modal.page.scss'],
})
export class PhoneModalPage implements OnInit {
  uid = this.authService.getUid(); 
  phones: string[];
  
  loading = true;
  error: any;
  isSubmitted = false;
  data:any;

  //phone Form
  phoneForm: FormGroup;
  phone_type: string;
  phone_number:string
  supplierphoneID:string;
 

  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

    ngOnInit() {
      let supplierphoneID = this.navParams.get('supplierphoneID');
     
      console.log('supplierphoneID', supplierphoneID);
      
  
      this.phoneForm = this.fb.group({
        phone_type:      [this.phone_type],
        phone_number:    [this.phone_number]
        
        
        
       });
  
    }
    closeModal():void{
      this.modalController.dismiss();
  }
  async updateSupplierPhones(supplierphoneID) {
    this.isSubmitted = true;
    if (!this.phoneForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {
  
      
      console.log(this.phoneForm.value)
    }
  
    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation: updateSupplierPhones,
      
      variables: {
      uid: this.afAuth.auth.currentUser.uid,
      phone_type:this.phoneForm.value.phone_type,
      phone_number:this.phoneForm.value.phone_number,
     
      supplierphoneID:supplierphoneID
     
     
     
      
      
       
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);    
      console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    });
  }
  async deleteSupplierPhones() {
  
  this.apollo.mutate({
    mutation: deleteSupplierPhones,
    
    variables: {
    
      supplierphoneID:this.supplierphoneID
   
    
    
     
  },
    
  }).subscribe(({ data }) => {
    
  
     console.log(data);    
    console.log("Deleted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
  //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
  }
  }
  