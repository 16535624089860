import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MapperPageRoutingModule } from './mapper-routing.module';

import { MapperPage } from './mapper.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MapperPageRoutingModule
  ],
  declarations: [MapperPage]
})
export class MapperPageModule {}
