import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-profile-pro-list-detail',
  templateUrl: './profile-pro-list-detail.page.html',
  styleUrls: ['./profile-pro-list-detail.page.scss'],
})
export class ProfileProListDetailPage implements OnInit {

  uid = this.authService.getUid(); 
  suppliers: string[];
  loading = true;
  error: any;
  insurance:any;

  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
   
    private router: Router) {}

  ngOnInit() {

 //Main Search Query Suppliers
 this.apollo
 .watchQuery({
   query: gql`
      query suppliers($uid:ID)
     {
       suppliers(where:{uid:$uid}){
       uid
       supplierID
       email
       business_type_tag
       date_create
       count_connect
       count_invites
       count_accept

       supplierSupplierStaff{
        name_first
        name_last
       }
         
         supplierSupplierAddress{
           supplieraddressID
           address
           city
           county
           state
           zip
         }
         
        supplierSupplierPhone{
          supplierphoneID
           phone_type
           phone_number
         }
         
         supplierSupplierLicense{
           supplierlicenseID
           license_number
           county_license
           state_license
         }
         
         supplierSupplierInfo{
           supplierinfoID
           supplierID
           company
           founded
           employee
           tagline
           base_location
           bio
           url
           insurance
           license
         }
         
         supplierSupplierStaff{
           supplierstaffID
           name_first
           name_last
         }
         supplierMap{
      h34
      metro{
        metro
        metro_areas
      }
    }

}
}


   `,
          
      variables:    {uid: this.afAuth.auth.currentUser.uid},
      
 })
 .valueChanges.subscribe((result: any) => {
   this.suppliers = result?.data?.suppliers;
   this.loading = result.loading;
   this.error = result.error;
 });
 console.log('uid',this.uid);

  }
  closeModal():void{
    this.modalController.dismiss();

  }

}
