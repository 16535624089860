import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { LoadingController,ModalController } from '@ionic/angular';

import { TaskPage } from '../task/task.page';
import { MapquestPage } from '../mapquest/mapquest.page';
import { PassportPage } from '../passport/passport.page';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

const now = new Date();

const GET_SUPPLIERS = gql`
   query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              countTask
              countsupplierTasks
              supplierHashtags{
              itemID
              item_tag
          }
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
                      }
              supplierCategory{
                categoryID
              }
              supplierSubcategory{
                subcategoryID
                subcategory
              }
              supplierBusinessType

                subscriptionTasksBT{
                itemID
                date_create
                task_description
                task_detail
                taskDaysAgo
                h39
                item_tag
                connectSupplierID
                declineSupplierID
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }
`

const createHashtags = gql`

mutation createHashtags($itemID:ID,$supplierID:ID, $subcategoryID:ID, $businessTypeID:ID, $categoryID:ID,$dayID:ID,$weekID:ID,$uid:ID ){
  createHashtags(input:
    {
      itemID: $itemID
      supplierID:$supplierID                                                
			subcategoryID:$subcategoryID                   
			businessTypeID:$businessTypeID
			categoryID: $categoryID
			dayID: $dayID                           
			weekID:$weekID
      uid:$uid                                                
			                         
      
      items:{connect:{
         where:{
           node:{itemID:$itemID}
         }
       }}
    	
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{categoryID:$categoryID}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategoryID:$subcategoryID}
         }
       }}
       
       suppliers:{connect:{
         where:{
           node:{uid:$uid}
         }
       }}
       
    }
    
  ) 
  
  {
   hashtags{
    hashtagID
    date_create
    	supplierID
      uid
    suppliers{
      supplierID
      email
    }
     itemID
    items{
      itemID
      item
      image_path
    }
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      subcategory{
        subcategory
          }
        }
        }
        
    
  }
 
    


`;

const createConnects = gql`
mutation createConnects($task_description:String,$supplierID:ID, $availibility:String, $note:String, $date_task_create:Date, $category:String, $subcategory:String, $taskID:ID,$territoryID:ID,$dayID:ID,$weekID:ID$uid:ID ){
  createConnects(input:
    {
      task_description:$task_description
      supplierID:$supplierID
      availibility:$availibility
      note:$note
      date_task_create:$date_task_create
      taskID:$taskID
      dayID:$dayID
      weekID:$weekID
      uid:$uid
      
    	tasks:{connect:{
        where: {
          node:{taskID:$taskID}
        }
        edge: {
         task_description:$task_description
         category:$category
         subcategory:$subcategory 
          
        }
      }}
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{category:$category}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategory:$subcategory}
         }
       }}
       territory:{connect:{
         where:{
           node:{territoryID:$territoryID}
         }
       }}
       suppliers:{connect:{
         where:{
           node:{uid:$supplierID}
         }
       }}
       
    }
    
  ) 
  
  {
   connects{
    connectID
    	supplierID
      task
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      	tasks{
         task_description
          town
          category{
            category
            
          }
          territory{
            territory
          }
          subcategory{
            subcategory
          }
        }
        }
        
    
  }
 
    }
    


`;

const createDeclines = gql`
mutation createDeclines($task_description:String,$supplierID:ID, $availibility:String, $note:String, $date_task_create:Date, $category:String, $subcategory:String, $taskID:ID,$dayID:ID,$weekID:ID,$supplierUID:ID ){
  createDeclines(input:
    {
      task_description:$task_description
      supplierID:$supplierID
      availibility:$availibility
      note:$note
      date_task_create:$date_task_create
      taskID:$taskID
      dayID:$dayID
      weekID:$weekID
      supplierUID:$supplierUID
      
    	tasks:{connect:{
        where: {
          node:{taskID:$taskID}
        }
        edge: {
         task_description:$task_description
         category:$category
         subcategory:$subcategory 
          
        }
      }}
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{category:$category}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategory:$subcategory}
         }
       }}
       
       suppliers:{connect:{
         where:{
           node:{uid:$supplierUID}
         }
       }}
       
    }
    
  ) 
  
  {
   declines{
    declineID
    	supplierID
      supplierUID
      task
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      	tasks{
         task_description
          town
          category{
            category
            
          }
          territory{
            territory
          }
          subcategory{
            subcategory
          }
        }
        }
        
    
  }
 
    }
    


`;



@Component({
  selector: 'app-jobs1',
  templateUrl: './jobs1.page.html',
  styleUrls: ['./jobs1.page.scss'],
})
export class Jobs1Page implements OnInit {

  segmentModel = "list";

  uid = this.authService.getUid(); 
  suppliers: any[];
  Suppliers: any[];
  email: string[];
  loading = true;
  error: any;

 
  business_type: string[];
  Business_Type: string[];

  businesstype: string[];
  BusinessType: string[];

  territories: string[];
  Territories: string[];

  //task: string[];
  Task: string[];
  taskID: string[];
  supplierID: string[];
  zip: string[];
 

  TaskSupplier: string[];
  taskSupplier: string[];

  task:any;
  category: string[];
  connects: string[];

  declines: string[];

  Category: string[];

  subcategory: string[];
  Subcategory: string[];

  dataReturned: string[];
  categoryID:any;
  subcategoryID:any;
  itemID:any;
  businessTypeID:any;
  value:any;
  dayID:any;
  weekany;
  yearID:any;
  date = now;
  year:number= now.getFullYear();
  month:number= now.getMonth()+1;
  day:number= now.getDate();

  connectID:any;
  count_connect:any;
  todays:any;
  connectSupplierID:any;
  countsupplierTasks:any;
   
 
 

  constructor(private authService: AuthService, 
              private afAuth: AngularFireAuth,
              private navService: NavService,
              private apollo: Apollo,
              private modalController: ModalController,
              public toastController: ToastController,
              public alertController: AlertController,
              private loadingCtrl: LoadingController,

              private router: Router) {}
              

             

              ngOnInit() {
               

                let uid=this.uid;
                
                console.log('now',now);
                console.log('day',this.day);
                console.log('month',this.month);
                console.log('year',this.year);

                this.apollo
                .watchQuery({
                  query: gql`
                     query todays
             {
             todays{
              todayID
              month{
                monthID
                month
              }
              
                    day{
                        day
                        dayID
                        date
                        weekNumber
                        month
                        year
                    }
                      week{
                        weekID
                        week
                        banner_placement{
                          bannerUID
                          bannerName
                          
                        }
                        promos_placement{
                          promoUID
                          promotionName
                        }
                       
              }
              }
             }
             
                  `,
                         //variables: {uid:this.uid} , 
                    // variables:    {uid: this.afAuth.auth.currentUser.uid},
                     
                })
                .valueChanges.subscribe((result: any) => {
                  this.todays = result?.data?.todays;
                  this.loading = result.loading;
                  this.error = result.error;
                });
                console.log('todays',this.todays)
                


                this.apollo
        .watchQuery({
          query: gql`
             query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              uid
              countTask
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
                      }
              supplierMetro{
        metro
        metro_code
        metro_areas
        baseIndex
      }
              
              supplierCategory{
            categoryID
            category
          }
          supplierHashtags{
            itemID
            item_tag
          }
         
              supplierTasksh35{
                task_description
                taskID
                itemID
                categoryID
                subcategoryID
                businessTypeID
                homeType
                posted
                date_create
                taskDaysAgo
                item_tag
                city_tag
                connectSupplierID
                count_connect
                count_decline
                declineSupplierID
                declineSupplierUID
              
                
                taskConnects{
                  connectID
                  supplierID
                }
                  taskAccept{
                    acceptID
                    supplierUID
                  }
                
                category {
                  category
              }
      subcategory{
        subcategory
      }
                
              }

              supplierTasksh34{
                task_description
                taskID
                itemID
                categoryID
                subcategoryID
                businessTypeID
                homeType
                posted
                date_create
                taskDaysAgo
                item_tag
                city_tag
                connectSupplierID
                count_connect
                count_decline
                declineSupplierID
                declineSupplierUID
              
                
                taskConnects{
                  connectID
                  supplierID
                }
                  taskAccept{
                    acceptID
                    supplierUID
                  }
                
                category {
                  category
              }
      subcategory{
        subcategory
      }
                
              }
    }
     
    }
  

    
    
    `,
        variables:    {uid: this.afAuth.auth.currentUser.uid},
            //variables:    {uid: uid}

    })
        .valueChanges.subscribe((result: any) => {
          this.suppliers = result?.data?.suppliers;
          this.loading = result.loading;
          this.error = result.error;
        });
                  
      
      this.apollo
        .watchQuery({
          query: gql`
            query connects($supplierID:ID){
    connects(where:{supplierID:$supplierID},options: {sort:{date_create:DESC}}){
      date_create
      supplierID
      supplierUID
      uid
      connectTask{
        taskID
        task_description
        task_detail
        category_tag
        subcategory_tag
        item_tag
        city_tag
        is_active
        count_connect
        count_decline
        taskDaysAgo
      }
    }
  }
  

    
    
    `,
        variables:    {supplierID: this.afAuth.auth.currentUser.uid},
            //variables:    {supplierID: uid}

    })
        .valueChanges.subscribe((result: any) => {
          this.connects = result?.data?.connects;
          this.loading = result.loading;
          this.error = result.error;
        });            
           
              

        this.apollo
        .watchQuery({
          query: gql`
            query declines($supplierID:ID){
    declines(where:{supplierUID:$supplierID},options: {sort:{date_create:DESC}}){
      declineID
      date_create
      supplierID
      declineTask{
        taskID
        task_description
        date_create
        task_detail
        category_tag
        subcategory_tag
        item_tag
        city_tag
        is_active
        count_connect
        count_decline
        taskDaysAgo
      }
    }
  }
  

    
    
    `,
        variables:    {supplierID: this.afAuth.auth.currentUser.uid},
          //fixthisvariables:    {supplierID: uid}

    })
        .valueChanges.subscribe((result: any) => {
          this.declines = result?.data?.declines;
          this.loading = result.loading;
          this.error = result.error;
        });
        console.log('uid',uid)
      }
              AddHashtags(itemID, supplierID,subcategoryID, businessTypeID,categoryID,dayID,weekID,uid ) {
                
                    this.apollo.mutate({
                    mutation:  createHashtags,
                    variables: {
                    
                    itemID:itemID,
                    businessTypeID:businessTypeID,
                    categoryID:categoryID,
                    subcategoryID:subcategoryID,
                    supplierID:supplierID,
                    
                    dayID:dayID,
                    weekID:weekID,
                    uid:uid
                    
                    },
        
                    //newly added to test update function
        
                   
              
        
                  
                    }).subscribe(({ data }) => {
                    //console.log('id: ', id);
                    
                    console.log('data',data);
                
                    }, (error) => {
                    console.log('there was an big ass error sending the query', error);
                    });
               
              }


              segmentChanged(event){
                console.log(this.segmentModel);
                
                console.log(event);
              }


              GET_SUPPLIERS(uid){
                this.apollo
                .watchQuery({
                  query: gql`
                     query suppliers($uid:ID) {
                      suppliers(where:{uid:$uid}) {
                      email
                      supplierID
                      countTask
                      supplierCategory{
                    categoryID
                    category
                  }
                  supplierHashtags{
                    itemID
                    item_tag
                  }
                 
                      supplierTasksh34{
                        
                        task_description
                        task_detail
                        taskID
                        itemID
                        homeType
                        categoryID
                        subcategoryID
                        businessTypeID
                        posted
                        date_create
                        taskDaysAgo
                        item_tag
                        city_tag
                        connectSupplierID
                        count_connect
                        count_decline
                        declineSupplierID
                        declineSupplierUID
                      }
                        supplierTasksh35{
                        
                        task_description
                        task_detail
                        taskID
                        itemID
                        homeType
                        categoryID
                        subcategoryID
                        businessTypeID
                        posted
                        date_create
                        taskDaysAgo
                        item_tag
                        city_tag
                        connectSupplierID
                        count_connect
                        count_decline
                        declineSupplierID
                        declineSupplierUID
                        
                        category {
                          category
                      }
              subcategory{
                subcategory
              }
                        
                      }
            }
             
            }
          
        
            
            
            `,
                variables:    {uid: this.afAuth.auth.currentUser.uid},
                //variables:    {uid: uid}
        
            })
                .valueChanges.subscribe((result: any) => {
                  this.suppliers = result?.data?.suppliers;
                  this.loading = result.loading;
                  this.error = result.error;
                  setTimeout(()=>{
                    uid.target.complete();
                    
                  })
                
                });
                
              };





            
              AddConnectTasks(taskID,category,subcategory,territoryID,supplierID,uid,dayID,weekID ) {
                console.log("territories",territoryID);
                console.log("category",category);
                console.log("taskID",taskID);
            
              
                    this.apollo.mutate({
                    mutation:  createConnects,
                    variables: {
                    
                    taskID:taskID,
                    territoryID:territoryID,
                    category:category,
                    subcategory:subcategory,
                    supplierID:supplierID,
                    uid:uid,
                    dayID:dayID,
                    weekID:weekID
                    },
        
                    //newly added to test update function
        
                   refetchQueries:[
                     {
                       query:gql`
                      
                      query suppliers($uid:ID) {
                      suppliers(where:{uid:$uid}) 
                      {
                     supplierID
                     }
                    }
                      
                      
                      `
                      }]
              
        
                  
                    }).subscribe(({ data }) => {
                    //console.log('id: ', id);
                    console.log('territory: ', this.territories);
                    console.log('sub: ', this.subcategory);
                    console.log('data',data);
                
                    }, (error) => {
                    console.log('there was an big ass error sending the query', error);
                    });
                  }
                    AddDeclineTasks(taskID,category,subcategory,supplierID,uid,dayID,weekID,supplierUID ) {
                
                      console.log("category",category);
                      console.log("taskID",taskID);
                  
                    
                          this.apollo.mutate({
                          mutation:  createDeclines,
                          variables: {
                          
                          taskID:taskID,
                          
                          category:category,
                          subcategory:subcategory,
                          supplierID:supplierID,
                          uid:uid,
                          dayID:dayID,
                          weekID:weekID,
                          supplierUID:uid,
                          },
              
                          //newly added to test update function
              
                         refetchQueries:[
                           {
                             query:gql`
                            
                            query suppliers($uid:ID) {
                            suppliers(where:{uid:$uid}) 
                            {
                           supplierID
                           uid
                           countsupplierTasks
                           
                           }
                          }
                            
                            
                            `
                            }]
                    
              
                        
                          }).subscribe(({ data }) => {
                          //console.log('id: ', id);
                          console.log('territory: ', this.territories);
                          console.log('sub: ', this.subcategory);
                          console.log('data',data);
                      
                          }, (error) => {
                          console.log('there was an big ass error sending the query', error);
                          });
               
              }
              
             
          
             // gotoProfile() {
             // this.navService.gotoProfile();
            
             // }
              gotoJobs() {
               this.navService.gotoJobs();
              }
              gotoTask() {
                this.router.navigateByUrl('/task/:uuid');
              }
                async presentAlert() {
                  const alert = await this.alertController.create({
                    header: 'Thank You',
                    subHeader: 'Congratulation',
                    message: ' Please track progress on the Review Invites Tab Below',
                    buttons: ['OK'],
                  });
                
                  await alert.present();
                  let result = await alert.onDidDismiss();
                  console.log(result);

              
              }
              showToastConfirm() {
                this.toastController.create({
                  header: 'Congratulation',
                  message: 'You Have Now Shown Interest With The Homeowner',
                  position: 'middle',
                  cssClass: 'my-custom-class',
                  color: 'success',
                  duration: 3000
                  
                }).then((obj) => {
                  obj.present();
                });

              }
              showToastDecline() {
                this.toastController.create({
                  header: 'Congratulation',
                  message: 'You Have Now Declined the Task',
                  position: 'middle',
                  cssClass: 'my-custom-class',
                  color: 'danger',
                  duration: 3000
                  
                }).then((obj) => {
                  obj.present();
                });

              }
              showToastHashtag() {
                this.toastController.create({
                  header: 'Congratulation',
                  message: 'You Added the Hashtag for Seatch',
                  position: 'middle',
                  cssClass: 'my-custom-class',
                  color: 'home',
                  duration: 3000
                  
                }).then((obj) => {
                  obj.present();
                });
              
              }
              closeModal():void{
                this.modalController.dismiss();
                
            }

            
            updateSuppliers(uid) {
              
              this.apollo
              .watchQuery({
                query: gql`
                   query suppliers($uid:ID) {
      suppliers(where:{uid:$uid}) 
                  {
        username
         supplierID
          uid
          email
          countsupplierTasks
              supplierTasksh35{
                task_description
                task_detail
                taskID
                homeType
                posted
                taskDaysAgo
                item_tag
                city_tag
                connectSupplierID
                count_connect
              }
                supplierTasksh34{
                task_description
                task_detail
                taskID
                homeType
                posted
                taskDaysAgo
                item_tag
                city_tag
                connectSupplierID
                count_connect
                category {
        category
      }
      subcategory{
        subcategory
      }
              }
              }
                    }

        `,
              variables:    {uid: this.afAuth.auth.currentUser.uid},
        
        })
              .valueChanges.subscribe((result: any) => {
                this.suppliers = result?.data?.suppliers;
                this.loading = result.loading;
                this.error = result.error;
              });
              console.log('uid', uid);
            }


            async openModalPassport(uid,h39) {
              let loading = await this.loadingCtrl.create({
                message: 'Loading Map Data. if MAP Does Not Appear Refresh ...',
                duration: 2000,
              });
              await loading.present();
              const modal = await this.modalController.create({
                component: PassportPage,
                cssClass: 'my-modal-class',
                id:"mapquest",
                componentProps: {
                  
                  uid:uid,
                 h39:h39,
                 
                }
              });
            
              modal.onDidDismiss().then((dataReturned) => {
                if (dataReturned !== null) {
                  this.dataReturned = dataReturned.data;
                  //alert('Modal Sent Data :'+ dataReturned);
                }
              });
              
            
              return await modal.present();
            }
               async openModalMapquest(uid) {
    const modal = await this.modalController.create({
      component: MapquestPage,
      id:"mapquest",
      componentProps: {
        
        uid:this.uid,
     
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
    
  
    return await modal.present();
          }
          refresher(event) {
            this.toastController.create({
              header: 'Congratulation',
              message: 'You Have Updated Your Job List Data',
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'pro',
              duration: 3000
              
            }).then((obj) => {
              obj.present();
            });
            setTimeout(()=>{
              event.target.complete();
              
            }) 
          }


            ionViewDidEnter() {
              var supplier=this.suppliers;
       
              var uid = this.afAuth.auth.currentUser.uid
      
              console.log('uid',uid);
              

              
            }
        }   
          
