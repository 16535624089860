import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoadingController,ModalController } from '@ionic/angular';
import { TaskPage } from '../task/task.page';
import { MapquestPage } from '../mapquest/mapquest.page';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { JobsPage } from '../jobs/jobs.page';
import { Jobs1Page } from '../jobs1/jobs1.page';

import { ActivatedRoute } from '@angular/router';
import { NavParams } from '@ionic/angular';

//h3
import {H3Index} from "h3-js";
import {H3IndexInput} from "h3-js";
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";

//mapbox
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import { environment } from "../../../environments/environment";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';
//mport {h3} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";
import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {isPentagon} from "h3-js";
import {getResolution} from "h3-js";
import {isValidCell} from "h3-js";
import  { h3SetToFeatureCollection } from 'geojson2h3';
import {cellToChildren} from "h3-js";

import geojson2h3 from 'geojson2h3';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { HashtagPage } from '../hashtag/hashtag.page';
import { Hashtag1Page } from '../hashtag1/hashtag1.page';

//H3
//import  { h3SetToFeatureCollection } from 'geojson2h3';
//import {cellToChildren} from "h3-js";
mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';


const now = new Date();

const GET_SUPPLIERS = gql`
   query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              countsupplierTasks
                supplierMetro{
                  metro
                  metro_code
                  metro_areas
                  baseIndex
      }
      supplierCounty{
            countyID
            county
            county_code
            county_areas
            baseIndex
    }
              supplierHashtags{
              itemID
              item_tag
          }
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
                      }
              supplierCategory{
                categoryID
              }
              supplierSubcategory{
                subcategoryID
                subcategory
              }
              supplierBusinessType

                subscriptionTasksBT{
                itemID
                date_create
                task_description
                task_detail
                taskDaysAgo
                h39
                item_tag
                connectSupplierID
                declineSupplierID
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }
`

@Component({
  selector: 'app-thehashlist',
  templateUrl: './thehashlist.page.html',
  styleUrls: ['./thehashlist.page.scss'],
})
export class ThehashlistPage implements OnInit {

  uid = this.authService.getUid(); 
  suppliers: any[];
  hashtags: any[];


  loading = true;
  error: any;
  dataReturned: string[];
  h34:any;
  h35:any;
  h36:any;
  latitude:any;
  longitude:any;
result1:any;


  constructor(private authService: AuthService,
    private navParams: NavParams,

    private route: ActivatedRoute, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private loadingCtrl: LoadingController,
    private router: Router) {}

  ngOnInit() {
    let h34 = this.navParams.get('h34');
    let h35 = this.navParams.get('h35');
    let h36 = this.navParams.get('h36');
    let latitude = this.navParams.get('latitude');
    let longitude = this.navParams.get('longitude');



    console.log('h34',this.h34);
    console.log('h35',this.h35);
    console.log('h36',this.h36);
    console.log('latitude',this.latitude);
    console.log('longitude',this.longitude);



    
    let h35geo =cellToBoundary(h35,true);
    console.log('h35geo',h35geo);
    
    

    let h36geo =cellToBoundary(h36,true);
    console.log('h36geo',h36geo);

    const h34geo =cellToBoundary(h34,true);
    console.log('h34geo',h34geo);

    let geo6 = h36geo[0];
    console.log('geo6',geo6);

    let geo5 = h35geo[0];
    console.log('geo5',geo5);


    let geo4 = h34geo[0];
    console.log('geo4',geo4);

    let julie = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]]
     // coordinates: geo,
      coordinates: [h35geo],
     
      }
    };
    console.log('julie',julie);

    
    mapboxgl.accessToken = mapboxgl.accessToken;

    const map = new mapboxgl.Map({
          
      container: 'map',
      
      style: 'mapbox://styles/mapbox/streets-v12',

      //center: [-74.08097014877629,40.98385513546514],
      center: [longitude,latitude],
      zoom: 9.5,
      maxBounds: [[-170, -85], [170, 85]]
      });

     // map.addControl(
       // new MapboxGeocoder({
       // accessToken: mapboxgl.accessToken,
       // mapboxgl: mapboxgl
       // })
        //);

       // add navigation controls \(zoom buttons, pitch & rotate\)
      map.addControl(new mapboxgl.NavigationControl());
      //full screen control
      map.addControl(new mapboxgl.FullscreenControl({container: document.querySelector('body')}));

      map.on('load', function () {
        map.resize();
    });
       
      map.on('load', () => {
        map.addSource('tiles-geojson', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      
        map.addSource('tiles-centers-geojson', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        //newly added for Task Map

        
          map.addSource("tasks", {
          type: "geojson",
          ///////////data: hashtag
          data: julie
          });

        
             map.addLayer({
              'id': 'blue',
              'type': 'fill',
              'source': 'tasks', // reference the data source
              'layout': {},
              'paint': {
              'fill-color': '#0080ff', // blue color fill
              //'fill-color': 'red', // blue color fill

              'fill-opacity': .075
              }
              });
             
              // Add a black outline around the polygon.
              map.addLayer({
              'id': 'outline11',
              'type': 'line',
              'source': 'tasks', // reference the data source

              'layout': {},
              'paint': {
              'line-color': 'black',
              'line-width': 1.0
              }
              });
              

              //add map layers
              // Add a new layer to visualize the polygon.
            map.addLayer({
            'id': 'h39Jobs',
            'type': 'fill',
            'source': 'tasks', // reference the data source
            'layout': {},
            'paint': {
            //'fill-color': '#0080ff', // blue color fill
            'fill-color': 'red', // blue color fill

            'fill-opacity': 0.10
            }
            });
           
            
      //end of task map data
        map.addLayer({
          id: 'tiles',
          source: 'tiles-geojson',
          type: 'line',
          paint: {
            'line-color': '#000'
          }
        });
      
        map.addLayer({
          id: 'tiles-shade',
          source: 'tiles-geojson',
          type: 'fill',
          paint: {
            'fill-color': ['case', ['get', 'pentagon'], 'rgba(255,0,0,0.5)', 'rgba(0,0,0,0.0)']
          }
        });
      
        map.addLayer({
          id: 'tiles-centers',
          source: 'tiles-centers-geojson',
          type: 'symbol',
          layout: {
            'text-field': ['format', ['get', 'text'], { 'font-scale': .5 }],
            'text-offset': [0, -1],
          },
          paint: {
            'text-color': '#000',
            'text-color-transition': {
              duration: 0
            },
            'text-halo-color': ['case', ['get', 'highlight'], '#0f0', '#fff'],
            'text-halo-width': 1,
            'text-halo-blur': 1
          }
        });
      
       
      });


      let bob = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          //coordinates: [[[-74.08097014877629,40.98385513546514],[-74.08089720802191,40.982089321371404],[-74.0787121734741,40.9812659441373],[-74.07659999259364,40.982208313354256],[-74.07667277137409,40.983974129895266],[-74.07885789300946,40.98479757477488],[-74.08097014877629,40.98385513546514]]]
       // coordinates: geo,
        coordinates: [h34geo],
       
        }
      };
      console.log('bob',bob);
  
      const map1 = new mapboxgl.Map({
          
        container: 'map1',
        
        style: 'mapbox://styles/mapbox/streets-v12',
  
        //center: [-74.08097014877629,40.98385513546514],
        center: [longitude,latitude],
        zoom: 8,
        maxBounds: [[-170, -85], [170, 85]]
        });
  
       // map.addControl(
         // new MapboxGeocoder({
         // accessToken: mapboxgl.accessToken,
         // mapboxgl: mapboxgl
         // })
          //);
  
         // add navigation controls \(zoom buttons, pitch & rotate\)
        map1.addControl(new mapboxgl.NavigationControl());
        //full screen control
        map1.addControl(new mapboxgl.FullscreenControl({container: document.querySelector('body')}));
  
        map1.on('load', function () {
          map1.resize();
      });
         
        map1.on('load', () => {
          map1.addSource('tiles-geojson', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: []
            }
          });
        
          map1.addSource('tiles-centers-geojson', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: []
            }
          });
  
          //newly added for Task Map
  
          
            map1.addSource("tasks", {
            type: "geojson",
            ///////////data: hashtag
            data: bob
            });
  
          
               map1.addLayer({
                'id': 'blue',
                'type': 'fill',
                'source': 'tasks', // reference the data source
                'layout': {},
                'paint': {
                //'fill-color': '#0080ff', // blue color fill
                'fill-color': 'red', // blue color fill
  
                'fill-opacity': 0.10
                }
                });
               
                // Add a black outline around the polygon.
                map1.addLayer({
                'id': 'outline11',
                'type': 'line',
                'source': 'tasks', // reference the data source
  
                'layout': {},
                'paint': {
                'line-color': 'black',
                'line-width': 1.0
                }
                });
                
  
                //add map layers
                // Add a new layer to visualize the polygon.
              map1.addLayer({
              'id': 'h39Jobs',
              'type': 'fill',
              'source': 'tasks', // reference the data source
              'layout': {},
              'paint': {
              //'fill-color': '#0080ff', // blue color fill
              'fill-color': 'red', // blue color fill
  
              'fill-opacity': 0.10
              }
              });
             
              
        //end of task map data
          map1.addLayer({
            id: 'tiles',
            source: 'tiles-geojson',
            type: 'line',
            paint: {
              //'line-color': '#000'
              'line-color': 'black'
            }
          });
        
          map1.addLayer({
            id: 'tiles-shade',
            source: 'tiles-geojson',
            type: 'fill',
            paint: {
              'fill-color': ['case', ['get', 'pentagon'], 'rgba(255,0,0,0.5)', 'rgba(0,0,0,0.0)']
            }
          });
        
          map1.addLayer({
            id: 'tiles-centers',
            source: 'tiles-centers-geojson',
            type: 'symbol',
            layout: {
              'text-field': ['format', ['get', 'text'], { 'font-scale': .5 }],
              'text-offset': [0, -1],
            },
            paint: {
              'text-color': '#000',
              'text-color-transition': {
                duration: 0
              },
              'text-halo-color': ['case', ['get', 'highlight'], '#0f0', '#fff'],
              'text-halo-width': 1,
              'text-halo-blur': 1
            }
          });
        
         
        });
      
      

  this.apollo
  .watchQuery({
    query: gql`
       query suppliers($uid:ID) {
        suppliers(where:{uid:$uid}) {
        email
        supplierID
        uid
        supplierMetro{
            metroID
            metro
            metro_code
            metro_areas
            baseIndex
    }
        supplierCounty{
            countyID
            county
            county_code
            county_areas
            baseIndex
    }
        
        supplierCategory{
      categoryID
      category
    }
    supplierHashtags{
      itemID
      item_tag
    }
   
        supplierTasksh35{
          task_description
          taskID
          itemID
          categoryID
          subcategoryID
          businessTypeID
          homeType
          posted
          date_create
          taskDaysAgo
          item_tag
          city_tag
          connectSupplierID
          count_connect
          count_decline
          declineSupplierID
          declineSupplierUID
        
          
          taskConnects{
            connectID
            supplierID
          }
            taskAccept{
              acceptID
              supplierUID
            }
          
          category {
            category
        }
subcategory{
  subcategory
}
          
        }

        supplierTasksh34{
          task_description
          taskID
          itemID
          categoryID
          subcategoryID
          businessTypeID
          homeType
          posted
          date_create
          taskDaysAgo
          item_tag
          city_tag
          connectSupplierID
          count_connect
          count_decline
          declineSupplierID
          declineSupplierUID
        
          
          taskConnects{
            connectID
            supplierID
          }
            taskAccept{
              acceptID
              supplierUID
            }
          
          category {
            category
        }
subcategory{
  subcategory
}
          
        }
}

}




`,
  variables:    {uid: this.afAuth.auth.currentUser.uid},
      //variables:    {uid: uid}

})
  .valueChanges.subscribe((result: any) => {
    this.suppliers = result?.data?.suppliers;
    this.loading = result.loading;
    this.error = result.error;
  });
}
  closeModal():void{
    this.modalController.dismiss();
}
async openModalHashh35(uid) {
  let loading = await this.loadingCtrl.create({
    message: 'Loading Task Data ...',
    duration: 1000,
  });
  await loading.present();
  const modal = await this.modalController.create({
    component: HashtagPage,
    cssClass: 'my-modal-class',
    id:"hashtag",
    componentProps: {
      
      uid:this.uid,
      
      //zipID:zipID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}


async openModalHashh34(uid) {
  let loading = await this.loadingCtrl.create({
    message: 'Loading Task Data ...',
    duration: 1000,
  });
  await loading.present();
  const modal = await this.modalController.create({
    component: Hashtag1Page,
    cssClass: 'my-modal-class',
    id:"hashtag1",
    componentProps: {
      
      uid:this.uid,
      
      //zipID:zipID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
async openModalJobsh34(uid) {
  let loading = await this.loadingCtrl.create({
    message: 'Loading Task Data ...',
    duration: 1000,
  });
  await loading.present();
  const modal = await this.modalController.create({
    component: Jobs1Page,
    cssClass: 'my-modal-class',
    id:"jobs1",
    componentProps: {
      
      uid:this.uid,
      
      //zipID:zipID
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}

}