import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CheckoutPage } from '../checkout/checkout.page';
import { CheckoutpaidPage } from '../checkoutpaid/checkoutpaid.page';

import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';


//import { NavParams } from '@ionic/angular';


@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.page.html',
  styleUrls: ['./cart-modal.page.scss'],
})
export class CartModalPage implements OnInit {

  uid = this.authService.getUid(); 
  cart = [];
  aarbos:any[];
  suppliers:any[];

  //newly added
  fee:any;
  amount:any;
  item:any;
  description:any;
  itemID:any;
  cartItems:any[];
  inviteID:any;
  zip:any;
  dayID:any;
  weekID:any;
  id:any;
  loading = true;
  error: any;
  //suppliers:any[];
  monthID:any;


  constructor(private modalController: ModalController,private cartService: CartService, private modalCtrl: ModalController, private router: Router,private apollo: Apollo,private authService: AuthService, 
    private afAuth: AngularFireAuth) { }

  ngOnInit() {
    //this.zip = this.navParams.get('zip');
    this.dayID=this.dayID;
    this.weekID=this.weekID;
    this.monthID=this.monthID;

    this.cartService.getCart().subscribe(res => {
      this.cart = res;
    })
   console.log('cartInvite',this.cart);
   console.log('dayID',this.dayID);
   console.log('weekID',this.weekID);
   console.log('monthID',this.monthID);
   console.log('id',this.id);


   console.log('zip',this.zip)

   this.apollo
   .watchQuery({
     query: gql`
        query Suppliers($uid:ID) {
        suppliers(where:{uid:$uid}) 
       {
        uid
        supplierToolbelt{
  				free
          toolbelt
          toolbelt_points
				}  
   
       }}
   
   
   `,
   variables:    {uid: this.uid},
   //variables:    {uid: this.afAuth.auth.currentUser.uid},
   
   })
   .valueChanges.subscribe((result: any) => {
     this.suppliers = result?.data?.suppliers;
     this.loading = result.loading;
     this.error = result.error;
   });
  
   
  
  this.apollo
  .watchQuery({
    query: gql`
       query aarbos
    {
        aarbos{
        mode
    
    }
  }

    `,
           //variables: {uid:this.uid} , 
      // variables:    {uid: this.afAuth.auth.currentUser.uid},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.aarbos = result?.data?.aarbos;
    this.loading = result.loading;
    this.error = result.error;
  });
}
  decreaseCartItem(invites) {
    this.cartService.decreaseProduct(invites);
  }
 
  increaseCartItem(invites) {
    this.cartService.addProduct(invites);
  }
 
  removeCartItem(i) {
    this.cartService.removeProduct(i);
  }

  getTotal() {
    //return this.cart.reduce((i, j) => i + j.fee * j.amount, 0);
    return this.cart.reduce((i, j) => i + j.inviteFee[0].amount * 1, 0);
  }
  getInviteID(i) {
    this.cartService.getInviteID(i);
  }

  close() {
    this.modalCtrl.dismiss();
  }
  checkout(dayID,weekID) {
   // this.modalCtrl.dismiss();
   this.modalCtrl.dismiss(null, null, "invites");
   this.modalCtrl.dismiss(null, null, "invitedetail");
    this.router.navigateByUrl('/buyer/checkout');
    this.modalCtrl.dismiss();
  }
  //use to bypass stripe
  
  async openCart(dayID,weekID,monthID) {
    const modal = await this.modalController.create({
      component: CheckoutPage,
      cssClass: 'my-modal-class',
      componentProps: {
        dayID:dayID,
        weekID:weekID,
        monthID:monthID
       
     
      }
    });
    modal.present();
    console.log('dayID',this.dayID);
    console.log('weekID',this.weekID);
    console.log('monthID',this.monthID);
    

    
  }

  async openCartPaid(dayID,weekID) {
    const modal = await this.modalController.create({
      component: CheckoutpaidPage,
      cssClass: 'my-modal-class',
      componentProps: {
        dayID:dayID,
        weekID:weekID,
       
     
      }
    });
    modal.present();
    console.log('dayID',this.dayID);
    console.log('weekID',this.weekID);
    

    
  }
  closeModal():void{
    this.modalController.dismiss();
}
}
