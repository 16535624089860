import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//placekey
//import {geoToPlacekey} from '@placekey/placekey';
//H3
//import {gridDisk} from "h3-js";

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
const center = {
  //lng: -74.07936500000001,
  //lat: 40.98513233333333

  lng: -74.07936500000001,
  lat: 40.98513233333333
};

//const placekey = geoToPlacekey(center.lat, center.lng);
//const disk = gridDisk('872a10545ffffff', 1);

const now1 = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
//const now1 = new Date({timeZone: "America/New_York"})
const now = new Date();


const createTasks = gql`
  mutation createTasks( $date:Date,$createdAt:DateTime,$itemID:ID,$business_type:String,$territory:String,$task_description:String,$task_detail:String, $task_why_now: String, $categoryID:ID, $homeUID:ID,$town:String,$category:String, $subcategory:String, $businessTypeID:ID, $subcategoryID:ID, $is_emergency:Boolean, $make_model:String, $years_old: String, $locationID:ID,$h39Index:String,$dayID:ID, $weekID:ID, $monthID:ID){
  createTasks(input:
    {
      homeUID:$homeUID
      date:$date
      task_description:$task_description
      task_detail:$task_detail
      task_why_now:$task_why_now
      businessTypeID:$businessTypeID
      categoryID:$categoryID
      subcategoryID:$subcategoryID
     
      is_active:true
      town:$town
      is_emergency:$is_emergency
      make_model:$make_model
      years_old:$years_old
      createdAt:$createdAt
      itemID:$itemID
      h39Index:$h39Index
      locationID:$locationID
      dayID:$dayID
      weekID:$weekID
      monthID:$monthID
     

      days:{connect:{
        where: {
          node:{dayID:$dayID}
        }
      }}

      weeks:{connect:{
        where: {
          node:{weekID:$weekID}
        }
      }}
      months:{connect:{
        where: {
          node:{monthID:$monthID}
        }
      }}

      

      locations:{connect:{
        where: {
          node:{locationID:$locationID}
        }
      }}
      

      item:{connect:{
        where: {
          node:{itemID:$itemID}
        }
      }}
      business_type:{connect:{
        where: {
          node:{businessTypeID:$businessTypeID}
        }
          edge: {
            territory:$territory
          category:$category
          subcategory:$subcategory
          business_type:$business_type
          
           task_description:$task_description
        
          town:$town
          }
      }}
      
      
      homes:{connect:{
        where: {
          node:{uid:$homeUID}
        }
        
        edge: {
         
          category:$category
          subcategory:$subcategory
          business_type:$business_type
          
          
         
        }
        
      }}
      
      
      
      category:{connect:{
        where:  {
          node:{categoryID:$categoryID}
        }
        edge:{
          territory:$territory
          category:$category
          business_type:$business_type
          
          task_description:$task_description
          
        }
      }}
      
      
      
      subcategory:{connect:{
        where:  {
          node:{subcategoryID:$subcategoryID}
        }
        edge:{
          
          subcategory:$subcategory
          category:$category
          business_type:$business_type
          territory:$territory
          
          task_description:$task_description
          
          
          
        }
      }}
      
      
      
      
      
       
    }
    
    
    
  ) 
  
  {
   tasks{
     taskID
     task_description
     task_detail
      task_why_now
      date
    businessTypeID
    categoryID
    dayID
    weekID
    monthID
    subcategoryID
    is_active
    is_emergency
    make_model
    years_old
    createdAt
    h39
    h39Index
    locationID
    home_address{
      homeAddressID
      street_number
      street
      homeAddressLocation{
          locationID
          h39
          
          
          locationAddress{
            homeAddressID
            street_number
            street
            city
          }
        }
    }
    days{
      dayID
      date
    }
    weeks{
      weekID
    }
    months{
      monthID
    }
    
    locations{
      locationID
      h39
      h36
    }
    actionTask{
      action
    }
    actionID
    item{
      itemID
      item
    }
   territory{
      territory
    }
    business_type{
      business_type
      businessTypeID
    }
    subcategory{
      subcategory
      subcategoryID
    }
      
        homes{
          homeID
        
          username
          password
        }
        }
    
  }
 
    }
    

  
`;


const createTodos = gql`
  mutation createTodos( $date:Date,$createdAt:DateTime,$itemID:ID,$business_type:String,$territory:String,$task_description:String,$task_detail:String, $task_why_now: String, $categoryID:ID, $homeUID:ID,$town:String,$category:String, $subcategory:String, $businessTypeID:ID, $subcategoryID:ID,$territoryID:ID, $is_emergency:Boolean, $make_model:String, $years_old: String,$zipID:ID, $locationID:ID,$h39Index:String,$dayID:ID, $weekID:ID){
  createTodos(input:
    {
      homeUID:$homeUID
      date:$date
     	task_description:$task_description
      task_detail:$task_detail
      task_why_now:$task_why_now
      businessTypeID:$businessTypeID
      categoryID:$categoryID
      subcategoryID:$subcategoryID
      territoryID:$territoryID
      is_active:true
      town:$town
      is_emergency:$is_emergency
      make_model:$make_model
      years_old:$years_old
      createdAt:$createdAt
      itemID:$itemID
      h39Index:$h39Index
      locationID:$locationID
      dayID:$dayID
      weekID:$weekID
     

      days:{connect:{
        where: {
          node:{dayID:$dayID}
        }
      }}

      weeks:{connect:{
        where: {
          node:{weekID:$weekID}
        }
      }}

      

      locations:{connect:{
        where: {
          node:{locationID:$locationID}
        }
      }}
      

      item:{connect:{
        where: {
          node:{itemID:$itemID}
        }
      }}
      business_type:{connect:{
        where: {
          node:{businessTypeID:$businessTypeID}
        }
        	edge: {
            territory:$territory
          category:$category
          subcategory:$subcategory
          business_type:$business_type
          
           task_description:$task_description
        
          town:$town
          }
      }}
      
      
      homes:{connect:{
        where: {
          node:{uid:$homeUID}
        }
        
        edge: {
         
          category:$category
          subcategory:$subcategory
          business_type:$business_type
          
          
         
        }
        
      }}
      
      territory:{connect: {
        where: {
          node:{territoryID:$territoryID}
        }
        edge:{
          territory:$territory
          category:$category
          subcategory:$subcategory
          business_type:$business_type
          
           task_description:$task_description
        
          town:$town
        }
      }}
      
      category:{connect:{
        where:	{
          node:{categoryID:$categoryID}
        }
        edge:{
          territory:$territory
          category:$category
          business_type:$business_type
          
          task_description:$task_description
          
        }
      }}
      
      
      
      subcategory:{connect:{
        where:	{
          node:{subcategoryID:$subcategoryID}
        }
        edge:{
          
					subcategory:$subcategory
          category:$category
          business_type:$business_type
          territory:$territory
          
          task_description:$task_description
          
          
          
        }
      }}
      
    	
      
      
      zip:{connect:{
        where:	{
          node:{zipID:$zipID}
        }
      }}
       
    }
    
    
    
  ) 
  
  {
    todos{
      todoUID
     task_description
   	 task_detail
      task_why_now
      date_task
    businessTypeID
    categoryID
    dayID
    weekID
    subcategoryID
    is_active
    is_emergency
    make_model
    years_old
    date_create
    h39
    h39Index
    locationID
    home_address{
      homeAddressID
      street_number
      street
      homeAddressLocation{
          locationID
          h39
          
          
          locationAddress{
            homeAddressID
            street_number
            street
            city
          }
        }
    }
    days{
      dayID
      date
    }
    weeks{
      weekID
    }
    
    locations{
      locationID
      h39
      h36
    }
    actionTask{
      action
    }
    actionID
    item{
      itemID
      item
    }
   territory{
      territory
    }
    business_type{
      business_type
      businessTypeID
    }
    subcategory{
      subcategory
      subcategoryID
    }
      
      	homes{
          homeID
        
          username
          password
        }
        }
    
  }
 
    }
    

  
`;

@Component({
  selector: 'app-rfq-home',
  templateUrl: './rfq-home.page.html',
  styleUrls: ['./rfq-home.page.scss'],
})
export class RfqHomePage implements OnInit {

  ticket = { "adult": 15, "child": 10, "adultcount":0 , "childcount":0};
  calculate() : any {
       return this.ticket.adult * this.ticket.adultcount + this.ticket.child * this.ticket.childcount;
  }
  //@ViewChild('radioGroup') radioGroup: IonRadioGroup
  
  //Get value on ionChange on IonRadioGroup
  selectedRadioGroup:any;
  //Get value on ionSelect on IonRadio item
  selectedRadioItem:any;
  //uid = this.authService.getUid(); 
   //use for demo1 user
   uid = "VO74uw2G04dtfBL0ooDQ9PFbGee2"
  loading = true;
  error: any;
  isSubmitted = false;
  homes:any[];
  categories:any[];
  subcategories:any[];
  businessTypes:any[];
  actions:any[];
  actionTasks:any[];
  dataReturned: any[];
  registerForm: FormGroup;
  categoryID: any;
  subcategoryID: any;
  zip:any;
  zipID:any;
  territory:any;
  territoryID:any;
  homeID: any;
  itemID: any;
  homeUID: any;
  actionID: any;
  businessTypeID: any;
  value_selected:any;
  action:any;
  createdAt:any;
  date:any;
  now:any;
  now1:any;
  homeAddressID:any;
  locationID:any;
  h39:any;
  h39Index:any;
  todays:any;
  dayID:any;
  weekID:any;
  monthID:any;
  


 

  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    private fb: FormBuilder, 
    ) {}
  ngOnInit() {
    
    this.registerForm = this.fb.group({
     
      task_description:   ['', [Validators.required]],
      
      task_detail:        [''],
      task_why_now:       [''],
      itemID:             [''],
      action:             [''],
      checkValueAddress:  [''],
      make_model:         [''],
      years_old:          [''],
      //territoryID:        [''],
      h39Index:           ['{{location.h39}}'],
      date:               [''],

      locationID:         [''],
      dayID:              [''],
      weekID:             [''],
      monthID:             [''],

      //createdAt:        ['now'],
      //itemID: ['', [Validators.required, Validators.minLength(2)]],
      

      
   
      
    });
    //let now1 = new Date(RfqPage.toLocaleString("en-US", {timeZone: "America/New_York"});
    let now2 = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
    let now = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
    let subcategoryID = this.navParams.get('subcategoryID');
    let categoryID = this.navParams.get('categoryID');
    let todayID = this.navParams.get('todayID');
    let zipID = this.navParams.get('zipID');
    let uid=this.uid;
    let territoryID=this.territoryID;
    let businessTypeID = this.navParams.get('businessTypeID');
    let homeAddressID=this.homeAddressID;
    let itemID=this.itemID;
    let actionID=this.actionID;
    let h39 = this.h39;
    let locationID = this.locationID;
    let h39Index = this.h39Index;
    //let dayID = this.dayID;
    //let weekID = this.weekID;
    



    
   // console.log('placekey',placekey);
    console.log('sub',subcategoryID);
    console.log('cat',categoryID);
    console.log('uid',uid);
    console.log('territoryID',territoryID);
    console.log('businesstypeID',businessTypeID),
    console.log('zipID',zipID),
    console.log('itemID',itemID),
    console.log('actionID',actionID),
    console.log('todayID',todayID),
    console.log('now',now),
    console.log('homeAddressID',homeAddressID),
    //console.log('h39',h39),
    console.log('locationID',locationID),
    console.log('h39Index',h39Index),
    //console.log('dayID',dayID),
    //console.log('weekID',weekID),




   // console.log('griddisk',disk),


   this.apollo
   .watchQuery({
     query: gql`
        query todays
{
todays{
 todayID
 month{
   monthID
   month
 }
 
       day{
           day
           dayID
           date
           weekNumber
           month
           year
       }
         week{
           weekID
           week
           banner_placement{
             bannerUID
             bannerName
             
           }
           promos_placement{
             promoUID
             promotionName
           }
          
 }
 }
}

     `,
            //variables: {uid:this.uid} , 
       // variables:    {uid: this.afAuth.auth.currentUser.uid},
        
   })
   .valueChanges.subscribe((result: any) => {
     this.todays = result?.data?.todays;
     this.loading = result.loading;
     this.error = result.error;
   });
   console.log('todays',this.todays);

    this.apollo
    .watchQuery({
      query: gql`
         query actionTasks
                        {
    actionTasks{
    actionID
    action
      
    }
    }
    
      `,
            
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.actionTasks = result?.data?.actionTasks;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('action',this.actions)

    this.apollo
    .watchQuery({
      query: gql`
        query homes($uid:ID)
    {
    homes(where:{uid:$uid}){
    homeID
    uid
      
      homeAddress{
        
        homeAddressID
        street_number
        street
        city
        state
        zip
        homeAddressLocation{
          locationID
          h39
          locationAddress{
            homeAddressID
            street_number
            street
            city
          }
        }
        
        
        
        
        
      }
      homeZip{
        zip
        town
        zipTerritory{
          territory
        }
      }
      
    
    }
    }
    

    
    
      `,
             variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid)

    this.apollo
    .watchQuery({
      query: gql`
       query subategories($subcategoryID:ID) {
          subcategories(where:{subcategoryID:$subcategoryID}){
            subcategory
            subcategoryID
            image_path
            
              item{
                itemID
                item
                image_path
              }
              category{
                categoryID
                category
                image_path
              }
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {subcategoryID:subcategoryID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.subcategories = result?.data?.subcategories;
      this.loading = result.loading;
      this.error = result.error;
    });

    this.apollo
    .watchQuery({
      query: gql`
       query categories($categoryID:ID) {
          categories(where:{categoryID:$categoryID}){
            category
            categoryID
            image_path
            
             
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {categoryID:categoryID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.categories = result?.data?.categories;
      this.loading = result.loading;
      this.error = result.error;
    });

    this.apollo
    .watchQuery({
      query: gql`
       query businessTypes($businessTypeID:ID) {
          businessTypes(where:{businessTypeID:$businessTypeID}){
            businessTypeID
            business_type
            image_path
            
             
  }
}


`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
    variables:    {businessTypeID:businessTypeID},
   //variables: {inviteID:this.activatedroute.snapshot.paramMap.get('inviteID')} ,

})
    .valueChanges.subscribe((result: any) => {
      this.businessTypes = result?.data?.businessTypes;
      this.loading = result.loading;
      this.error = result.error;
    });
  }
  closeModal():void{
    //this.modalController.dismiss();
    this.modalController.dismiss(null, null, "rfqpage");
    this.modalController.dismiss(null, null, "banners-subcategory");
    
    this.modalController.dismiss(null, null, "categoryhomepage");
    this.modalController.dismiss(null, null, "businesstypepage");
    this.modalController.dismiss(null, null, "promos");
    this.modalController.dismiss(null, null, "offerings");
    //Used to close Tip of the Week Module
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banner");
    this.modalController.dismiss(null, null, "banners-rfq");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "banners-category");

    





    

   
    
  }
  async createTasks(h39Index,dayID,weekID,monthID) {
    this.isSubmitted = true;
    if (!this.registerForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {

      
      console.log(this.registerForm.value)
    }

    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation: createTasks,
      
      variables: {
      uid: this.afAuth.auth.currentUser.uid,
      task_description:this.registerForm.value.task_description,
      task_detail:this.registerForm.value.task_detail,
      task_why_now:this.registerForm.value.task_why_now,
      action:this.registerForm.value.action,
      make_model:this.registerForm.value.make_model,
      years_old:this.registerForm.value.years_old,
      categoryID:this.categoryID,
      subcategoryID:this.subcategoryID,
      
      homeUID:this.afAuth.auth.currentUser.uid,
      businessTypeID:this.businessTypeID,
      itemID:this.itemID,
      createdAt:now,
      //homeAddressID:this.homeAddressID,
      h39Index:this.registerForm.value.h39Index,
      locationID:this.locationID,
      //actionID:this.actionID
      date:now,
      dayID:this.registerForm.value.dayID,
      weekID:this.registerForm.value.weekID,
      monthID:this.registerForm.value.monthID,

      
      
      
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);
       
  console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    
    });
    this.modalController.dismiss(null, null, "rfqpage");
    this.modalController.dismiss(null, null, "categoryhomepage");
    this.modalController.dismiss(null, null, "businesstypepage");
    this.modalController.dismiss(null, null, "trades");
    this.modalController.dismiss(null, null, "banner");
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "promos");
    this.modalController.dismiss(null, null, "offerings");
    this.modalController.dismiss(null, null, "banners-rfq");
    this.modalController.dismiss(null, null, "banners-category");
    
     this.router.navigateByUrl('seller/entry');


}


  async taskCreate(homeAddressID,h39Index) {
    this.isSubmitted = true;
    if (!this.registerForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {

      
      console.log(this.registerForm.value)
    }

    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation: createTasks,
      
      variables: {
      uid: this.afAuth.auth.currentUser.uid,
      task_description:this.registerForm.value.task_description,
      task_detail:this.registerForm.value.task_detail,
      task_why_now:this.registerForm.value.task_why_now,
      action:this.registerForm.value.action,
      make_model:this.registerForm.value.make_model,
      years_old:this.registerForm.value.years_old,
      categoryID:this.categoryID,
      subcategoryID:this.subcategoryID,
      
      homeUID:this.afAuth.auth.currentUser.uid,
      businessTypeID:this.businessTypeID,
      itemID:this.itemID,
      createdAt:now, 
      homeAddressID:this.homeAddressID,
      locationID:this.locationID,
      h39Index:h39Index,
      dayID:this.registerForm.value.dayID,
      weekID:this.registerForm.value.weekID,
      monthID:this.registerForm.value.monthID,

     
      
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);
       
  console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    
    });
    this.modalController.dismiss(null, null, "rfqpage");
    this.modalController.dismiss(null, null, "categoryhomepage");
    this.modalController.dismiss(null, null, "businesstypepage");
    this.modalController.dismiss(null, null, "trades");
    this.modalController.dismiss(null, null, "banner");
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "promos");
    this.modalController.dismiss(null, null, "offerings");
    this.modalController.dismiss(null, null, "banners-rfq");
    this.modalController.dismiss(null, null, "banners-category");


    
    this.router.navigateByUrl('seller/entry');


}



checkValue(event) { 
  //console.log(event.detail.value);
  console.log('itemID',this.itemID)
}
checkValueAction(event) { 
  //console.log(event.detail.value);
  console.log('action',this.actionID)
}
checkValueAddress(event) { 
  //console.log(event.detail.value);
  console.log('homeAddressID',this.homeAddressID)
}
checkValueLocation(event) { 
  //console.log(event.detail.value);
  console.log('locationID',this.locationID);
  
}
showToast() {
  this.toastController.create({
    header: 'Congratulation',
    message: 'Your Task Has been added to THE list',
    position: 'top',
    cssClass: 'my-custom-class',
    color: 'home',
    duration: 5000
    
  }).then((obj) => {
    obj.present();
    
   
  });
}
  showToastSignup() {
    this.toastController.create({
      header: 'Thank You',
      message: 'Please Sign Up to create your own tasks and todos',
      position: 'middle',
      cssClass: 'my-custom-class',
      color: 'home',
      duration: 5000
      
    }).then((obj) => {
      obj.present();
      
     
    });
}
async createTodos(h39Index,dayID,weekID) {
  this.isSubmitted = true;
  if (!this.registerForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.registerForm.value)
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: createTodos,
    
    variables: {
      uid: this.afAuth.auth.currentUser.uid,
      task_description:this.registerForm.value.task_description,
      task_detail:this.registerForm.value.task_detail,
      task_why_now:this.registerForm.value.task_why_now,
      action:this.registerForm.value.action,
      make_model:this.registerForm.value.make_model,
      years_old:this.registerForm.value.years_old,
      categoryID:this.categoryID,
      subcategoryID:this.subcategoryID,
      zipID:this.zipID,
      territoryID:this.territoryID,
      homeUID:this.afAuth.auth.currentUser.uid,
      businessTypeID:this.businessTypeID,
      itemID:this.itemID,
      createdAt:now, 
      homeAddressID:this.homeAddressID,
      locationID:this.locationID,
      h39Index:h39Index,
      dayID:this.registerForm.value.dayID,
      weekID:this.registerForm.value.weekID,
    
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
   
  
  });
 
  this.modalController.dismiss(null, null, "rfqpage");
    this.modalController.dismiss(null, null, "categoryhomepage");
    this.modalController.dismiss(null, null, "businesstypepage");
    this.modalController.dismiss(null, null, "trades");
    this.modalController.dismiss(null, null, "banner");
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "promos");
    this.modalController.dismiss(null, null, "offerings");
    this.modalController.dismiss(null, null, "banners-rfq");
    this.modalController.dismiss(null, null, "banners-category");
  
  
   this.router.navigateByUrl('seller/entry');





}
checkValue1(event) { 
//console.log(event.detail.value);
console.log('itemID',this.itemID)
}
checkValueAction1(event) { 
//console.log(event.detail.value);
console.log('action',this.actionID)
}
showToast1() {
this.toastController.create({
header: 'Congratulation',
message: 'Your Task has been Posted ',
position: 'middle',
cssClass: 'my-custom-class',
color: 'home',
duration: 3000

}).then((obj) => {
obj.present();


});
}
showToastTodo() {
  this.toastController.create({
  header: 'Congratulation',
  message: 'Your Todo has been Posted ',
  position: 'middle',
  cssClass: 'my-custom-class',
  color: 'home',
  duration: 3000
  
  }).then((obj) => {
  obj.present();
  
  
  });
  

}
subscribeTasks(){

}
}
