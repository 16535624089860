import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { BannerModalPage } from '../banner-modal/banner-modal.page';



@Component({
  selector: 'app-banner',
  templateUrl: './banner.page.html',
  styleUrls: ['./banner.page.scss'],
})
export class BannerPage implements OnInit {

  uid = this.authService.getUid(); 
  homeID:any;
  bannerPlacements: any[];
  bannerPlacements1: any[];
  jediHero: any[];
  placement1: any[];


  loading = true;
  error: any;
  dataReturned: any;
  todays: any[];
  homes: any[];
 bannerUID: any[];
 bannerPlacementUID: any[];
 

  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let weekID = this.navParams.get('weekID');
    let todayID = this.navParams.get('todayID');
    let dayID = this.navParams.get('dayID');
    let yearID = this.navParams.get('yearID');

    let territory_number = this.navParams.get('territory_number');
    console.log('weekID',weekID);
    console.log('todayID',todayID);
    console.log('dayID',dayID);
    console.log('yearID',yearID);


   

    this.apollo
    .watchQuery({
      query: gql`
          query todays
{
  todays{
  todayID
    date
    		day{
      		dayOfWeek
      				daily_message_placement{
        				daily_message
      } 
    }
    weekly_banners{
      headline
      message
      image_path
    }
    
  month{
    monthID
    month
  }
  year{
    yearID
    year
  }
        day{
            day
            dayID
            date
            dayOfWeek
            weekNumber
            month
            year
          daily_message_placement{
            dayID
            image_path
            daily_message
            
          }
          ad_placements{
            adPlacementUID
            ads{
              adUID
              headline
              subheader
              body
              image_path
            }
          }
         
        }
          week{
            weekID
            week
            
            promos_placement{
              
              
              promo{
                
                description
                image_path
               		promo_offering{
                    offering
                    offering_description
                    offering_image_path
                    offeringUID
                    offerings{
                      offeringUID
                      description
                      image_path
                      	category{
                          categoryID
                          category
                          image_path
                          	subcategory{
                              subcategoryID
                              subcategory
                              	item{
                                  itemID
                                  item
                                  image_path
                                }
                            }
                        }
                    }
                  }
              }
            }
           
           
  }
    
  
}
}

      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays)

  
  this.apollo
  .watchQuery({
    query: gql`
      query bannerPlacements($weekID:ID)
{
  bannerPlacements(where:{weekID:$weekID},options:{limit:10}){
      bannerID
 bannerPlacementUID
 weekID
 dayID
  week
  year
  territory_number
  banners{
    bannerUID
    image_path
    headline
    message
  }
}
}

    `,
           //variables: {uid:this.uid} , 
       variables:    {weekID: weekID},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.bannerPlacements = result?.data?.bannerPlacements;
    this.loading = result.loading;
    this.error = result.error;
  });


  this.apollo
  .watchQuery({
    query: gql`
      query bannerPlacements($weekID:ID)
{
  jediHero:bannerPlacements(where:{weekID:$weekID},options:{offset:1,limit:1}){
      bannerID
 bannerPlacementUID
 weekID
 dayID
  week
  year
  territory_number
  banners{
    bannerUID
    image_path
    headline
    message
  }
}
}

    `,
           //variables: {uid:this.uid} , 
       variables:    {weekID: weekID},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.jediHero = result?.data?.jediHero;
    this.loading = result.loading;
    this.error = result.error;
  });


  this.apollo
  .watchQuery({
    query: gql`
      query bannerPlacements($weekID:ID)
{
  placement1:bannerPlacements(where:{weekID:$weekID},options:{limit:1}){
      bannerID
 bannerPlacementUID
 weekID
 dayID
  week
  year
  territory_number
  banners{
    bannerUID
    image_path
    headline
    message
  }
}
}

    `,
           //variables: {uid:this.uid} , 
       variables:    {weekID: weekID},
       
  })
  .valueChanges.subscribe((result: any) => {
    this.placement1 = result?.data?.placement1;
    this.loading = result.loading;
    this.error = result.error;
  });


  

  



  }
async openModalBanner(bannerPlacementUID, territoryID,zipID,todayID,dayID) {
  const modal = await this.modalController.create({
    component: BannerModalPage,
    cssClass: 'my-modal-class',
    id:"banner",
    componentProps: {
      
      uid:this.uid,
      bannerPlacementUID:  bannerPlacementUID,
      territoryID:territoryID,
      zipID: zipID,
      todayID:todayID,
      dayID:dayID
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
closeModal():void{
  this.modalController.dismiss();
}

      








}
