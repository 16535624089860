import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProAcceptPageRoutingModule } from './pro-accept-routing.module';

import { ProAcceptPage } from './pro-accept.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ProAcceptPageRoutingModule
  ],
  declarations: [ProAcceptPage]
})
export class ProAcceptPageModule {}
