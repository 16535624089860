import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HashtagPageRoutingModule } from './hashtag-routing.module';

import { HashtagPage } from './hashtag.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HashtagPageRoutingModule
  ],
  declarations: [HashtagPage]
})
export class HashtagPageModule {}
