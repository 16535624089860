import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

const GET_TODOS = gql`
  query GetTodos($todoUID:ID) {
    todos(where:{todoUID:$todoUID}) {
      task_description
      task_detail
      todoUID
      is_active
      categoryID
      homeUID
      itemID
      subcategoryID
      territoryID
      zipID
      businessTypeID
    }}
`;


const updateTodos = gql`
  mutation updateTODOS(
              
              $todoUID:ID,
              $task_description: String,
              $task_detail: String,
              $task_why_now: String,
              $make_model: String,
              $years_old:String
              
      ) {
      updateTodos(
          where: {
              todoUID: $todoUID
          }
          update: {
              task_description: $task_description
              task_detail:  		$task_detail
              task_why_now:  		$task_why_now
              make_model: 			$make_model
              years_old:				$years_old
          }
      ) {
          todos {
             todoUID
             task_description
             task_detail
             task_why_now
             make_model
             years_old
             is_active
            
          }
      }
  }

  
`;

const closeTodos = gql`
  mutation closeTODOS(
              
              $todoUID:ID,
             
             
              
      ) {
      updateTodos(
          where: {
              todoUID: $todoUID
          }
          update: {
              is_active: false
             
          }
      ) {
          todos {
             todoUID
             task_description
             task_detail
             task_why_now
             make_model
             years_old
             is_active
            
          }
      }
  }

  
`;

const deleteTodos = gql`
  mutation deleteTodos($todoUID:ID){
  deleteTodos (where:{todoUID:$todoUID}){
    nodesDeleted
   
  }
}

  
`;

const createTasks = gql`
  mutation createTasks( 
    $task_description:String,
    $task_detail:String,
    $task_why_now: String,
    $categoryID:ID,
    $homeUID:ID,
    $itemID:ID,
    $subcategoryID:ID,
    $territoryID:ID,
    $make_model:String,
    $years_old: String,
    $zipID:ID,
    $locationID:ID,
    $h39Index:String,
    $dayID:ID,
    $weekID:ID,
    $businessTypeID:ID,
    $is_emergency:Boolean

    ){
  createTasks(input:
    {
      task_description:$task_description
      task_detail:$task_detail
      task_why_now:$task_why_now
      categoryID:$categoryID
      homeUID:$homeUID
      itemID:$itemID
      subcategoryID:$subcategoryID
      territoryID:$territoryID
      make_model:$make_model
      years_old:$years_old
      locationID:$locationID
      h39Index:$h39Index
      dayID:$dayID
      weekID:$weekID
      businessTypeID:$businessTypeID
      is_emergency:$is_emergency
      is_active:true
      
      
      
     

      days:{connect:{
        where: {
          node:{dayID:$dayID}
        }
      }}

      weeks:{connect:{
        where: {
          node:{weekID:$weekID}
        }
      }}
      business_type:{connect:{
        where: {
          node:{businessTypeID:$businessTypeID}
        }
      }}

      

      locations:{connect:{
        where: {
          node:{locationID:$locationID}
        }
      }}
      

      item:{connect:{
        where: {
          node:{itemID:$itemID}
        }
      }}
      
      
      
      homes:{connect:{
        where: {
          node:{uid:$homeUID}
        }
        
        edge: {
         
          
        
          
          
         
        }
        
      }}
      
      territory:{connect: {
        where: {
          node:{territoryID:$territoryID}
        }
        edge:{
          
         
          
           task_description:$task_description
        
         
        }
      }}
      
      category:{connect:{
        where:	{
          node:{categoryID:$categoryID}
        }
        edge:{
         
          
          task_description:$task_description
          
        }
      }}
      
      
      
      subcategory:{connect:{
        where:	{
          node:{subcategoryID:$subcategoryID}
        }
        edge:{
          
					
          
          task_description:$task_description
          
          
          
        }
      }}
      
    	
      
      
      zip:{connect:{
        where:	{
          node:{zipID:$zipID}
        }
      }}
       
    }
    
    
    
  ) 
  
  {
   tasks{
     taskID
     task_description
   	 task_detail
      task_why_now
      date
   businessTypeID
    categoryID
    dayID
    weekID
    subcategoryID
    is_active
    is_emergency
    make_model
    years_old
    createdAt
    h39
    h39Index
    locationID
    home_address{
      homeAddressID
      street_number
      street
      homeAddressLocation{
          locationID
          h39
          
          
          locationAddress{
            homeAddressID
            street_number
            street
            city
          }
        }
    }
    days{
      dayID
      date
    }
    weeks{
      weekID
    }
    
    locations{
      locationID
      h39
      h36
    }
    actionTask{
      action
    }
    actionID
    item{
      itemID
      item
    }
   territory{
      territory
    }
    
    subcategory{
      subcategory
      subcategoryID
    }
      
      	homes{
          homeID
        
          username
          password
        }
        }
    
  }
 
    }
    

  
`;


@Component({
  selector: 'app-todo-detail',
  templateUrl: './todo-detail.page.html',
  styleUrls: ['./todo-detail.page.scss'],
  
  
  
})
export class TodoDetailPage implements OnInit {
  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  valid: any;
  todos:any[];
  todays:any[];
  businessTypeID:any[];
 locationID:any[];
  todoForm: FormGroup;
  isSubmitted = false;
  private querySubscription: Subscription;

  constructor(private afAuth: AngularFireAuth,

    
    private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    private fb: FormBuilder, 
    ) {}

    ngOnInit() {
     
      let task_description = this.navParams.get('task_description');
      let task_detail = this.navParams.get('task_detail');
      let task_why_now = this.navParams.get('task_why_now');
      let make_model = this.navParams.get('make_model');
      let years_old = this.navParams.get('years_old');
      let todoUID = this.navParams.get('todoUID');
      let businessTypeID = this.navParams.get('businessTypeID');
      let locationID = this.navParams.get('locationID');

      let now = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});

      this.querySubscription = this.apollo.watchQuery<any>({
        query: GET_TODOS,
        variables: {
          todoUID:todoUID
        },
      })
        .valueChanges
        .subscribe(({ data, loading }) => {
          this.loading = loading;
          this.todos = data.todos;
        });
    
  
    
      
    


      

      this.todoForm = this.fb.group({
      
      
        task_description:   [task_description, [Validators.required]],
        
        task_detail:        [task_detail],
        task_why_now:       [task_why_now],
        itemID:             [''],
        action:             [''],
        make_model:         [make_model],
        years_old:          [years_old],
        territoryID:        [''],
        locationID:        [locationID],
        //itemID: ['', [Validators.required, Validators.minLength(2)]],
        
  
        
     
        
      });
      
      let categoryID = this.navParams.get('categoryID');
      let uid=this.uid;
      //let territoryID=this.territoryID;
      //let businessTypeID = this.navParams.get('businessTypeID');
      //let zipID=this.zipID;
      //let itemID=this.itemID;
      //let actionID=this.actionID;
     
     // console.log('subid',subcategoryID);
     // console.log('cat',categoryID);
      //console.log('uid',uid);
      //console.log('territoryID',territoryID);
      //console.log('businesstypeID',businessTypeID);
      //console.log('zipID',zipID),
      //console.log('itemID',itemID),
      //console.log('actionID',actionID)
     // let uid=this.uid;
      //console.log('todouid',todoUID);
     
      this.apollo
   .watchQuery({
     query: gql`
        query todays
{
todays{
 todayID
 month{
   monthID
   month
 }
 
       day{
           day
           dayID
           date
           weekNumber
           month
           year
       }
         week{
           weekID
           week
           banner_placement{
             bannerUID
             bannerName
             
           }
           promos_placement{
             promoUID
             promotionName
           }
          
 }
 }
}

     `,
            //variables: {uid:this.uid} , 
       // variables:    {uid: this.afAuth.auth.currentUser.uid},
        
   })
   .valueChanges.subscribe((result: any) => {
     this.todays = result?.data?.todays;
     this.loading = result.loading;
     this.error = result.error;
   });
   //console.log('todays',this.todays)
      

      this.apollo
      .watchQuery({
        query: gql`
          query todos($todoUID:ID)
  {
    todos(where:{todoUID:$todoUID}){
      task_description
      task_detail
      todoUID
      is_active
      categoryID
      homeUID
      itemID
      subcategoryID
      territoryID
      zipID
      locationID
      dayID
      weekID
      businessTypeID
     
    }
  }
  
        `,
               //variables: {uid:this.uid} , 
           variables:    {todoUID:todoUID},
           
      })
      .valueChanges.subscribe((result: any) => {
        this.todos = result?.data?.todos;
        this.loading = result.loading;
        this.error = result.error;
      });
  
  
  
  
    }
   closeModal():void{
      this.modalController.dismiss();
  }
  async updateTodos(todoUID) {
    this.isSubmitted = true;
    if (!this.todoForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {

      
      console.log(this.todoForm.value)
    }

    //let params = Object.assign(credentials)




    this.apollo.mutate({
      mutation: updateTodos,
      
      variables: {
      todoUID:todoUID,
      uid: this.afAuth.auth.currentUser.uid,
      task_description:this.todoForm.value.task_description,
      task_detail:this.todoForm.value.task_detail,
      task_why_now:this.todoForm.value.task_why_now,
      action:this.todoForm.value.action,
      make_model:this.todoForm.value.make_model,
      years_old:this.todoForm.value.years_old,
      
      //actionID:this.actionID
      
      
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);
       
  console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    
    });
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "banners-category");
    this.modalController.dismiss(null, null, "banners-rfq");
    
     this.router.navigateByUrl('seller/entry');






}

showToast() {
this.toastController.create({
  header: 'Congratulation',
  message: 'You Have Updated Your Todo',
  position: 'middle',
  cssClass: 'my-custom-class',
  color: 'home',
  duration: 3000
  
}).then((obj) => {
  obj.present();
  
 
});
  }



  async deleteTodos(todoUID) {
   
    this.apollo.mutate({
      mutation: deleteTodos,
      
      variables: {
      todoUID:todoUID,
      
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);
       
  console.log("Data Deleted");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    
    });
    this.modalController.dismiss(null, null, "banners");
    this.modalController.dismiss(null, null, "banners-subcategory");
    this.modalController.dismiss(null, null, "banners-category");
    this.modalController.dismiss(null, null, "banners-rfq");
    this.modalController.dismiss(null, null, "todos");
    
     this.router.navigateByUrl('seller/entry');

}

showToast1() {
this.toastController.create({
  header: 'Congratulation',
  message: 'You Have Deleted Your Todo',
  position: 'middle',
  cssClass: 'my-custom-class',
  color: 'home',
  duration: 3000
  
}).then((obj) => {
  obj.present();
  
 
});
}
async createTasks( 
  task_description,
  task_detail,
  task_why_now,
  categoryID, 
  homeUID,
  itemID,
  subcategoryID,
  territoryID,
  make_model,
  years_old,
  
  locationID,
  h39Index,
  dayID,
  weekID,
  businessTypeID) {
 
  this.apollo.mutate({
    mutation: createTasks,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    task_description:task_description,
    task_detail:task_detail,
    task_why_now:task_why_now,
    //todoUID:todoUID,
    make_model:make_model,
    years_old:years_old,
    categoryID:categoryID,
    subcategoryID:subcategoryID,
    
    territoryID:territoryID,
    homeUID:this.afAuth.auth.currentUser.uid,
  
    itemID:itemID, 
    //actionID:this.actionID
    //date:now,
    h39Index:h39Index,
      locationID:locationID,
      dayID:dayID,
      weekID:weekID,
      businessTypeID:businessTypeID
    
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  
  });
  this.modalController.dismiss(null, null, "banners-subcategory");
  this.modalController.dismiss(null, null, "todos");
  this.modalController.dismiss(null, null, "banners");
 
  this.modalController.dismiss(null, null, "banners-category");
  this.modalController.dismiss(null, null, "banners-rfq");
  
   this.router.navigateByUrl('seller/entry');
}
async closeTodos(todoUID) {
 
  this.apollo.mutate({
    mutation: closeTodos,
    
    variables: {
    //is_active:false,
    todoUID:todoUID,
    
    
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);
     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  
  });
  //this.modalController.dismiss(null, null, "banners");
  //this.modalController.dismiss(null, null, "banners-subcategory");
  //this.modalController.dismiss(null, null, "banners-category");
  //this.modalController.dismiss(null, null, "banners-rfq");
  
   //this.router.navigateByUrl('seller/entry');
}
}