import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

const updateSupplierStaffs = gql`

mutation updateSupplierStaffs($supplierstaffID:ID, 
  													
  $name_first:String,
  $name_last: String,

  

  ) {

updateSupplierStaffs(where: {supplierstaffID: $supplierstaffID }
      
   update:{
    name_first:$name_first,
    name_last:$name_last,
    
  }

){
supplierStaffs{

  supplierstaffID
    name_first
   name_last


}

}
}

`;

const deleteSupplierStaffs = gql`
mutation deleteSupplierStaffs($supplierstaffID:ID){
deleteSupplierStaffs (where:{supplierstaffID:$supplierstaffID}){
nodesDeleted

}
}


`;
@Component({
  selector: 'app-staff-modal',
  templateUrl: './staff-modal.page.html',
  styleUrls: ['./staff-modal.page.scss'],
})
export class StaffModalPage implements OnInit {

  uid = this.authService.getUid(); 
  staffs: string[];
  
  loading = true;
  error: any;
  isSubmitted = false;
  data:any;

  //staff Form
  staffForm: FormGroup;
  name_first: string;
  name_last:string
  supplierstaffID:string;

  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

  ngOnInit() {
    let supplierstaffID = this.navParams.get('supplierstaffID');
     
    console.log('supplierstaffID', supplierstaffID);

    this.staffForm = this.fb.group({
      name_first:      [this.name_first],
      name_last:    [this.name_last]
      
      
      
     });


    }
     async updateSupplierStaffs(supplierstaffID) {
      this.isSubmitted = true;
      if (!this.staffForm.valid) {
        console.log('Please provide all the required values!')
        return false;
      } else {
    
        
        console.log(this.staffForm.value)
      }
    
      //let params = Object.assign(credentials)
      this.apollo.mutate({
        mutation: updateSupplierStaffs,
        
        variables: {
        uid: this.afAuth.auth.currentUser.uid,
        name_first:this.staffForm.value.name_first,
        name_last:this.staffForm.value.name_last,
       
        supplierstaffID:supplierstaffID
       
       
       
        
        
         
      },
        
      }).subscribe(({ data }) => {
        
     
         console.log(data);    
        console.log("Form Submitted!");
    
    
    //this.navCtrl.navigateForward('/setup'); 
     //this.router.navigate(['/app/setup/',this.id ]);
       
      }, (error) => {
        console.log('there was an big ass error sending the query', error);
        
        //this.navCtrl.navigateRoot('/setup');
      });
    }
    async deleteSupplierStaffs() {
    
    this.apollo.mutate({
      mutation: deleteSupplierStaffs,
      
      variables: {
      
        supplierstaffID:this.supplierstaffID
     
      
      
       
    },
      
    }).subscribe(({ data }) => {
      
    
       console.log(data);    
      console.log("Deleted!");
    
    
    //this.navCtrl.navigateForward('/setup'); 
    //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    });



  }
  closeModal():void{
    this.modalController.dismiss();
}
}
