import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
//import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { BusinesstypePage } from '../businesstype/businesstype.page';
import { TaskHomePage } from '../task-home/task-home.page';
import { TradesPage } from '../trades/trades.page';
import { ProfileHomePage } from '../profile-home/profile-home.page';
import { BannerPage } from '../banner/banner.page';
import { PromoPage } from '../promo/promo.page';
import { TodoPage } from '../todo/todo.page';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController} from '@ionic/angular';


const now = new Date();
const GET_SUPPLIERS = gql`
   query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) {
              email
              supplierID
              countTask
              countsupplierTasks
              supplierHashtags{
              itemID
              item_tag
          }
              supplierMap{
                        latitude
                        longitude
                        mapID
                        uid
                        h39
                        h37
                        h36
                      }
              supplierCategory{
                categoryID
              }
              supplierSubcategory{
                subcategoryID
                subcategory
              }
              supplierBusinessType

                subscriptionTasksBT{
                  date_create
                itemID
                task_description
                task_detail
                taskDaysAgo
                h39
                homeType
                item_tag
                  item{
                  item
                  itemID
                  image_path
              }
                  category{
                  category
              }
                  subcategory{
                  subcategory
               }
                  territory{
                  territory
        }
      }
    }
     
    }
`

const createConnects = gql`
mutation createConnects($task_description:String,$supplierID:ID, $availibility:String, $note:String, $date_task_create:Date, $category:String, $subcategory:String, $taskID:ID,$dayID:ID,$weekID:ID ){
  createConnects(input:
    {
      task_description:$task_description
      supplierID:$supplierID
      availibility:$availibility
      note:$note
      date_task_create:$date_task_create
      taskID:$taskID
      dayID:$dayID
      weekID:$weekID
      
    	tasks:{connect:{
        where: {
          node:{taskID:$taskID}
        }
        edge: {
         task_description:$task_description
         category:$category
         subcategory:$subcategory 
          
        }
      }}
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{category:$category}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategory:$subcategory}
         }
       }}
       
       suppliers:{connect:{
         where:{
           node:{uid:$supplierID}
         }
       }}
       
    }
    
  ) 
  
  {
   connects{
    connectID
    	supplierID
      task
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      	tasks{
         task_description
          town
          category{
            category
            
          }
          territory{
            territory
          }
          subcategory{
            subcategory
          }
        }
        }
        
    
  }
 
    }
    


`;

const createDeclines = gql`
mutation createDeclines($task_description:String,$supplierID:ID, $availibility:String, $note:String, $date_task_create:Date, $category:String, $subcategory:String, $taskID:ID,$dayID:ID,$weekID:ID,$supplierUID:ID ){
  createDeclines(input:
    {
      task_description:$task_description
      supplierID:$supplierID
      availibility:$availibility
      note:$note
      date_task_create:$date_task_create
      taskID:$taskID
      dayID:$dayID
      weekID:$weekID
      supplierUID:$supplierUID
      
    	tasks:{connect:{
        where: {
          node:{taskID:$taskID}
        }
        edge: {
         task_description:$task_description
         category:$category
         subcategory:$subcategory 
          
        }
      }}
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       category:{connect:{
         where:{
           node:{category:$category}
         }
       }}
       subcategory:{connect:{
         where:{
           node:{subcategory:$subcategory}
         }
       }}
       
       suppliers:{connect:{
         where:{
           node:{uid:$supplierUID}
         }
       }}
       
    }
    
  ) 
  
  {
   declines{
    declineID
    	supplierID
      supplierUID
      task
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      category{
        category
      }
      	tasks{
         task_description
          town
          category{
            category
            
          }
          territory{
            territory
          }
          subcategory{
            subcategory
          }
        }
        }
        
    
  }
 
    }
    


`;

const deleteHashtags =gql`
mutation deleteHashtags($hashtagID:ID){
  deleteHashtags (where:{ hashtagID:$hashtagID}){
    nodesDeleted
   
  }
}

`;


const createHashtags = gql`

mutation createHashtags($itemID:ID,$supplierID:ID, $uid:ID ){
  createHashtags(input:
    {
      itemID: $itemID
      supplierID:$supplierID                                               
      uid:$uid                                               
			                         
      
      items:{connect:{
         where:{
           node:{itemID:$itemID}
         }
       }}
    	
     
      
       
       suppliers:{connect:{
         where:{
           node:{uid:$uid}
         }
       }}
       
    }
    
  ) 
  
  {
   hashtags{
    hashtagID
    date_create
    	supplierID
      uid
    suppliers{
      supplierID
      uid
      email
    }
     itemID
    items{
      itemID
      item
      image_path
    }
     
        }
        }
        
    
  }
 
    


`;
@Component({
  selector: 'app-hashtag1',
  templateUrl: './hashtag1.page.html',
  styleUrls: ['./hashtag1.page.scss'],
})
export class Hashtag1Page implements OnInit {

  @ViewChild('slides',{static:true}) slider: IonSlides;
  segmentModel = "one";
  segmentModelList = "list";


  uid = this.authService.getUid(); 
  suppliers: any[];
  loading = true;
  error: any;
  items:any[];
  hashtags:[];
  declines:[];
  connects:[];
  relatedhashtags:any[];
  hashtagID:any;
  itemID:any;
  territories: string[];
  subcategory: string[];
  todays:any;
  dayID:any;
  weekID:any;


  constructor( private route: ActivatedRoute,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController, public toastController: ToastController) {
   
  }

  ngOnInit() {
    let uid=this.uid;
    let hashtagID=this.hashtagID;
    let itemID=this.itemID;
    this.hashtagID=hashtagID;

    

    this.apollo
    .watchQuery({
      query: gql`
         query todays
 {
 todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
 
      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
      console.log('todays',this.todays);

    });

    

    this.apollo
    .watchQuery({
      query: gql`
         query suppliers($uid:ID) {
          suppliers(where:{uid:$uid}) {
          email
          supplierID
          uid
          countTask
          supplierHashTagTasksh34{
            taskID
            task_description
            city_tag
            homeType

            date_create
            item_tag
            category_tag
            subcategory_tag
            count_connect
            count_decline
            taskDaysAgo
            connectSupplierID
            declineSupplierUID
            
                category{
                categoryID
                category
            }
                subcategory{
                  subcategoryID
                  subcategory
                }

          }
          supplierCategory{
        categoryID
        category
      }
      supplierHashtags{
        itemID
        item_tag
        item_image_path
      }
      supplierSubcategory{
        subcategoryID
        subcategory
      }
          

          supplierHashTagTasksh35{
            taskID
            task_description
            city_tag
            homeType

            date_create
            item_tag
            category_tag
            subcategory_tag
            count_connect
            count_decline
            taskDaysAgo
            connectSupplierID
            declineSupplierUID
            
                category{
                categoryID
                category
            }
                subcategory{
                  subcategoryID
                  subcategory
                }

          }
          supplierCategory{
        categoryID
        category
      }
      supplierHashtags{
        itemID
        item_tag
        item_image_path
      }
      supplierSubcategory{
        subcategoryID
        subcategory
      }
          supplierTasksh34{
            task_description
            taskID
            itemID
            categoryID
            subcategoryID
            businessTypeID
            posted
            homeType

            taskDaysAgo
            item_tag
            city_tag
            connectSupplierID
            count_connect
            
            category {
            category
            }
            subcategory{
            subcategory
            }
            
          }
        }
 
        }




`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
        variables:    {uid: this.uid},

})
    .valueChanges.subscribe((result: any) => {
      this.suppliers = result?.data?.suppliers;
      this.loading = result.loading;
      this.error = result.error;
    });
        
  

    this.apollo
    .watchQuery({
      query: gql`
         query hashtags($supplierID:ID) {
          hashtags(where:{supplierID:$supplierID},options:{sort:{date_create:DESC}}) {
            itemID
        		item_tag
        		item_image_path
    				supplierID
            hashtagID
            item_related{
              itemID
              item
              
            }
          
        }
 
        }



`,
    //variables:    {uid: this.afAuth.auth.currentUser.uid},
        variables:    {supplierID: this.uid},

})
    .valueChanges.subscribe((result: any) => {
      this.hashtags = result?.data?.hashtags;
      this.loading = result.loading;
      this.error = result.error;
    });


    this.apollo
    .watchQuery({
      query: gql`
        query declines($supplierUID:ID){
declines(where:{supplierUID:$supplierUID},options: {sort:{date_create:DESC}}){
  declineID
  date_create
  supplierID
  declineTask{
    taskID
    task_description
    date_create
    task_detail
    category_tag
    subcategory_tag
    item_tag
    city_tag
    is_active
    count_connect
    count_decline
    taskDaysAgo
  }
}
}




`,
    variables:    {supplierUID: this.afAuth.auth.currentUser.uid},
        //variables:    {supplierID: uid}

})
    .valueChanges.subscribe((result: any) => {
      this.declines = result?.data?.declines;
      this.loading = result.loading;
      this.error = result.error;
    });
    this.apollo
    .watchQuery({
      query: gql`
        query connects($supplierID:ID){
connects(where:{supplierID:$supplierID},options: {sort:{date_create:DESC}}){
  date_create
  supplierID
  supplierUID
  uid
  connectTask{
    taskID
    task_description
    task_detail
    category_tag
    subcategory_tag
    item_tag
    city_tag
    is_active
    count_connect
    count_decline
    taskDaysAgo
  }
}
}




`,
    variables:    {supplierID: this.afAuth.auth.currentUser.uid},
        //variables:    {supplierID: uid}

})
    .valueChanges.subscribe((result: any) => {
      this.connects = result?.data?.connects;
      this.loading = result.loading;
      this.error = result.error;
      //console.log('data',this.data);

    }); 
    
  }
  closeModal():void{
    this.modalController.dismiss();
}




  deleteHashtags(hashtagID ) {
                
                
            
              
    this.apollo.mutate({
    mutation:  deleteHashtags,
    variables: {
    hashtagID:hashtagID
    
    
    
    
    },

    //newly added to test update function

   


  
    }).subscribe(({ data }) => {
    //console.log('id: ', id);
    
    console.log('data',data);

    }, (error) => {
    console.log('there was an big ass error sending the query', error);
    });
}
AddHashtags(itemID, uid,supplierID ) {
                
  this.apollo.mutate({
  mutation:  createHashtags,
  variables: {
  
  itemID:itemID,
  uid:uid,
  supplierID:supplierID
  
  
  
  },

  //newly added to test update function

 



  }).subscribe(({ data }) => {
  //console.log('id: ', id);
  
  console.log('data',data);

  }, (error) => {
  console.log('there was an big ass error sending the query', error);
  });
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}

showToast() {
  this.toastController.create({
    header: 'Congratulation',
    message: 'You Have Added a new Hashtag',
    position: 'middle',
    cssClass: 'my-custom-class',
    color: 'home',
    duration: 3000
    
  }).then((obj) => {
    obj.present();
  });

}


AddConnectTasks(taskID,category,subcategory,supplierID,uid,dayID,weekID ) {
                
  console.log("category",category);
  console.log("taskID",taskID);


      this.apollo.mutate({
      mutation:  createConnects,
      variables: {
      
      taskID:taskID,
      
      category:category,
      subcategory:subcategory,
      supplierID:uid,
      uid:uid,
      dayID:dayID,
      weekID:weekID
      },

      //newly added to test update function

     refetchQueries:[
       {
         query:gql`
        
        query suppliers($uid:ID) {
        suppliers(where:{uid:$uid}) 
        {
       supplierID
       uid
       countTask
       countsupplierTasks
       supplierTasks{
        task_description
        homeType

  taskID
  posted
  taskDaysAgo
  item_tag
  city_tag
  connectSupplierID
  count_connect
 
       }
       }
      }
        
        
        `
        }]


    
      }).subscribe(({ data }) => {
      //console.log('id: ', id);
      console.log('territory: ', this.territories);
      console.log('sub: ', this.subcategory);
      console.log('data',data);
  
      }, (error) => {
      console.log('there was an big ass error sending the query', error);
      });
    }
      AddDeclineTasks(taskID,category,subcategory,supplierID,uid,dayID,weekID,supplierUID ) {
    
        console.log("category",category);
        console.log("taskID",taskID);
    
      
            this.apollo.mutate({
            mutation:  createDeclines,
            variables: {
            
            taskID:taskID,
            
            category:category,
            subcategory:subcategory,
            supplierID:supplierID,
            uid:uid,
            dayID:dayID,
            weekID:weekID,
            supplierUID:uid,
            },

            //newly added to test update function

           refetchQueries:[
             {
               query:gql`
              
              query suppliers($uid:ID) {
              suppliers(where:{uid:$uid}) 
              {
             supplierID
             uid
             
             supplierTasks{
              task_description
              date_create
              homeType

        taskID
        posted
        taskDaysAgo
        item_tag
        city_tag
        connectSupplierID
        count_connect
       
             }
             }
            }
              
              
              `
              }]
      

          
            }).subscribe(({ data }) => {
            //console.log('id: ', id);
            console.log('territory: ', this.territories);
            console.log('sub: ', this.subcategory);
            console.log('data',data);
        
            }, (error) => {
            console.log('there was an big ass error sending the query', error);
            });
          }
          showToastConfirm() {
            this.toastController.create({
              header: 'Congratulation',
              message: 'You Have Now Shown Interest With The Homeowner',
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'success',
              duration: 3000
              
            }).then((obj) => {
              obj.present();
            });
      
          }
          showToastDecline() {
            this.toastController.create({
              header: 'Congratulation',
              message: 'You Have Now Declined the Task',
              position: 'middle',
              cssClass: 'my-custom-class',
              color: 'danger',
              duration: 3000
              
            }).then((obj) => {
              obj.present();
            });
          }
ionViewDidEnter() {

  
}
}

