import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
//Modal Pages
import { InfoModalPage } from '../info-modal/info-modal.page';
import { AddressModalPage } from '../address-modal/address-modal.page';
import { PhoneModalPage } from '../phone-modal/phone-modal.page';
import { LicenseModalPage } from '../license-modal/license-modal.page';
import { CompanyModalPage } from '../company-modal/company-modal.page';
import { StaffModalPage } from '../staff-modal/staff-modal.page';


import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

const createSupplierAddresses = gql`
 mutation createSupplierAddresses($address:String,
  														$suite:String,
  													  $county: String
  														$city: String,
  														$state: String,
  														$zip: String,
  														$uid: ID
  												
  																	

){
  createSupplierAddresses(input:
    {
      address: $address
      suite:$suite
      city: $city
      county:$county
      state: $state
      zip: $zip
      uid:$uid
      
     
    	suppliers:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      
      zips:{connect:{
        where: {
          node:{zip:$zip}
        }
        
      }}
      
     
    }
    
  ) 
  
  {
   supplierAddresses{
     supplieraddressID
    	uid
      address
    	suite
    	city
      county
    	state
    	zip
      suppliers{
        uid
      }
      zips{
        zip
        town
      }	
        }
    
  }
 
    }
  
  
`;

const createSupplierLicenses = gql`
mutation createSupplierLicenses($license_number:String,
  																$county_license: String,
  																$state_license: String,
    															$uid:ID																	

																){
  		createSupplierLicenses(input:
    {
      license_number: $license_number
     	county_license:$county_license
      state_license:$state_license
      uid:$uid
     
    	suppliers:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      
      
      
     
    }
    
  ) 
  
  {
   supplierLicenses{
    	uid
      county_license
    	state_license
    	license_number
      suppliers{
        uid
      }
      suppliers{
        uid
      }	
        }
    
  }
 
    }
  
  
  
`;

const createSupplierPhones = gql`
mutation createSupplierPhones($phone_type:String,
  															$phone_number: String,
  															$uid:ID																	

																){
  		createSupplierPhones(input:
    {
      phone_type: 	$phone_type
     	phone_number: $phone_number
      uid:$uid
      
      
    	suppliers:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      
      
    }
    
  ) 
  
  {
   supplierPhones{
    	uid
      phone_type
    phone_number
      suppliers{
        uid
      }
      suppliers{
        uid
      }	
        }
    
  }
 
    }
  
  
`;

const createSupplierInfos = gql`
mutation createSupplierInfos($company:String,
  														$founded: Int,
                              $employee: Int,
                              $tagline: String,
                              $base_location: String,
                              $bio: String,
                              $url: String,
                              $insurance: Boolean,
                              $license: Boolean,
  														$uid:ID	,
                              $supplierID: ID															

																){
  		createSupplierInfos(input:
    {
      company: 	      $company
      founded:        $founded
      employee:       $employee
      tagline:        $tagline
      base_location:  $base_location
      bio:            $bio
      url:            $url
      insurance:      $insurance
      license:        $license
      supplierID:     $supplierID
      
      
    	suppliers:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      
      
    }
    
  ) 
  
  {
   supplierInfos{
     supplierinfoID
    	uid
      supplierID
      company
      founded
      employee
      tagline
      base_location
      bio
      url
      insurance
      license
      suppliers{
        uid
      }
      suppliers{
        uid
      }	
        }
    
  }
 
    }
  
  
`;

const createSupplierStaffs = gql`
mutation createSupplierStaffs($name_first:String,
  															$name_last: String,
  															$uid:ID																	

																){
  		createSupplierStaffs(input:
    {
      name_first: $name_first
     	name_last: $name_last
      uid:$uid
      
    	suppliers:{connect:{
        where: {
          node:{uid:$uid}
        }
        
      }}
      
      
    }
    
  ) 
  
  {
   supplierStaffs{
    	
      name_first
   name_last
      suppliers{
        uid
      }
      suppliers{
        uid
      }	
        }
    
  }
 
    }
  
  
`;

@Component({
  selector: 'app-profile-pro',
  templateUrl: './profile-pro.page.html',
  styleUrls: ['./profile-pro.page.scss'],
})
export class ProfileProPage implements OnInit {
  uid = this.authService.getUid(); 
  segmentModel = "intro";
  dataReturned: string[];
  
  

  //set up each form

  //address Form
  addressForm: FormGroup;
  address: string;
  suite: string;
  city: string;
  county: string;
  state: string;
  zip: string;

  //info Form
   infoForm: FormGroup;
   company: string;
   founded: number;
   employee: number;
   tagline: string;
   base_location: string;
   bio: string;
   url: string;
   insurance: boolean;
   license: boolean;
   supplierID: string;
   //insurance = true;
   //license = true;

   //license Form
   licenseForm: FormGroup;
   license_number: string;
   county_license: string;
   state_license: string;

    //phone Form
    phoneForm: FormGroup;
    phone_type: string;
    phone_number: string;

     //staff Form
     staffForm: FormGroup;
     name_first: string;
     name_last: string;

    suppliers: string[];
    loading = true;
    error: any;
    isSubmitted = false;
    data:any;
    supplierinfoID: string;

  constructor(private authService: AuthService, 
    private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private router: Router) {}

  ngOnInit() {
    
   
     //remember to add reactive forms module to module.ts
     
     let uid=this.uid;
     let supplierinfoID=this.supplierinfoID

    //remember to add reactive forms for each form

    //address form
     this.addressForm = this.fb.group({
      address:      [''],
      suite:        [''],
      city:         [''],
      county:       [''],
      state:        ['New Jersey'],
      zip:          [''],
     });

      //info form
      this.infoForm = this.fb.group({
        company:      [''],
        founded:      [''],
        employee:     [''],
        tagline:      [''],
        base_location:[''],
        bio:          [''],
        url:          [''],
        insurance:    [''],
        license:      [''],
        supplierID:   [''],
       });

       //license form
      this.licenseForm = this.fb.group({
        license_number:[''],
        county_license:[''],
        state_license: [''],

      });

       //license form
       this.phoneForm = this.fb.group({
        phone_type:    [''],
        phone_number:  [''],
        

      });

       //staff form
       this.staffForm = this.fb.group({
        name_first:    [''],
        name_last:      [''],
        

      });
  
      //Main Search Query Suppliers
  this.apollo
    .watchQuery({
      query: gql`
         query suppliers($uid:ID)
        {
          suppliers(where:{uid:$uid}){
          uid
          supplierID
          email
            
            supplierSupplierAddress{
              supplieraddressID
              address
              city
              county
              state
              zip
            }
            
           supplierSupplierPhone{
             supplierphoneID
              phone_type
              phone_number
            }
            
            supplierSupplierLicense{
              supplierlicenseID
              license_number
              county_license
              state_license
            }
            
            supplierSupplierInfo{
              supplierinfoID
              supplierID
              company
              founded
              employee
              tagline
              base_location
              bio
              url
              insurance
              license
            }
            
            supplierSupplierStaff{
              supplierstaffID
              name_first
              name_last
            }
            
  
}
}
  

      `,
             
         variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.suppliers = result?.data?.suppliers;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('uid',this.uid);
  }
  closeModal():void{
    this.modalController.dismiss();
  }
  segmentChanged(event){
    console.log(this.segmentModel);
    
    console.log(event);

//Function for License Form
}
async createSupplierStaffs() {
  this.isSubmitted = true;
  if (!this.staffForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.staffForm.value)
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: createSupplierStaffs,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    name_first:this.staffForm.value.name_first,
    name_last:this.staffForm.value.name_last,

    
    
     
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);    
    console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });


    //Function for License Form
  }
  async createSupplierLicenses() {
    this.isSubmitted = true;
    if (!this.licenseForm.valid) {
      console.log('Please provide all the required values!')
      return false;
    } else {
  
      
      console.log(this.licenseForm.value)
    }
  
    //let params = Object.assign(credentials)
    this.apollo.mutate({
      mutation: createSupplierLicenses,
      
      variables: {
      uid: this.afAuth.auth.currentUser.uid,
      license_number:this.licenseForm.value.license_number,
      county_license:this.licenseForm.value.county_license,
      state_license:this.licenseForm.value.state_license,
      
       
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);    
      console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    });
}
 //Function for license Form
 async createSupplierAddresses() {
  this.isSubmitted = true;
  if (!this.addressForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.addressForm.value)
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: createSupplierAddresses,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    address:this.addressForm.value.address,
    suite:this.addressForm.value.suite,
    county:this.addressForm.value.county,
    city:this.addressForm.value.city,
    state:this.addressForm.value.state,
    zip:this.addressForm.value.zip,
     
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);    
    console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
}
 //Function for License Form

async createSupplierPhones() {
  this.isSubmitted = true;
  if (!this.phoneForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.phoneForm.value)
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: createSupplierPhones,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    phone_type:this.phoneForm.value.phone_type,
    phone_number:this.phoneForm.value.phone_number,
    
    
     
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);    
    console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
}
async createSupplierInfos() {
  this.isSubmitted = true;
  if (!this.infoForm.valid) {
    console.log('Please provide all the required values!')
    return false;
  } else {

    
    console.log(this.infoForm.value)
  }

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation: createSupplierInfos,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    company:this.infoForm.value.company,
    founded:this.infoForm.value.founded,
    employee:this.infoForm.value.employee,
    tagline:this.infoForm.value.tagline,
    base_location:this.infoForm.value.base_location,
    bio:this.infoForm.value.bio,
    url:this.infoForm.value.url,
    //insurance:this.infoForm.value.insurance,
    insurance:this.insurance,
    //license:this.infoForm.value.license,
    license:this.license,
    supplierID:this.uid
   
    
    
     
  },
    
  }).subscribe(({ data }) => {
    
 
     console.log(data);    
    console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });

}
insuranceChange() {
  this.insurance = !this.insurance;
  console.log(this.insurance);
}
licenseChange() {
  this.license = !this.license;
  console.log(this.license);
}

async openModalInfo(supplierinfoID,company, url, founded, employee, tagline, bio, base_location, insurance, license) {
  const modal = await this.modalController.create({
    component: InfoModalPage,
    componentProps: {
      
      uid:this.uid,
      supplierinfoID:supplierinfoID,
      company:company,
      url:url,
      founded:founded,
      employee:employee,
      tagline:tagline,
      bio:bio,
      base_location:base_location,
      insurance:insurance,
      license:license
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}

close():void{
  this.modalController.dismiss();
}

//this is update or change Supplier Address via modal
async openModalAddress(supplieraddressID,address, suite, city, county, state, zip) {
  const modal = await this.modalController.create({
    component: AddressModalPage,
    componentProps: {
      
      uid:this.uid,
      supplieraddressID:supplieraddressID,
      address:address,
      suite:suite,
      city:city,
      county:county,
      state:state,
      zip:zip,
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
async openModalPhones(supplierphoneID,phone_type, phone_number) {
  const modal = await this.modalController.create({
    component: PhoneModalPage,
    componentProps: {
      
      uid:this.uid,
      supplierphoneID:supplierphoneID,
      phone_type:phone_type,
      phone_number:phone_number,
      
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
async openModalLicenses(supplierlicenseID,license_number, county_license, state_license) {
  const modal = await this.modalController.create({
    component: LicenseModalPage,
    componentProps: {
      
      uid:this.uid,
      supplierlicenseID:supplierlicenseID,
      license_number:license_number,
      county_license:county_license,
      state_license:state_license
      
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
  async openModalStaff(supplierstaffID,name_first, name_last) {
    const modal = await this.modalController.create({
      component: StaffModalPage,
      componentProps: {
        
        uid:this.uid,
        supplierstaffID:supplierstaffID,
        name_first:name_first,
        name_last:name_last,
        
        
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
  
    return await modal.present();
  }
}
