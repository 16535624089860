import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-labor',
  templateUrl: './labor.page.html',
  styleUrls: ['./labor.page.scss'],
})
export class LaborPage implements OnInit {

  segmentModel = "labor";

  labor = { "employee_on_tools": 1,"annual_hours_per_employee":2080, "annual_chargable_hours_per_employee":1664, "annual_overhead_expense":0, "total_on_the_tools_wages":0, "multiplier":1.3, "yearly_work_hours":2080};

  total_chargable_hours() : any {
    return (this.labor.employee_on_tools * this.labor.annual_chargable_hours_per_employee).toFixed(2); 
  }

  total_on_the_tools_wage_benfits() : any {
    return (this.labor.total_on_the_tools_wages * this.labor.multiplier).toFixed(2) ;
  }

  total_on_the_tools_wage_chargeable() : any {
    return (this.labor.total_on_the_tools_wages * this.labor.multiplier / ((this.labor.employee_on_tools * this.labor.annual_chargable_hours_per_employee  / (this.labor.annual_chargable_hours_per_employee * this.labor.yearly_work_hours))* this.labor.annual_chargable_hours_per_employee)).toFixed(2);
  }

  total_chargeable_hour_percent() : any {
    return (this.labor.annual_hours_per_employee / ((this.labor.employee_on_tools * this.labor.annual_chargable_hours_per_employee))).toFixed(2);
  }

  hourly_overhead_cost() : any {
    return (this.labor.annual_overhead_expense / (this.labor.employee_on_tools * this.labor.annual_chargable_hours_per_employee)).toFixed(2) ;
  }

  real_cost() : any {
    return ((this.labor.total_on_the_tools_wages * this.labor.multiplier / ((this.labor.employee_on_tools * this.labor.annual_chargable_hours_per_employee  / (this.labor.annual_chargable_hours_per_employee * this.labor.yearly_work_hours))* this.labor.annual_chargable_hours_per_employee)) + this.labor.annual_overhead_expense / (this.labor.employee_on_tools * this.labor.annual_chargable_hours_per_employee)).toFixed(2);

  }


  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private router: Router) {}

  ngOnInit() {

    
  }
  closeModal():void{
    this.modalController.dismiss();
    
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
}
