import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

              const createBases = gql`
              mutation CreateBases($uid:ID
																		
                                    ){
                                      createBases(input:
                                        {

                                        
                                          is_active: true
                                          supplier:{connect:{
                                            where: {
                                              node:{uid:$uid}
                                            } 
                                          }}
                                         
                                          
                                        }
                                        
                                      ) 
                                      
                                      {
                                       bases{
                                         baseID
                                         uid
                                         supplierID
                                         supplierUID
                                          is_active
                                          baseSupplier{
                                            supplierID
                                            uid
                                          }
                                          
                                            supplier{
                                             supplierID
                                              uid
                                            }
                                        
                                        
                                            }
                                        
                                      }
                                     
                                        }
                                        
                                          
                                    
  
`;

const createSuppliers = gql`

  mutation createSuppliers(
 $username:					String
 $email:						String
 $password:					String
 $name_first:				String
 $name_last:				String
 $uid:							ID
){
createSuppliers(input:
  {
    username:$username
    email:$email
    password:$password
    name_first:$name_first
    name_last:$name_last
    is_active: true
    
    uid:$uid
   
  }) {
     suppliers{
      username
      email
      password
      supplierID
      is_active
      name_first
      name_last
      uid
      
    }
      
      
    }
}
  
`;

const createHomes = gql`


    mutation createHomes(
 $username:					String
 $email:						String
 $password:					String
 $uid:							ID
 
 
 
 

){
createHomes(input:
  {
    username:$username
    email:$email
    password:$password
    is_active: true
    
    uid:$uid
    
   
  }) {
     homes{
      username
      email
      password
      homeID
      is_active
      uid
      
      
      
    }
      
      
    }
}
`;





@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<any>;
  uid: string;
  email: string;
  name_first: string;
  name_last: string;
 
 
 


  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private navCtrl: NavController,   private apollo: Apollo,) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  signIn(credentials): Observable<any> {
    return from(this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
      switchMap(user => {
        console.log('real user: ', user);
        if (user) {
          return this.db.doc(`users/${user.user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  signUp(credentials) {
    return this.afAuth.auth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      console.log('after register: ', data);
      return this.db.doc(`users/${data.user.uid}`).set({
        name: credentials.name,
        email: credentials.email,
        role: credentials.role,
        
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
  }

  

  

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.navCtrl.navigateRoot('/');
    });
  }

  getEmail() {
    return this.afAuth.auth.currentUser.email;
  }

  getUid() {
    return this.afAuth.auth.currentUser.uid;
  } 
  
 
  
createHomes(uid,email) {

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation:  createHomes,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    email: this.afAuth.auth.currentUser.email,
    
    

    

    
  },
    
  }).subscribe(({ data }) => {
   // console.log('sendMessage: ', uuid);
 
     console.log(data);
     

     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/seller/setup-home'); 

   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    this.navCtrl.navigateRoot('/setup-home');
  });
  
}
createSuppliers(uid,email,name_first, name_last) {
  

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation:  createSuppliers,
    
    variables: {
    uid: this.afAuth.auth.currentUser.uid,
    email: this.afAuth.auth.currentUser.email,
    name_first:this.name_first,
    name_last:this.name_last
    
    
  },
    
  }).subscribe(({ data }) => {
   // console.log('sendMessage: ', uuid);
 
     console.log(data);
     
console.log("Form Submitted!");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    this.navCtrl.navigateRoot('/setup');
  });

}

createBases(uid) {

  //let params = Object.assign(credentials)
  this.apollo.mutate({
    mutation:  createBases,
    
    variables: {
      uid: this.afAuth.auth.currentUser.uid
  //uid:uid
   //uid: this.afAuth.auth.currentUser.uid
   //uid: this.afAuth.auth.currentUser.uid

   
  
    
    
  },
    
  }).subscribe(({ data }) => {
   // console.log('sendMessage: ', uuid);
 
     console.log(data);
     console.log('uid',uid);
     
console.log("Base ID Created ");


//this.navCtrl.navigateForward('/setup'); 
 //this.router.navigate(['/app/setup/',this.id ]);
   
  }, (error) => {
    console.log('there was an big ass error sending the query', error);
    
    //this.navCtrl.navigateRoot('/setup');
  });
}
}