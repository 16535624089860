import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-price',
  templateUrl: './price.page.html',
  styleUrls: ['./price.page.scss'],
})
export class PricePage implements OnInit {

  price = { "hourly_rate": 85, "number_of_workers": 1, "hours_required":1 ,"overhead":10000,"annual_chargeable_hours":1640, "travel_time":0, "cost_of_materials":0, "cost_of_subcontractors":0, "rental_fees":0, "desired_profit_margin":35, "profit_margin10":10, "profit_margin15":15, "profit_margin20":20, "profit_margin25":25, "profit_margin30":30, "profit_margin35":35, "profit_margin40":40, "profit_margin45":45};
  
  total_hours() : any {
    return this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time;
}
  cost_of_labor() : any {
  return this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time) ;
}

total_chargeable_hours(): any{
  return this.price.number_of_workers * this.price.annual_chargeable_hours
}

overhead_cost_per_hour() {
  return (this.price.overhead /((this.price.number_of_workers * this.price.annual_chargeable_hours))).toFixed(2)
}

overhead_cost_per_project() {
  return (((this.price.overhead /((this.price.number_of_workers * this.price.annual_chargeable_hours)))) * this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time).toFixed(2)
}

total_cost_of_goods_sold() : any {
  return this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees ;
}

desired_profit_margin_percent_calc() : any {
  return (1 -(this.price.desired_profit_margin) /100).toFixed(4) ;
}



//Sales Price - Cost of Goods Sold
gross_profit() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}

gross_profit10() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin10) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}

gross_profit15() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin15) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}

gross_profit20() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin20) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}

gross_profit25() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin25) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}
gross_profit30() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin30) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}
gross_profit35() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin35) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}
gross_profit40() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin40) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}
gross_profit45() : any {
  return ((((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin45) /100))) - (this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)).toFixed(2) ;
}

profit_expense_percent() : any {
return((this.price.desired_profit_margin).toFixed(2))
}
labor_expense_percent() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))*100).toFixed(2) ;
}
labor_expense_percent10() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin10) /100))*100).toFixed(2) ;
}
labor_expense_percent15() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin15) /100))*100).toFixed(2) ;
}
labor_expense_percent20() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin20) /100))*100).toFixed(2) ;
}
labor_expense_percent25() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin25) /100))*100).toFixed(2) ;
}
labor_expense_percent30() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin30) /100))*100).toFixed(2) ;
}
labor_expense_percent35() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin35) /100))*100).toFixed(2) ;
}
labor_expense_percent40() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin40) /100))*100).toFixed(2) ;
}
labor_expense_percent45() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin45) /100))*100).toFixed(2) ;
}
material_expense_percent() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))*100).toFixed(2) ;
}
material_expense_percent10() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin10) /100))*100).toFixed(2) ;
}
material_expense_percent15() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin15) /100))*100).toFixed(2) ;
}
material_expense_percent20() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin20) /100))*100).toFixed(2) ;
}

material_expense_percent25() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin25) /100))*100).toFixed(2) ;
}

material_expense_percent30() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin30) /100))*100).toFixed(2) ;
}
material_expense_percent35() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin35) /100))*100).toFixed(2) ;
}
material_expense_percent40() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin40) /100))*100).toFixed(2) ;
}
material_expense_percent45() : any {
  return ((this.price.cost_of_materials ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin45) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent10() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin10) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent15() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin15) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent20() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin20) /100))*100).toFixed(2) ;
}

subcontractor_expense_percent25() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin25) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent30() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin30) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent35() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin35) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent40() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin40) /100))*100).toFixed(2) ;
}
subcontractor_expense_percent45() : any {
  return ((this.price.cost_of_subcontractors ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin45) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent10() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin10) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent15() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin15) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent20() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin20) /100))*100).toFixed(2) ;
}

rental_fees_expense_percent25() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin25) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent30() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin30) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent35() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin35) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent40() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin40) /100))*100).toFixed(2) ;
}
rental_fees_expense_percent45() : any {
  return ((this.price.rental_fees ) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin45) /100))*100).toFixed(2) ;
}
// Total cost of goods sold / (1-profit margin)*100
sales_price() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100)).toFixed(2) ;
}
sales_price10() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin10) /100)).toFixed(2) ;
}
sales_price15() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin15) /100)).toFixed(2) ;
}
sales_price20() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin20) /100)).toFixed(2) ;
}

sales_price25() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin25) /100)).toFixed(2) ;
}
// Total cost of goods sold / (1-profit margin)*100
sales_price30() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin30) /100)).toFixed(2) ;
}
// Total cost of goods sold / (1-profit margin)*100

sales_price35() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin35) /100)).toFixed(2) ;
}
// Total cost of goods sold / (1-profit margin)*100

sales_price40() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin40) /100)).toFixed(2) ;
}
sales_price45() : any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin45) /100)).toFixed(2) ;
}


cost_of_goods_sold_percent() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors )) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.desired_profit_margin) /100))*100).toFixed(2) ;
}

cost_of_goods_sold_percent10() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin10) /100))*100).toFixed(2) ;
}
cost_of_goods_sold_percent15() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin15) /100))*100).toFixed(2) ;
}
cost_of_goods_sold_percent20() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin20) /100))*100).toFixed(2) ;
}
cost_of_goods_sold_percent25() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin25) /100))*100).toFixed(2) ;
}
cost_of_goods_sold_percent30() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin30) /100))*100).toFixed(2) ;
}
cost_of_goods_sold_percent35() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin35) /100))*100).toFixed(2) ;
}
cost_of_goods_sold_percent40() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin40) /100))*100).toFixed(2) ;
}
cost_of_goods_sold_percent45() : any {
  return (((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors +this.price.rental_fees)) / ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors) / (1 -(this.price.profit_margin45) /100))*100).toFixed(2) ;
}
net_profit(): any {
  return ((this.price.hourly_rate * (this.price.number_of_workers * this.price.hours_required + this.price.number_of_workers * this.price.travel_time)+ this.price.cost_of_materials  + this.price.cost_of_subcontractors ) / (1 -(this.price.desired_profit_margin) /100)) .toFixed(2) ;

}

  segmentModel = "labor";


  constructor(private authService: AuthService, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private router: Router) {}

  ngOnInit() {

    
  }
  closeModal():void{
    this.modalController.dismiss();
    
}
segmentChanged(event){
  console.log(this.segmentModel);
  
  console.log(event);
}
}
