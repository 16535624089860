import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql, QueryRef} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ToastController} from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ActivatedRoute} from '@angular/router';
//used for poll & refresh
import { Subscription } from 'rxjs'

//placekey
import {geoToPlacekey} from '@placekey/placekey';
//H3
//import geojson2h3 from 'geojson2h3';


import {placekeyToGeo} from '@placekey/placekey';
import {placekeyToH3} from '@placekey/placekey';
import {h3ToPlacekey} from '@placekey/placekey';
//import {getHexBoundary} from "h3-js";

import {latLngToCell} from "h3-js";
import {cellToLatLng} from "h3-js";
import {gridDisk} from "h3-js";
import {cellToBoundary} from "h3-js";

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//keys to the kingdom
import {polygonToCells} from "h3-js";
import {cellsToMultiPolygon} from "h3-js";
//import {geoToH3} from "h3-js";
//import {h3ToGeo} from "h3-js";
//import {h3ToGeoBoundary} from "h3-js";

//mapbox
import * as mapboxgl from 'mapbox-gl';
import { environment } from "../../../environments/environment";

mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';

const GET_HOME_ADDRESS = gql`
  query GetHomeAddress($homeAddressID:ID) {
    homeAddresses(where:{homeAddressID:$homeAddressID}) {
      uid
      homeAddressID
      street_number
      street
      city
      state
      zip
      address_name
      
    }
  }
`

const createLocations = gql`
  mutation createLocations( $homeID:ID $homeAddressID:ID, $latitude: Float, $longitude: Float,$placekey:String,$h36:String, $h37:String,$h38:String,$h39:String,$h310:String,$coord:[String],$dayID:ID,$weekID:ID, $h35:String ){
  createLocations(input:
    {
      homeID:$homeID
      homeAddressID:$homeAddressID
      latitude: $latitude
      longitude: $longitude
      placekey:$placekey
      h35:$h35
      h36:$h36

      h37:$h37
      h38:$h38
      h39:$h39
      h310:$h310
      coord:$coord
      dayID:$dayID
      weekID:$weekID

      days:{connect:{
        where: {
          node:{dayID:$dayID}
        }
        
      }}

      weeks:{connect:{
        where: {
          node:{weekID:$weekID}
        }
        
      }}

      home_address:{connect:{
        where: {
          node:{homeAddressID:$homeAddressID}
        }
        
      }}

      home:{connect:{
        where: {
          node:{homeID:$homeID}
        }
        
      }}
       
    }) 
  
  {
   locations{
    locationID
    homeID
    homeAddressID
    latitude
    longitude
    placekey
    h35
    h36
    h37
    h38
    h39
    h310 
    coordinates
    coord 
    dayID
    weekID
    days{
      dayID
    }
    weeks{
      weekID
    }
    home_address{
      street_number
      street
      city
      state
      zip
    }
    home{
      homeID
      email 
    }

   
    
  }
 
    }
    
  }
  
`;

@Component({
  selector: 'app-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit {
  latitude: number;
  longitude :number;
  homeID: String;
  homeAddressID: String;
  placekey: String;
  h3Index: String;
  h35:String;
  h36:string;

  h37:any;
  h38:any;
  h39:any;
  h310:any;
  poly:any;
  hexagons:any[];
  loading = true;
  error: any;
  homeAddresses:any[];
  homes:any[];
  home:any[];
  coordinates:any;
  coor:any;
  coord:any;
  dat0:any[];
  homeAdressQuery: QueryRef<any>;
  h3center:any;
  h39boundary:any;
  dayID:any;
  weekID:any;
  

  private mapbox: mapboxgl.Map;
  private map = {};
  private directions = {};
  private container = {};
  private value: any;
  private values: any;

  private querySubscription: Subscription

  constructor(private afAuth: AngularFireAuth,
    private authService: AuthService, 
    private navService: NavService,
    private apollo: Apollo, 
    private router: Router,
    private modalController: ModalController,
    private navParams: NavParams,
    public toastController: ToastController,
    private fb: FormBuilder,
    private activatedroute:ActivatedRoute
     
    ) { mapboxgl.accessToken = environment.mapboxgl.accessToken;}
    ngOnInit(){}

    ionViewDidEnter() {
   
    let latitude = this.navParams.get('latitude');
    let longitude = this.navParams.get('longitude');
    let homeAddressID = this.navParams.get('homeAddressID');
    let homeID = this.navParams.get('homeID');
    let dayID = this.navParams.get('dayID');
    let weekID = this.navParams.get('weekID');

    let placekey=geoToPlacekey( latitude,longitude);
    let geo = placekeyToGeo(placekey);//[40.98415841509838, -74.07913882721337]
    //let hexBoundary = getHexBoundary(geo);
    let h35 =latLngToCell(latitude,longitude, 5);
    let h36 =latLngToCell(latitude,longitude, 6);
    let h37 =latLngToCell(latitude,longitude, 7);
    let h38 =latLngToCell(latitude,longitude, 8);
    let h39 =latLngToCell(latitude,longitude, 9);
    let h310 =latLngToCell(latitude,longitude, 10);
    let h39center = cellToLatLng(h39);
    let h39boundary = cellToBoundary(h39);
    let boundary = JSON.stringify(h39boundary);
//the keys to the kingdome
let h3Index = latLngToCell(40.984194, -74.079635, 9);
let poly = cellToBoundary(h39);//'872a10545ffffff'
let poly1 = cellToBoundary(h3Index);//'872a10545ffffff'
let hexagons = polygonToCells(poly, 9);
let coordinates = cellsToMultiPolygon(hexagons, true);
//let coor = JSON.stringify(coordinates);
let coor = JSON.stringify(coordinates);
//use parse to delete outside quotation marks
//let coord = JSON.parse(coor.slice(1,-1));
let coord = (coor.slice(1,-1));
    console.log('h35',h35);
    console.log('h36',h36);
    console.log('h37',h37);
    console.log('h38',h38);
    console.log('h39',h39);
    console.log('h39center',h39center);
    console.log('h39boundary',h39boundary);
    console.log('h3Index',h3Index);
    console.log('h310',h310);
    console.log('placekey',placekey);
    console.log('homeID',homeID),
    console.log('homeAddressID',homeAddressID);
    console.log('coordinates',coordinates);
    console.log('coord',coord);
    console.log('poly',poly);
    console.log('geo',geo);
    console.log('hexagons',hexagons);
    console.log('h39center',h39center);
    console.log('boundary',boundary);
    console.log('dayID',dayID);
    console.log('weekID',weekID);



    //console.log('coor',coor);
    


    this.homeAddressID=homeAddressID;
    this.h35=h35;
    this.h36 = h36;
    this.h37 = h37;
    this.h38 = h38;
    this.h39 = h39;
    this.h39boundary = h39boundary;
  
    this.h310 = h310;
    this.latitude = latitude;
    this.longitude = longitude;
    this.placekey=placekey;
    this.homeID = homeID;
    this.dayID = dayID;
    this.weekID = weekID;

    this.coordinates=coordinates;
    //this.coor = coor;
    this.coord = h39boundary;
    poly = poly
   
    console.log('coord',coord)
   


    this.homeAdressQuery = this.apollo.watchQuery<any>({
      query: GET_HOME_ADDRESS,
      variables: {
        //uid : this.navParams.get('uid'),
        homeAddressID: this.homeAddressID
        //variables: {homeAddressID:this.activatedroute.snapshot.paramMap.get('homeAddressID')} ,
      },
      pollInterval: 0,
    });
    this.querySubscription =this.homeAdressQuery
    .valueChanges
    .subscribe(({ data, loading }) => {
      this.loading = loading
      this.homeAddresses = data.homeAddresses;
    });
  }
  async createLocations(homeID,homeAddressID,latitude,longitude,placekey,h36,h37,h38,h39,h310,coord,dayID,weekID,h35) {
      
    this.apollo.mutate({
      mutation: createLocations,
      
      variables: {
      
      homeID:homeID,
      homeAddressID:homeAddressID,
      latitude:latitude,
      longitude:longitude,
      placekey:placekey,
      h36:h36,
      h37:h37,
      h38:h38,
      h39:h39,
      h310:h310,
      //coord:this.coord
      coord:coord,
      dayID:dayID,
      weekID:weekID,
      h35:h35,
      
      
    },
      
    }).subscribe(({ data }) => {
      
   
       console.log(data);
       
  console.log("Form Submitted!");
  
  
  //this.navCtrl.navigateForward('/setup'); 
   //this.router.navigate(['/app/setup/',this.id ]);
     
    }, (error) => {
      console.log('there was an big ass error sending the query', error);
      
      //this.navCtrl.navigateRoot('/setup');
    });
  }
  closeModalLocation():void{
    //this.modalController.dismiss();
    this.modalController.dismiss(null, null, "locationpage");
  
    this.apollo
    .watchQuery({
      query: gql`
         query homes($homeID:ID)
  {
  homes(where:{homeID:$homeID}){
  uid
  homeID
  username
  password
  email
  
  homeInfo{
    name_first
    name_last
  }
  
  location{
    latitude
    longitude
  }
  }
  }
  
  
      `,
             //variables: {uid:this.uid} 
         variables:    {homeID:this.homeID},
        //variables: {homeID:this.activatedroute.snapshot.paramMap.get('homeID')} ,
         
    })
    .valueChanges.subscribe((result: any) => {
      this.homes = result?.data?.homes;
      this.loading = result.loading;
      this.error = result.error;
    });

    
    mapboxgl.accessToken = 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g';


    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 9,
      center: [-74.08097014877629,40.98385513546514]
      });
    
      
    
}
}
