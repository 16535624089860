import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OfferingModalPageRoutingModule } from './offering-modal-routing.module';

import { OfferingModalPage } from './offering-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    OfferingModalPageRoutingModule
  ],
  declarations: [OfferingModalPage]
})
export class OfferingModalPageModule {}
