import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cart = [];
  private cartItems = new BehaviorSubject([]);
  private cartItemCount = new BehaviorSubject(0);
 
  constructor() {

  }
 
  getCart() {
    return this.cartItems.asObservable();
  }
 
  getCartItemCount() {
    return this.cartItemCount;
  }
 
  addProduct(i) {
    
   
      this.cart.push(i);
    
    this.cartItems.next(this.cart);
    this.cartItemCount.next(this.cartItemCount.value + 1);
  }
 
  decreaseProduct(i) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === i.inviteID) {
        p.amount -= 1;
        if (p.amount == 0) {
          this.cart.splice(index, 1);
        }
      }
    }
    this.cartItems.next(this.cart);
    this.cartItemCount.next(this.cartItemCount.value - 1);
  }
 
  removeProduct(i) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === i.inviteID) {
        this.cartItemCount.next(this.cartItemCount.value - p.inviteFee[0].amount);
        this.cart.splice(index, 1);
      }
    }
    this.cartItems.next(this.cart);
  }

  getItemCount(i) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === i.inviteID) {
        return p.amount;
      }
    }
    return 0;
  }
  getInviteID(i) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === i.inviteID) {
        return p.inviteID;
      }
    }
    return 0;
  }

  checkout() {
    
  }
}