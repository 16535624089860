import { Component, OnInit, ViewChild } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { IonItemDivider, IonSlides } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { BannerModalSubcategoryPage } from '../banner-modal-subcategory/banner-modal-subcategory.page';

@Component({
  selector: 'app-banner-modal',
  templateUrl: './banner-modal.page.html',
  styleUrls: ['./banner-modal.page.scss'],
})
export class BannerModalPage implements OnInit {
  uid = this.authService.getUid(); 
  
  bannerPlacements: any[];
  loading = true;
  error: any;
  dataReturned: any;
  bannerPlacementUID: any;


  constructor(private navParams: NavParams,private afAuth: AngularFireAuth,private authService: AuthService, private navService: NavService,private apollo: Apollo, private router: Router,private modalController: ModalController,) {}

  ngOnInit() {
    let bannerPlacementUID = this.navParams.get('bannerPlacementUID');
   // let territoryID = this.navParams.get('territoryID');
    //let zipID = this.navParams.get('zipID');
    let todayID = this.navParams.get('todayID');
    let dayID = this.navParams.get('dayID');
    //console.log('territoryID', territoryID);
    //console.log('zipID', zipID);
    console.log('todayID1', todayID);
    console.log('dayID', dayID);

    this.apollo
    .watchQuery({
      query: gql`
        query bannerPlacements($bannerPlacementUID:ID)
{
  bannerPlacements(where:{bannerPlacementUID:$bannerPlacementUID}){
    bannerID
   bannerPlacementUID
    week
    year
    territory_number
    banners{
      bannerID
      image_path
      headline
      message
        items{
          itemID
          item
          image_path
        }
        banner_items{
      item
      itemID
      item_image_path
    }
    }
  }
}

      `,
             //variables: {uid:this.uid} , 
         variables:    {bannerPlacementUID:bannerPlacementUID},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.bannerPlacements = result?.data?.bannerPlacements;
      this.loading = result.loading;
      this.error = result.error;
    });



  }
closeModal():void{
    this.modalController.dismiss();
}
async openModalBannerSubcategory(bannerPlacementUID,  itemID,todayID) {
  const modal = await this.modalController.create({
    component: BannerModalSubcategoryPage,
    cssClass: 'my-modal-class',
    id:"banners-subcategory",
    componentProps: {
      
      uid:this.uid,
      bannerPlacementUID:  bannerPlacementUID,
     // territoryID:territoryID,
      itemID: itemID,
     
      todayID:todayID,
     // zipID:zipID
      
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });
  return await modal.present();
}
}
