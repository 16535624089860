import { Component, OnInit } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NavParams } from '@ionic/angular';

import { LoadingController,ModalController } from '@ionic/angular';
import { LibraryPage } from '../library/library.page';
import { TaskPage } from '../task/task.page';
import { MapquestPage } from '../mapquest/mapquest.page';
import { ToastController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-resource',
  templateUrl: './resource.page.html',
  styleUrls: ['./resource.page.scss'],
})
export class ResourcePage implements OnInit {
  uid = this.authService.getUid(); 
  loading = true;
  error: any;
  libraries: any[];
  libraryUID:any;
  principleUID:any;
  dataReturned: string[];

  constructor(private authService: AuthService, 
    //private fb: FormBuilder, 
    private afAuth: AngularFireAuth,
    private navService: NavService,
    private apollo: Apollo,
    private modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    private navParams: NavParams,
    
    private router: Router) {}

  ngOnInit() {
    this.apollo
    .watchQuery({
      query: gql`
         query libraries
{
  libraries{
    libraryUID
    title
    author
    header
    body
    libraryID
    image_path
    
  }
}

 
      `,
             //variables: {uid:this.uid} , 
                


         
    })
    .valueChanges.subscribe((result: any) => {
      this.libraries = result?.data?.libraries;
      this.loading = result.loading;
      this.error = result.error;
    });
   
  }
  closeModal():void{
    this.modalController.dismiss(null, null, "resource");
}
async openModalLibrary(libraryUID) {
  const modal = await this.modalController.create({
    component: LibraryPage,
    cssClass: 'my-modal-class',
    id:"library",
    componentProps: {
      
      libraryUID:libraryUID
   
    }
  });

  modal.onDidDismiss().then((dataReturned) => {
    if (dataReturned !== null) {
      this.dataReturned = dataReturned.data;
      //alert('Modal Sent Data :'+ dataReturned);
    }
  });

  return await modal.present();
}
}
