import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';

import { RfqPageRoutingModule } from './rfq-routing.module';

import { RfqPage } from './rfq.page';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RfqPageRoutingModule
  ],
  declarations: [RfqPage]
})
export class RfqPageModule {}
