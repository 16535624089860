// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g'
  },
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDg8KTX2_0p-z2wjuiCVHAPlbo0qWisSv0",
    authDomain: "aarbo-161b3.firebaseapp.com",
    projectId: "aarbo-161b3",
    storageBucket: "aarbo-161b3.appspot.com",
    messagingSenderId: "249664333495",
    appId: "1:249664333495:web:d04a178b5cffa725908f32",
    measurementId: "G-QH20DV4D7X"
  },
  stripe_key: "pk_test_51J33lkKOQRbjls6GSsfAaWYGHkGLzPoMkkwDSHC7d7AEZc4ugUdzXe3S68O0ruXMa8VaIBOlCbd5kt8H9gAuVZsa00gXVB4iyk",
  mapboxgl: {
    accessToken: 'pk.eyJ1IjoiZm1jbG91ZCIsImEiOiJjbDl5bjUzajkwNmt3M3ZseG5yMTRiaGc5In0.4XOmaw6L-ETJnL4s3boq5g'
    
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.s/dist/zone-error';  // Included with Angular CLI.
